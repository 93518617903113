$(document).on('turbo:load', function() {
  if ($("#project_workload").length > 0) {
    project_workload_by_user_in_group();
  }

  let day_of_year = $("#project_workload_by_user_in_group").data("day");

  $("#page_project_workload").delegate(".filter_users_by_name", "click", function (){
    filter_users_in_workload(day_of_year);
  });

  $("#page_project_workload").delegate("#filter_users_by_department", "change", function (){
    filter_users_in_workload(day_of_year);
  });

  $("#page_project_workload").delegate(".search_by_user_name", "keydown", function (event){
    if (event.keyCode == 13) {
      filter_users_in_workload(day_of_year);
    }
  });

  $("#page_project_workload").delegate(".filter_users_current_month", "click", function (){
    day_of_year = $(this).data("day");
    filter_users_in_workload(day_of_year);
  });

  $("#page_project_workload").delegate(".filter_users_last_month", "click", function (){
    day_of_year = $("#filter_users_last_month").val();
    filter_users_in_workload(day_of_year);
  });

  $("#page_project_workload").delegate(".filter_users_next_month", "click", function (){
    day_of_year = $("#filter_users_next_month").val();
    filter_users_in_workload(day_of_year);
  });

  function filter_users_in_workload(day_of_year) {
    let department_id = $("#page_project_workload #filter_users_by_department").val().split("_")[0]
        data_type = $("#page_project_workload #filter_users_by_department").val().split("_")[1]
        find_user = $(".search_by_user_name").val()
        id =  $("#header_master_plan").data("id")
    $.ajax({
      type: 'GET',
      url: "/master_plans/" + id + "/project_workloads/filter_users_in_project_workload",
      dataType: 'script',
      data: {
        department_id: department_id,
        find_user: find_user,
        data_type: data_type,
        day_of_year: day_of_year
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
      }
    });
  };
});

window.project_workload_by_user_in_group = function() {
  let data = gon.log_works;
  let list_holiday = gon.list_holiday;
  let width = $(window).width();
  let a = data.length;

  const weekday = I18n.t("date.abbr_day_names");
  const months = I18n.t("date.month_names");
  const years = I18n.t("common.year");

  function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  function IsdaySat (day, month, year) {
    date =  new Date(`${month}/${day}/${year}`).getDay();
    return (date == 6)
  }

  function IsdaySun (day, month, year) {
    date =  new Date(`${month}/${day}/${year}`).getDay();
    return (date == 0)
  }

  function Isholiday (day, month, year) {
    date =  new Date(`${month}/${day}/${year}`).getDay();
    return (check_holiday(day, list_holiday))
  }

  function check_holiday(day, list_holiday) {
    let a = false
    $.each(list_holiday, function(i, value) {
      if(day == value){ a = true}
    })

    return a;
  }

  function Isweekdays (day, month, year) {
    date =  new Date(`${month}/${day}/${year}`).getDay();
    return date
  }

  function listDay(day_count){
    let array_day = []
    for (var i = 0; i < day_count; i++){
      array_day.push(i + 1);
    }
    return array_day
  }

  let date = $("#project_workload").data("day")

  let date_select = new Date(date);
      year = date_select.getUTCFullYear();
      month = date_select.getUTCMonth() + 1;

  let days = listDay(daysInMonth(month, year))

  let header = [
    { type: 'hidden', title:'Id'},
    { type: 'hidden', title:'par' },
    { type: 'text', title: I18n.t("project_workloads.name"), width: 175, color: "#fffff" },
  ]

  $.each(days, function(i, value) {
    let weekdays = Isweekdays (value, month, year)
    if (check_holiday (i+1, list_holiday)){
      let day = weekday[weekdays]
      header.push({ type: 'numeric', title:`${day}`, width: 35, color: "red" })
    }
    else if( weekdays == 6){
      let day = weekday[weekdays]
      header.push({type: 'numeric', title:`${day}`, width: 35, color: "#3688fc"})
    }
    else if( weekdays == 0){
      let day = weekday[weekdays]
      header.push({type: 'numeric', title:`${day}`, width: 35, color: "red"})
    }
    else {
      let day = weekday[weekdays]
      header.push({ type: 'numeric', title:`${day}`, width: 35 })
    }
  })

  header.push({ type: 'numeric', title: I18n.t("project_workloads.total"), width: 45, color: "#42d29d" })

  let estimated_day = 0
  $.each(days, function(i, value) {
    let weekdays = Isweekdays (value, month, year);
    if (weekdays != 0 && weekdays != 6 && check_holiday (value, list_holiday) != true) {
      estimated_day = estimated_day +1
    }
  })

  let nestedHeaders = [
    {
      title: '',
    },
  ]
  $.each(days, function(i, value) {
    if (Isholiday (value, month, year) || IsdaySun(value, month, year)) {
      nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", color: "red", width: 35})
    }
    else if (IsdaySat(value, month, year)) {
      nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", color: "#3688fc", width: 35})
    }
    else {
      nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", width: 35})
    }
  })
  nestedHeaders.push({ type: 'numeric', title: "", width: 45 })
  let nestedHeader = [
    [
      {
        title: `${year}${years}${months[month]}`,
        colspan: `${days.length + 2}`
      },
    ],
    nestedHeaders
  ]
  table = jspreadsheet(document.getElementById('project_workload'), {
    data:data,
    tableOverflow: true,
    editable:false,
    tableWidth: width,
    tableHeight: "700px",
    lazyLoading:true,
    loadingSpin:true,
    columns: header,
    contextMenu: false,
    nestedHeaders: nestedHeader,
    updateTable:function(instance, cell, col, row, val, label, cellName) {


      if (col == header.length - 1) {
        cell.style.color = '#42d29d'
      }
      let children = $('tbody td[data-x="2"][data-patent!="0"]')
      children.each(function() {
        $(this).css('padding-left', '1rem');
        $(this).attr('title', $(this).find('span').text());
      });
    },
  });

  $( "table" ).delegate( "td", "click", function() {
    $(".collapse_btn").on("click", function() {
      let parent = $(this).parents()
      let children = $("tbody").find(`[data-patent='${parent.data("id")}']`)
      children.each(function() {
        table.hideRow($(this).data("y"));
      });

      $(this).addClass("expand_btn mdi-plus-box-outline")
      $(this).removeClass("collapse_btn mdi-minus-box-outline")
    });

    $(".expand_btn").on("click", function() {
      let parent = $(this).parents()
      let children = $("tbody").find(`[data-patent='${parent.data("id")}']`)
      children.each(function() {
        table.showRow($(this).data("y"));
      });

      $(this).addClass("collapse_btn mdi-minus-box-outline")
      $(this).removeClass("expand_btn mdi-plus-box-outline")
    });
  });
}
