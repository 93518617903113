$(document).on('turbo:load', function() {
	$("#list_bulletin_category_default a").click(function(){
    // remove classname 'active' from all li who already has classname 'active'
    $("#list_bulletin_category_default a.bulletin_active").removeClass("bulletin_active");
    $("#list_bulletin_category a.bulletin_active").removeClass("bulletin_active"); 
    // adding classname 'active' to current click li 
    this.classList.add("bulletin_active"); 
	});

	$("#list_bulletin_category a").click(function(){
    // remove classname 'active' from all li who already has classname 'active'
    $("#list_bulletin_category a.bulletin_active").removeClass("bulletin_active");
    $("#list_bulletin_category_default a.bulletin_active").removeClass("bulletin_active");
    // adding classname 'active' to current click li 
    this.classList.add("bulletin_active"); 
	});

	// handle click row table bulletin subject
		var lastCheckedBulletinSubject = null;
		$("body").delegate('.tbl_bulletin_subject tbody tr', 'click', function () {
			if(!lastCheckedBulletinSubject) {
        lastCheckedBulletinSubject = this;
        // return;
      }

	    if (window.event.ctrlKey) {
	      this.classList.toggle("selected");
	    }
	    else if (window.event.shiftKey) {
	      var start = $('.tbl_bulletin_subject tbody tr').index(this);
	      var end = $('.tbl_bulletin_subject tbody tr').index(lastCheckedBulletinSubject);
	      $('.tbl_bulletin_subject tbody tr').slice(Math.min(start,end), Math.max(start,end)+ 1).addClass('selected');
	    }
	    else {
	      $(this).toggleClass("selected").siblings().removeClass('selected');
	    }
	    lastCheckedBulletinSubject = this;
	  });

	// Remove bulletin
	$("#btnRemoveMultiBulletin").click(function() {
		var current_bulletin_category = $("#current_bulletin_category").val();
	  var arr_bulletin = [],id;

    $.each($('.tbl_bulletin_subject tbody tr'), function (i, row) {
        var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
        if (checkBox) 
        {
            id =row.getAttribute('data-item-id');
            arr_bulletin.push(id);
        }   
    });

		if (arr_bulletin.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/confirm_remove_multi_bulletin",
				dataType: 'script',
				data: {
					list_bulletin: arr_bulletin,
					bulletin_category_id: current_bulletin_category
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	// Move bulletin
	$("#btnMoveBulletin").click(function() {
		var current_bulletin_category = $("#current_bulletin_category").val();
		var bulletin_category_id = $("#lstBulletinCategory option:selected").val();
		var arr_bulletin = [],id;

    $.each($('.tbl_bulletin_subject tbody tr'), function (i, row) {
        var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
        if (checkBox) 
        {
            id =row.getAttribute('data-item-id');
            arr_bulletin.push(id);
        }   
    });

		if (arr_bulletin.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/move_bulletin",
				dataType: 'script',
				data: {
					list_bulletin: arr_bulletin,
					bulletin_category_selected: bulletin_category_id,
					bulletin_category_id: current_bulletin_category
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}

	});

});


