import {PDFContent} from './PDFContent';
import {PDFThumnail} from './PDFThumnail'
import {PDF} from './PDF';
import {_ui_utils} from './ui_ultil';
import { PDFDocument ,PDFDict,PDFArray,PDFString,PDFName,StandardFonts,rgb,grayscale,PDFFont,setFillingRgbColor,PageSizes} from 'pdf-lib';
import { toolBarHelper } from '../edit/toolbar_helper';
import { ANNOTATION,FORM_STATUS } from '../edit/annotation';
import {UndoRedoHandler} from '../edit/undo_redo';
import { ultil_mdethods } from '../common/ultil_methods';
import  {PdfAnnotationChannel} from '../../../channels/pdf_edit_channel'
import { annotation_helper } from '../common/annotation_helper';
import { PageView } from './layout_page';
import { url_helper } from '../../../packs/supports/url_helper.js';
import { css_helper } from '../../../packs/supports/css_helper.js';
import { Config } from './AppConfig.js';
const DEFAULT_SCALE_DELTA=1.1;
const MAX_SCALE = 10;
const MIN_SCALE = 0.1;
const scale_mode={
    Normal:0,
    FitHeight: 1,
    FitWidth:2,
}
const PDF_TO_CSS_UNITS =  96.0 / 72.0;
var _grab_to_pan = require("./grab_to_pan.js");
export class PDFBaseViewer{    
    file   
    pdfContent   
    pdfThumbnail
    viewComponents  
    scale = null
    scale_mode = scale_mode.Normal
    pageRendering
    pageNumPending   
    eventBus
    _currentPageNumber = 0
    _currentRotate = 0
    _currentScale = null
    pagesCount    
    pdf
    pdfDoc
    fabricpageAnnotations = []  
    undoRedoHandler
    form_status = FORM_STATUS.VIEWING
    initial(viewComponents,evBus,current_mode,app_type){
        
        this._currentRotate = 0;
        this._currentScale = 1   
        this.current_mode = current_mode;
        this.app_type = app_type;
        this.viewComponents = viewComponents ;
        this.eventBus = evBus;
        this.pdfContent = new PDFContent(this);
        this.pdfThumbnail = new PDFThumnail(this);

        this.file = new PDF();  
        this.handTool = new _grab_to_pan.GrabToPan({
            element: this.viewComponents.viewContainer
        });
    }

    async openURL(url){   
        showFormLoading();  
        this.file.url = url;    
        
        if(el('slug')){
            this.file.id = el('slug').value;
        }
        
        var result = await setDataByURL(this);        
        if(!result) return;
        this.openFileWithPDFBytes();      
        this.file.loading = false;  
        this.setDefaultData();
        hideFormLoading();    
        this.form_status = FORM_STATUS.VIEWING;
        this.channel = new PdfAnnotationChannel(this)
        this.user_id = el('user_id').value;
    }   
    async reload(url){     
        this.file.url = url;
        var result = await setDataByURL(this);        
        if(!result) return;
        showFormLoading();      
        reloadFile(this);    
        this.setDefaultData();
        hideFormLoading();    
        this.file.loading = false;   
        this.form_status = FORM_STATUS.VIEWING;
        
    }  
    reload(){
        showFormLoading();      
        reloadFile(this);    
        this.setDefaultData();
        hideFormLoading();    
    }
    setDefaultData(){       
        if(this.form_status == FORM_STATUS.EDITTING){
            this.fabricpageAnnotations = [];      
            this.undoRedoHandler = new UndoRedoHandler();        
            toolBarHelper.clearListannotations();
            this.updateStatusUndoRedo();
            set_status_form_by_url(this);
            //enablePinchZoom(this);
        }
        
    }    
    getLayoutPage(pageNum){
        return new PageView(this,pageNum);
    }
    updateStatusUndoRedo(){
        toolBarHelper.updateStatusUndoRedo(this);
        toolBarHelper.hideToolProperties();  
    }
    // open file from bytes
   openFileWithPDFBytes(){       
      showFormLoading(); 
      load(this);   
      if(this.form_status == FORM_STATUS.EDITTING){
         toolBarHelper.clearListannotations();
      }
      hideFormLoading();    
    }      
    setFormStatus(form_status){
        this.form_status = form_status;
    }
    get currentRotate() {
        return this._currentRotate;
    }
    set currentRotate(val) {
        if (!Number.isInteger(val)) {
          throw new Error("Invalid Rotate.");
        }
        if (this._currentRotate === val) {
            return true;
        }
        
        this._currentRotate = val;
        this.setDefaultData();
        createContent(this);
        
        this.pdfContent.renderPage(this.currentPageNumber);    
        this.viewComponents.viewContainer.querySelector(`#page-${this.currentPageNumber}`) .scrollIntoViewIfNeeded(); 
        return true;
        
    }
 
    get currentScaleValue() {
        return this._currentScale;
    }
     set currentScaleValue(val) {   
        this.setScaleValue(val);       
        
    }
    setScaleValue = async (val) => {
        if (this._currentScale === val) {          
            return true;
        }         
        this.viewComponents.viewContainer.removeAttribute('edit');
        this.setDefaultData();
        this._currentScale = val;        
        this.updatescale(val);
        this.pdfContent.updateScale(this);             
        toolBarHelper.hideToolProperties();  
        this.viewComponents.viewContainer.querySelector(`#page-${this.currentPageNumber}`) .scrollIntoViewIfNeeded(); 
        if(this.scale_mode == scale_mode.Normal )
        {
            if($(`#${this.viewComponents.toolbar.select_scale.id} option[value="${val}"]`).length == 0){
                var opt = document.createElement('option');
                opt.value = val;
                opt.innerHTML = `${parseInt(val*100)}%`;
                opt.setAttribute("hidden", true);
                this.viewComponents.toolbar.select_scale.appendChild(opt);
            }
            this.viewComponents.toolbar.select_scale.value = val; 
        }
        css_helper.set_global_variable("--scale-factor",val * PDF_TO_CSS_UNITS);     
        if(this.current_mode == FORM_STATUS.EDITTING){
            url_helper.add_params_to_url("scale",this._currentScale)
        }  
        this.renderPageInView();
        return true;
    }
    get currentPageNumber() {
        return this._currentPageNumber;
    }
    set currentPageNumber(val) {
        if (!Number.isInteger(val)) {
          throw new Error("Invalid page number.");
        }
        if (!this._setCurrentPageNumber(val, true)) {
          console.error(`currentPageNumber: "${val}" is not a valid page.`);
        }
    }

    _setCurrentPageNumber(val) {
        if (this._currentPageNumber === val) {          
          return true;
        }
    
        if (!(0 < val && val <= this.pagesCount)) {
          return false;
        }
    
        // const previous = this._currentPageNumber;
        this._currentPageNumber = val;
        
        this.eventBus.dispatch("pagechanging", {
          source: this,
          pageNumber: val,        
        });             

        this.renderPageInView();
        return true;
    }
    
    renderPage(number){        
        this.pdfContent.renderPage(number);
    } 
    scroll(){        
        var pages = this.viewComponents.viewContainer.querySelectorAll(".page");
        var page_in_pointer,i,number_of_page;
        for(i = 0;i< pages.length;i++){
            if(IsPageDisplay(pages[i],this.viewComponents.pageScroll))
            {
                page_in_pointer = pages[i];
                break;
            }
        }
       
        if(page_in_pointer){
            var id = page_in_pointer.id.split("-");
            var number_of_page = parseInt(id[1]);
            this.renderPage(number_of_page-1);
            this._currentPageNumber = number_of_page; 
            this.updatetoolbar(number_of_page);             
            
        }
        this.renderPageInView();
    }

    renderPageInView(){   
        var pages = this.viewComponents.viewContainer.querySelectorAll(".page");   
        var holder = this.viewComponents.pageScroll;
        for(let i = 0;i< pages.length;i++){
          if(isElementVisible(pages[i],holder))
          {
            this.renderPage(i);
          }
        }  
    }

    queueRenderPage(num) {
        if(num<=0 || num > _TOTAL_PAGES) return;
        
        if (this.pageRendering) {
            this.pageNumPending = num;
        } 
        else 
        {
            this.renderPage(num);
        }
    }
    firstpage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber == 1) {
            return false;
        }
        this.currentPageNumber =  1;
        return true;   
    }

    endpage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber == this.pagesCount) {
            return false;
        }
        this.currentPageNumber =  this.pagesCount;
        return true;   
    }

    previousPage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber <= 1) {
            return false;
        }
        this.currentPageNumber = currentPageNumber - 1;
        return true;   
    }

    nextPage(){
        const currentPageNumber = this._currentPageNumber;
        if (currentPageNumber >= this.pagesCount) {
            return false;
        }
        this.currentPageNumber = currentPageNumber + 1;
        return true;   
    }
    rotateleft(){   
        var rotate = (this.file.pages[this.currentPageNumber-1].viewRotate - 90)%360;
        this.set_rotate(rotate);
        return true;
    }
    rotateright(){       
        var rotate = (this.file.pages[this.currentPageNumber-1].viewRotate + 90)%360;
        this.set_rotate(rotate);
        return true;
    }

    set_rotate(rotate){        
        this.viewComponents.viewContainer.removeAttribute('edit');
        var page = this.file.pages[this.currentPageNumber-1];
        page.viewRotate = rotate;
        this.pdfContent.renderViewPage(this);  
        this.pdfThumbnail.rotate(page); 
        if(this.current_mode == 1){
            update_rotation(this,rotate);
        }
        
    }
    changescale(scaleValue){
        var scale = scaleValue;
        switch (scaleValue){
            case 'page-fit':
                scale = getScalePageFit(this);
                this.scale_mode = scale_mode.FitHeight
                break;
            case 'page-width':                
                scale = getScalePageWidth(this);
                this.scale_mode = scale_mode.FitWidth
                break;
            default:
                this.scale_mode = scale_mode.Normal
                break;

        }
        this.currentScaleValue = parseFloat(scale);
    }

    zoomIn(steps=1){
        let newScale = this._currentScale;

        do {
          newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
          newScale = Math.ceil(newScale * 10) / 10;
          newScale = Math.min(MAX_SCALE, newScale);
        } while (--steps > 0 && newScale < MAX_SCALE);
        this.scale_mode = scale_mode.Normal
        this.currentScaleValue = newScale;
    }
    zoomOut(steps=-1){
        let newScale = this._currentScale;

        do {
          newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
          newScale = Math.floor(newScale * 10) / 10;
          newScale = Math.max(MIN_SCALE, newScale);
        } while (--steps > 0 && newScale > MIN_SCALE);
        this.scale_mode = scale_mode.Normal
        this.currentScaleValue = newScale;
    }

    updatetoolbar(pageNumber){        
        this.pdfThumbnail.setPageNumber(pageNumber);
        this.viewComponents.toolbar.current_page.value = pageNumber;
        if(this.app_type != 0){
            url_helper.add_params_to_url('page',pageNumber);
        }
        if(pageNumber==1){
            this.viewComponents.toolbar.previous.setAttribute("disabled", true);
            this.viewComponents.toolbar.firstpage.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.previous.removeAttribute("disabled");
            this.viewComponents.toolbar.firstpage.removeAttribute("disabled");

        }
        if(pageNumber==this.pagesCount){
            this.viewComponents.toolbar.next.setAttribute("disabled", true);
            this.viewComponents.toolbar.endpage.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.next.removeAttribute("disabled");
            this.viewComponents.toolbar.endpage.removeAttribute("disabled");

        }
    }
    updatescale(scale){        
        if(scale == MIN_SCALE){
            this.viewComponents.toolbar.zoomOut.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.zoomOut.removeAttribute("disabled");

        }
        if(scale == MAX_SCALE){
            this.viewComponents.toolbar.zoomIn.setAttribute("disabled", true);
        }
        else{
            this.viewComponents.toolbar.zoomIn.removeAttribute("disabled");

        }
    }  
   

    _matching_annotation(){
        set_select_annotation(this)
    }
}

function set_select_annotation(viewer){
    var elem = null;
    switch(viewer.current_annotation)
    {
      case ANNOTATION.NONE:         
         elem = viewer.viewComponents.edittoolbar.select_text;
         break;
      case ANNOTATION.MOVE:      
         elem = viewer.viewComponents.edittoolbar.select_annotation;
         break;      
      case ANNOTATION.HAND:      
         elem = viewer.viewComponents.edittoolbar.hand;
         break;   
      case ANNOTATION.ADD_STAMP:
        elem = el("btnAddStamp");
        break;      
      case ANNOTATION.ADD_TEXT:
        elem = viewer.viewComponents.edittoolbar.add_text;
        break;      
     case ANNOTATION.FREE_DRAW:  
         elem = viewer.viewComponents.edittoolbar.pen;
         break;      
    case ANNOTATION.PENCIL:  
         elem = viewer.viewComponents.edittoolbar.free_draw;
         break;  
     case ANNOTATION.ADD_LINK:         
        elem = viewer.viewComponents.edittoolbar.link;
        break;      
         
     case ANNOTATION.ADD_IMAGE:         
        elem = viewer.viewComponents.edittoolbar.add_image;
        break;      
     case ANNOTATION.DRAW_LINE:                     
        elem = viewer.viewComponents.edittoolbar.draw_line;
        break;      
     case ANNOTATION.DRAW_RECTANGLE:  
        elem = viewer.viewComponents.edittoolbar.draw_rectangle;
        break;      
     case ANNOTATION.DRAW_ELLIPSE:     
        elem = viewer.viewComponents.edittoolbar.draw_ellipse;
        break;       
     case ANNOTATION.ADD_TEXTBOX:
        elem = viewer.viewComponents.edittoolbar.form_textbox;
        break;      
     case ANNOTATION.ADD_TEXT_AREA:
        elem = viewer.viewComponents.edittoolbar.form_textarea;
        break;      
     case ANNOTATION.ADD_RADIO:
        elem = viewer.viewComponents.edittoolbar.form_radio;
        break;      
     case ANNOTATION.ADD_CHECKBOX:
        elem = viewer.viewComponents.edittoolbar.form_checkbox;
        break;      
     case ANNOTATION.ADD_DROPDOWN:
        elem = viewer.viewComponents.edittoolbar.form_dropdown;
        break;      
     case ANNOTATION.ERASE:
        elem = viewer.viewComponents.edittoolbar.erase;
        break;
     case ANNOTATION.ADD_XMARK:      
        elem = viewer.viewComponents.edittoolbar.mark_x;       
        break;      
     case ANNOTATION.ADD_CHECKMARK:  
        elem = viewer.viewComponents.edittoolbar.mark_v;
        break;      
     case ANNOTATION.ADD_DOT:         
        elem = viewer.viewComponents.edittoolbar.mark_o;
        break;      
     case ANNOTATION.DRAW_ARROW:
        elem = viewer.viewComponents.edittoolbar.draw_arrow;
         break;     
    case ANNOTATION.TEXT_HIGHLIGHT:
        elem = viewer.viewComponents.edittoolbar.highlight;
        break;   
    case ANNOTATION.TEXT_STROKEOUT:
        elem = viewer.viewComponents.edittoolbar.strikeout;
        break;
    case ANNOTATION.TEXT_UNDERLINE:
        elem = viewer.viewComponents.edittoolbar.underline;
        break;
    default:
        elem = viewer.viewComponents.edittoolbar.select_text;
        break;
    }    
    $('.secondary-button-list .dropdown-item').removeClass("active");
    if(elem){
        var buttons = document.querySelectorAll(`.edit-button`);
        for (let index = 0; index < buttons.length; index++) {
            if(buttons[index].id != elem.id){
                buttons[index].setAttribute('selected',false);            
            }
            else{
                elem.setAttribute('selected',true);
            }            
        }  

        var buttons2 = document.querySelectorAll(`.secondary-button-list .dropdown-item`);
        for (let index = 0; index < buttons2.length; index++) {
            if(buttons2[index].id  != elem.id + "2"){
                buttons2[index].setAttribute('selected',false);            
            }
            else{
                buttons2[index].setAttribute('selected',true);
            }
            
        }  
    }
}

async function setDataByURL(baseView){
    try
    {    
        var bytes = await getPdfBytes(baseView.file.url);
        baseView.file.bytes = bytes;        
        baseView.pdfDoc = await PDFDocument.load(bytes,{ ignoreEncryption: true });  
        var files = new Blob([bytes], {type: 'application/pdf'});
		
		files.text().then(x=> {		
			baseView.file.encripted =  x.includes("Encrypt");	
            if(baseView.file.encripted)	            
            {
                var form = document.getElementById('form-edit-pdf');
                if(form){
                    form.setAttribute('encripted',true)
                }
            }
		});
        return true;
    }
    catch(error)
    {
        hideFormLoading();
        console.log(error);
        alert(error.message);
        return false;
    }
}


async function createComponents(baseView){
    var init_page = get_page_number_init();
    
    if(baseView.app_type == 0)
    {
        init_page = 1;
    }
    baseView.file.pages = await getPages(baseView.pdf);
    defineProperties(baseView.file.pages);
    // if(baseView._currentScale == null){
    //     baseView._currentScale = getScalePageFit(baseView);
    // }
    set_status_scale_form_by_url(baseView);
    
    baseView.file.numPages = baseView.pdf.numPages;
    baseView.pagesCount = baseView.pdf.numPages
    createContent(baseView);
    baseView.pdfContent.renderPage(init_page-1);     
    baseView.pdfThumbnail.create(); 
    baseView.currentPageNumber = init_page;
    baseView.viewComponents.toolbar.total_page.value = baseView.pdf.numPages;
    baseView.viewComponents.toolbar.current_page.setAttribute('max',baseView.pdf.numPages);   
    // baseView.viewComponents.thumnailContainer.querySelector(`#page-${init_page}`) .scrollIntoView();
     
}
async function reloadCreateComponents(baseView){
    baseView.file.pages = await getPages(baseView.pdf);
    defineProperties(baseView.file.pages);
    baseView.file.numPages = baseView.pdf.numPages;
    baseView.pagesCount = baseView.pdf.numPages
    createContent(baseView);
    baseView.pdfContent.renderPage(baseView.currentPageNumber-1);     
    baseView.pdfThumbnail.create(); 
    baseView.viewComponents.toolbar.total_page.value = baseView.pdf.numPages;
    baseView.viewComponents.toolbar.current_page.setAttribute('max',baseView.pdf.numPages);   
    baseView.updatetoolbar(baseView.currentPageNumber);
    baseView.set_annotation(baseView.current_annotation);

}
function defineProperties(pages){   
    var rotate_element,page_rotate = 0;
    for (let index = 0; index < pages.length; index++) {
        const element = pages[index];
        page_rotate = 0
        rotate_element = el('rotation-page-'+ (index+1))
        if(rotate_element && rotate_element.value){
            page_rotate = parseInt(rotate_element.value);
        }
        Object.defineProperties(element,{
            viewRotate:{
                value : page_rotate,
                writable: true
            }
            
         })  


         var list_annos = annotation_helper.get_annotations_by_index(index) ;
         Object.defineProperties(element,{
            annotation:{
                value : list_annos,
                writable: true
            }
            
         })  
        
    }

    $(el('data_json_annotation')).remove();
    $(el('data_page_rotation')).remove();
}
function createContent(baseView){   
    baseView.pdfContent.create(baseView);  
    //baseView.pdfContent.renderPage(baseView.currentPageNumber);     
}

async function getPages(pdf){
    const pageNumbers = Array.from({length: pdf.numPages}, (_, i) => i + 1)
    const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));
    return await Promise.all(promises);
}
async function getPdfBytes(url){  
  var bytes = await fetch_data(url);
  return bytes;
}

function load(baseView){
    var loadingTask  = pdfjsLib.getDocument({
      data: baseView.file.bytes,
      cMapUrl: '/cmaps/',
      cMapPacked: true
    });
   
    loadingTask.promise.then(function(pdf) {    
        baseView.pdf = pdf;  
        createComponents(baseView);        
    })
}    
function reloadFile(baseView){
    var loadingTask  = pdfjsLib.getDocument({
      data: baseView.file.bytes,
      cMapUrl: '/cmaps/',
      cMapPacked: true
    });
   
    loadingTask.promise.then(function(pdf) {    
        baseView.pdf = pdf;  
        reloadCreateComponents(baseView);        
        
    })
}    
function IsPageDisplay(page,container){
    var result = false;
    const containerHeight = container.clientHeight;
    let visiblePageHeight = getVisibleHeight(page,container);
    if(visiblePageHeight >= containerHeight / 2){
        result = true;
    }
    return result;  
}


function getVisibleHeight(element,container){
	let scrollTop = container.scrollTop;
	let scrollBot = scrollTop + container.clientHeight;
	let containerRect = container.getBoundingClientRect();
	let eleRect = element.getBoundingClientRect();
	let rect = {};
	rect.top = eleRect.top - containerRect.top,
	rect.right = eleRect.right - containerRect.right,
	rect.bottom = eleRect.bottom - containerRect.bottom,
	rect.left = eleRect.left - containerRect.left;
	let eleTop = rect.top + scrollTop;
	let eleBot = eleTop + element.offsetHeight;
	let visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
	let visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

	return visibleBot - visibleTop;
}

function isElementVisible (el, holder) {
    holder = holder || document.body
    const { top, bottom, height } = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()
  
    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height
  }

  function getScalePageFit(baseView){
    var height = baseView.viewComponents.pageScroll.offsetHeight - 22;
    return height/baseView.file.pages[0].getViewport({scale:1,rotation:baseView.file.pages[0].viewRotate}).height;
  }
  function getScalePageWidth(baseView){
    var width = baseView.viewComponents.pageScroll.offsetWidth - 22;
    return width/baseView.file.pages[0].getViewport({scale:1,rotation:baseView.file.pages[0].viewRotate}).width;
  }


  function checkStatus(array){
    var hasChild = false;
    for (let index = 0; index < array.length; index++) {
        var hasChild = false;
        const element = array[index];
        if(element.hasChildNodes()){
            hasChild = true;
        }
    }
    return hasChild;
  }

  function set_status_form_by_url(viewer){     
    var annotation = url_helper.get_value_params_from_URL("annotation");
    if(annotation){
        viewer.set_annotation(parseInt(annotation));
    }
    else{
        viewer.set_annotation(ANNOTATION.NONE);        
    }  

  }
  function set_status_scale_form_by_url(viewer){  
    var scale = url_helper.get_value_params_from_URL("scale");
    if(scale){
        viewer._currentScale = parseFloat(scale);   
    }
    else{
        viewer._currentScale = getScalePageFit(viewer); 
        viewer.scale_mode = scale_mode.FitHeight  ;
    }  
    if(viewer.scale_mode == scale_mode.Normal )
    {
        if($(`#${viewer.viewComponents.toolbar.select_scale.id} option[value="${viewer._currentScale}"]`).length == 0){
            var opt = document.createElement('option');
            opt.value = viewer._currentScale;
            opt.innerHTML = `${parseInt(viewer._currentScale*100)}%`;
            opt.setAttribute("hidden", true);
            viewer.viewComponents.toolbar.select_scale.appendChild(opt);
        }
        viewer.viewComponents.toolbar.select_scale.value = viewer._currentScale; 
    }
  }

  function get_page_number_init(){
    var init_page = 1;
    var page = url_helper.get_value_params_from_URL('page');
    if(page){
        init_page = parseInt(page);
    }
    return init_page;
  }

 function update_rotation(viewer,rotate){
    var success = false;
    var objectDataSubmit = {id: viewer.file.id,page: viewer.currentPageNumber, rotate:rotate};  
    $.ajax({
        type: "POST",
        url: window.location.origin + "/pdf/save_page_rotation",
        data: objectDataSubmit,    
        success: function(data)
        {
            success = true;
        }
    });
    return success;
}

function enablePinchZoom(pdfViewer) {
    let startX = 0, startY = 0;
    let initialPinchDistance = 0;        
    let pinchScale = 1;    
    const viewer = pdfViewer.viewComponents.viewContainer;
    const reset = () => { startX = startY = initialPinchDistance = 0; pinchScale = 1; };
    // Prevent native iOS page zoom
    //document.addEventListener("touchmove", (e) => { if (e.scale !== 1) { e.preventDefault(); } }, { passive: false });
    document.addEventListener("touchstart", (e) => {
        if (e.touches.length > 1) {
            startX = (e.touches[0].pageX + e.touches[1].pageX) / 2;
            startY = (e.touches[0].pageY + e.touches[1].pageY) / 2;
            initialPinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));
        } else {
            initialPinchDistance = 0;
        }
    });
    document.addEventListener("touchmove", (e) => {
        if (initialPinchDistance <= 0 || e.touches.length < 2) { return; }
        if (e.scale !== 1) { e.preventDefault(); }
        const pinchDistance = Math.hypot((e.touches[1].pageX - e.touches[0].pageX), (e.touches[1].pageY - e.touches[0].pageY));
        const originX = startX + viewer.scrollLeft;
        const originY = startY + viewer.scrollTop;
        pinchScale = pinchDistance / initialPinchDistance;
        pdfViewer.currentScaleValue *= pinchScale;
        viewer.style.transform = `scale(${pinchScale})`;
        viewer.style.transformOrigin = `${originX}px ${originY}px`;
    }, { passive: false });
    document.addEventListener("touchend", (e) => {
        if (initialPinchDistance <= 0) { return; }
        viewer.style.transform = `none`;
        viewer.style.transformOrigin = `unset`;
        pdfViewer.currentScaleValue *= pinchScale;
        const rect = viewer.getBoundingClientRect();
        const dx = startX - rect.left;
        const dy = startY - rect.top;
        viewer.scrollLeft += dx * (pinchScale - 1);
        viewer.scrollTop += dy * (pinchScale - 1);
        reset();
    });
}
