$(document).on('ready turbo:load', function() {
  $("body").delegate("#select_all_column_options", "click", function(){
    $("#list_checkbox_columns").find(".column_item").prop('checked', this.checked);
  })

  $("body").delegate(".column_item", "click", function(){
    set_select_all_option("#list_checkbox_columns", "column_item")
  })

  $("#setting-column-modal").delegate("#btn_apply_column", "click", function () {
    let column_list = $("#list_checkbox_columns").find("input[type='checkbox']");
    user_screen_properties_params = []

    $.each(column_list, function( index, col ) {
      user_screen_properties_params.push({
        id: $(col).parent().data("id"),
        display: $(col).is(":checked"),
        screen_property_id: $(col).parent().data("screen-property-id")
      })
    });

    $.ajax({
      type: "POST",
      url: "/project_manager/user_screen_properties",
      dataType: "script",
      data: {
        screen: $(this).data("screen"),
        user: {
          user_screen_properties_attributes: user_screen_properties_params
        }
      }
    })
  });
})

window.set_select_all_option = function(parent, children) {
  let checkboxes = $(parent).find("input." + children);

  if (checkboxes.filter(":checked").length == checkboxes.length) {
    $("#select_all_column_options").prop('checked', true);
  } else {
    $("#select_all_column_options").prop('checked', false);
  }
}
