$(document).on('turbo:load', function() {
	$("body").delegate('.scheduleEventMarkMenu', 'click', function (e) {
		var class_radio = this.id;
		$("." + class_radio)[0].checked = true;
	});

	$("#btnDeleteTypeMeeting").click(function() {
		var arr_type_meeting = [];
		$.each($('.tbl_type_meeting tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_type_meeting.push($(row).data('item-id'));
			} 
		});

		if (arr_type_meeting.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_type_meeting",
				dataType: 'script',
				data: {
					id: arr_type_meeting
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate('#btnMoveUpTypeMeeting', 'click', function () {
		$('#tblTypeMeetingsList select :selected').each(function (i, selected) {
			if (!$(this).prev().length) return false;
			$(this).insertBefore($(this).prev());
		});
		$('#tblTypeMeetingsList select').focus();
	});

	$("body").delegate('#btnMoveDownTypeMeeting', 'click', function () {
		$($('#tblTypeMeetingsList select :selected').get().reverse()).each(function (i, selected) {
			if (!$(this).next().length) return false;
			$(this).insertAfter($(this).next());
		});
		$('#tblTypeMeetingsList select').focus();
	});

	$("body").delegate('#btnMoveTopTypeMeeting', 'click', function () {
		var count_category = 0;
        $.each($('#tblTypeMeetingsList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var first_elem = $("#tblTypeMeetingsList option:first");
			if(first_elem.is(':selected')) return true;
			while(!first_elem.prev().length){
				$('#tblTypeMeetingsList select :selected').each(function (i, selected) {
					if (!$(this).prev().length) return false;
					$(this).insertBefore($(this).prev());
				});
				$('#tblTypeMeetingsList select').focus();
			}	
		}
	});

	$("body").delegate('#btnMoveBottomTypeMeeting', 'click', function () {	
		var count_category = 0;
        $.each($('#tblTypeMeetingsList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var last_elem = $("#tblTypeMeetingsList option:last");
			if(last_elem.is(':selected')) return true;
			while(!last_elem.next().length){
				$($('#tblTypeMeetingsList select :selected').get().reverse()).each(function (i, selected) {
					if (!$(this).next().length) return false;
					$(this).insertAfter($(this).next());
				});
				$('#tblTypeMeetingsList select').focus();
			}
		}
	});

  $("body").delegate('#btnUpdateIndexTypeMeetings', 'click', function () {
		var lst = [];
		$("#lstTypeMeetings option").each(function () {
			lst.push($(this).val());
		});
		$.ajax({
			type: 'GET',
			url: "update_index_type_meeting",
			dataType: 'script',
			data: {
					lst_type_meeting: lst,
			},
			success: function (data, textStatus, jqXHR) {
					
			},
			error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error: #{textStatus}")
			}
		});
	});
});