import { annotation_helper } from "../common/annotation_helper";
import { stamp_helper } from "../common/stamp_helper";
import { ANNOTATION } from "../edit/annotation";
import { PDFSaveAnnotation } from "../edit/save_annotation";
import { ObjectHelper } from "../fabric/events/object_helper";
import { fabric_format } from "../fabric/fabric_format";
import { FArrow } from "../fabric/items/arrow";
import { FLine } from "../fabric/items/line";
import { FText } from "../fabric/items/text";

export const factory = {};
factory.create = {   
    image: add_new_image,       
    line: add_new_line,
    circle: add_new_circle,
    arrow: create_arrow,
    add_date: addDateLabel,
    clone_line: clone_line,
    clone_arrow: clone_arrow,
    clone_object: clone_object
}

factory.delete = deleteOneElement;
factory.delete_multil_element = delete_multil_element;

function addDateLabel(stamp,viewer)
{    
    var date = stamp_helper.get_date_by_stamp(stamp);
    if(date){
      factory.delete(stamp.canvas,date);
    }
    var currentDate = new Date();
    var format_date = stamp.formatDate;//'YYYY-MM-DD';
    var dateString = moment(currentDate).format(format_date); 

   var property = {
    text: dateString,
    x: stamp.left - stamp.x_relative*viewer.currentScaleValue,
    y: stamp.top - stamp.y_relative*viewer.currentScaleValue,
    fontFamily:  'MS 明朝',
    fontSize: stamp.fontSize,
    parent_id: stamp.id,
    color: stamp.color,
    formatDate: format_date,
    id: random_string(10)
   }
   return startAddingDate(property,stamp.canvas,viewer);
   

}

function startAddingDate(property,canvas,viewer){ 
  var boldText = 'normal';
  var italicText = 'normal';
  var page = viewer.file.pages[viewer.currentPageNumber-1];
  var rotate = (page.rotate + page.viewRotate) % 360;  
  var txt = new FText(property.text, { 
      left: property.x,
      top:   property.y,
      fontFamily: 'MS 明朝',
      fontWeight: boldText,
      fontStyle: italicText,
      fill: property.color,
      fontSize: parseInt(property.fontSize*viewer.currentScaleValue),
      name: 'date',
      annotation : ANNOTATION.ADD_TEXT,
      id: property.id,
      parent_id: property.parent_id,
      scale: viewer.currentScaleValue,
      lockUniScaling: true,
      originFontSize: parseInt(property.fontSize),
      rotate:  rotate,
      textFormat:  property.textFormat,
      page: viewer.currentPageNumber,
      user_id: viewer.user_id
  })
  fabric_format.text(txt,viewer);    
  canvas.add(txt);
  canvas.renderAll();  
  canvas.setActiveObject(txt);  
  return txt;
}
function add_new_line(canvas,aCoords,properties){  
    var line = new FLine(aCoords,properties);    
    canvas.add(line);
    canvas.add(line.head1);
    canvas.add(line.head2);

    canvas.bringToFront(line.head1);
    canvas.bringToFront(line.head2);
    canvas.sendBackwards(line);
    return line;
}
function clone_line(canvas,line,viewer) {
  var aCoords =   [line._start.x + 10, line._start.y + 10, line._end.x+ 10, line._end.y+ 10]    
    var properties = {
      fill: line.fill,
      stroke: line.stroke,
      strokeWidth: line.strokeWidth,     
      annotation: line.annotation,
      id: random_string(10),
      strokeDashArray: line.strokeDashArray,
      scale: line.scale,
      origin_strokeDashArray: line.origin_strokeDashArray,
      origin_strokeWidth: line.origin_strokeWidth,
      name: 'line',
      page: line.page
    }
    
    
    properties = annotation_helper.config.line.fix_config(properties);
    ObjectHelper.define_custome_properties(properties,viewer,canvas);

    var new_line = factory.create.line(canvas,aCoords,properties)  
    new_line.setMovedLine();   
    PDFSaveAnnotation.saveElement(viewer,new_line);
}
function clone_arrow(canvas,arrow,viewer){
    var aCoords =   [arrow._start.x + 10, arrow._start.y + 10, arrow._end.x+ 10, arrow._end.y+ 10]  

    var properties = {        
      fill: arrow.fill,
      stroke: arrow.stroke,
      strokeWidth: arrow.strokeWidth,     
      annotation: arrow.annotation,
      id: random_string(10),
      scale: arrow.scale,
      number_head: arrow.number_head,
      opacity: arrow.opacity,
      origin_strokeWidth: arrow.origin_strokeWidth,
      origin_strokeDashArray: arrow.origin_strokeDashArray,
      name: arrow.name ,
      page: arrow.page,
      strokeDashArray: arrow.strokeDashArray
    }
    
    
    properties = annotation_helper.config.line.fix_config(properties);
    var new_arrow = factory.create.arrow(canvas,aCoords,properties)
    new_arrow.setMovedLine();
    PDFSaveAnnotation.saveElement(viewer,new_arrow);
}
function clone_object(target,viewer) {
  target.clone(function(cloned) {
    cloned.left += 10;
    cloned.top += 10;
    cloned.annotation = target.annotation;
    cloned.name = target.name;
    cloned.id = random_string(10);
    cloned.scale = target.scale,
    cloned.scaleX = target.scaleX,
    cloned.scaleY = target.scaleY,
    cloned.rotate =  target.rotate ,
    cloned.originFontSize =  target.originFontSize ,
    
    cloned.page = target.page;
    target.canvas.add(cloned);
    cloned.setCoords();
    PDFSaveAnnotation.saveElement(viewer,cloned);
  });
}
function add_new_circle(canvas,properties){  
    var c = new fabric.Circle(properties);    
    return add_object(canvas,c)
}

function add_new_image(canvas,canvas_image,properties){   
    var image = new fabric.Image(canvas_image, properties);        
    return add_object(canvas,image)
}


  function deleteOneElement(canvas,element){
    switch (element.annotation) {
      case ANNOTATION.DRAW_ARROW:
        var arrow = element;
        if(element.name != 'arrow') {   
          arrow = element.line;
        }
        canvas.remove(arrow,arrow.head1,arrow.head2,arrow.line1,arrow.line2);
        if(arrow.number_of_head == 2){
          canvas.remove(arrow.line3,arrow.line4);
        }
        
        break;
      case ANNOTATION.DRAW_LINE:      
        if(element.name == 'line') {
          canvas.remove(element,element.head1,element.head2); 
        } 
        else{
          canvas.remove(element.line,element.line.head1,element.line.head2); 
        }
          
        break;   
      case ANNOTATION.ADD_STAMP:       
        if(element.hasDate) {
          var date = stamp_helper.get_date_by_stamp(element);
          if(date){  
            canvas.remove(element);      
            canvas.remove(date); 
          }     
        }
        else{
          canvas.remove(element);
        }
        
        break;    
      default:
        canvas.remove(element);
        break;
    }
    
  }
  function delete_multil_element(canvas,elements) {
    elements.forEach(element => {
      deleteOneElement(canvas,element);
    });
  }
  function add_object(canvas,object){
    canvas.add(object);
    object.setCoords();
    canvas.setActiveObject(object);
    object.setControlsVisibility({       
      mtr: false, 
    });
    return object;
}


function create_arrow(canvas,aCoords,properties){  
    var line = new FArrow(aCoords,properties);
    canvas.add(line);
    canvas.add(line.head1);
    canvas.add(line.head2);

    line.line1 && canvas.add(line.line1);
    line.line2 && canvas.add(line.line2);
    line.line3 && canvas.add(line.line3);
    line.line4 && canvas.add(line.line4);

    canvas.bringToFront(line.head1);
    canvas.bringToFront(line.head2);
    canvas.sendBackwards(line);
    
    return line;
}