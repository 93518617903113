import { moveOption } from './schedule.js';
$(document).on('turbo:load', function() {
	$("body").delegate('#lstDepartmentFilter', 'change', function() {
		var listIdSelected = [];
		$("#user_selected_edit option").each(function()     
		{
				listIdSelected.push($(this).val());
		});
		$.ajax({
			type: 'GET',
			url: "/search_users_by_department",
			dataType: 'script',
			data: {
				department_id: $("#lstDepartmentFilter option:selected").val(),
				lstUserSelected: listIdSelected
			},
			success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});

	// move top/bottom/up/down item in select option user
		$("body").delegate('#btnMoveTopPersonalGroupUser', 'click', function () {
			var count_personal_group = 0;
			$.each($('#user_selected_edit option:selected'), function (i, row) {
				count_personal_group = count_personal_group + 1
			});
			if (count_personal_group > 0){
				var first_elem = $("#user_selected_edit option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#user_selected_edit option:selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#user_selected_edit').focus();
				}	
			}
		});

		$("body").delegate('#btnMoveUpPersonalGroupUser', 'click', function () {
			$('#user_selected_edit option:selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#user_selected_edit').focus();
		});
		
		$("body").delegate('#btnMoveDownPersonalGroupUser', 'click', function () {
			$($('#user_selected_edit option:selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#user_selected_edit').focus();
		});
		
		$("body").delegate('#btnMoveBottomPersonalGroupUser', 'click', function () {	
			var count_personal_group = 0;
			$.each($('#user_selected_edit option:selected'), function (i, row) {
				count_personal_group = count_personal_group + 1
			});
			if (count_personal_group > 0){
				var last_elem = $("#user_selected_edit option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#user_selected_edit option:selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#user_selected_edit').focus();
				}
			}
		});  

		// add/remove from list user to list user selected
		$("body").delegate('#add_partner_personal_group', 'click', function() {
			var lst_user_select = document.getElementById('list_user_edit');
			var lst_user_selected = document.getElementById('user_selected_edit');
			moveOption(lst_user_select, lst_user_selected);
		});

		$("body").delegate('#remove_partner_personal_group', 'click', function() {
			var lst_user_select = document.getElementById('list_user_edit');
			var lst_user_selected = document.getElementById('user_selected_edit');
			moveOption(lst_user_selected, lst_user_select);
		});

	// event click button create/update record personal group
		$("body").delegate('#btnSavePersonalGroup', 'click', function () {
			var options_user = document.getElementById("user_selected_edit");
			for (var i=0; i<options_user.length; i++) {
				options_user[i].selected = "true";
			}
		});

	// destroy personal group
	$("#btnDeletePersonalGroup").click(function() {
		var arr_personal_group = [];
		$.each($('.tbl_personal_group tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_personal_group.push($(row).data('item-id'));
			} 
		});

		if (arr_personal_group.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/confirm_destroy_personal_group",
				dataType: 'script',
				data: {
					id: arr_personal_group
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	// sort personal group 
		$("body").delegate('#btnMoveUpPersonalGroup', 'click', function () {
			$('#tblPersonalGroupList select :selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#tblPersonalGroupList select').focus();
		});

		$("body").delegate('#btnMoveDownPersonalGroup', 'click', function () {
			$($('#tblPersonalGroupList select :selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#tblPersonalGroupList select').focus();
		});

		$("body").delegate('#btnMoveTopPersonalGroup', 'click', function () {
			var count_personal_group = 0;
	        $.each($('#tblPersonalGroupList select :selected'), function (i, row) {
	            count_personal_group = count_personal_group + 1
	        });
	        if (count_personal_group > 0){
				var first_elem = $("#tblPersonalGroupList option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#tblPersonalGroupList select :selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#tblPersonalGroupList select').focus();
				}	
			}
		});

		$("body").delegate('#btnMoveBottomPersonalGroup', 'click', function () {	
			var count_personal_group = 0;
	        $.each($('#tblPersonalGroupList select :selected'), function (i, row) {
	            count_personal_group = count_personal_group + 1
	        });
	        if (count_personal_group > 0){
				var last_elem = $("#tblPersonalGroupList option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#tblPersonalGroupList select :selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#tblPersonalGroupList select').focus();
				}
			}
		});

	// update change index 
	  $("body").delegate('#btnUpdateIndexPersonalGroup', 'click', function () {
			var lst = [];
			$("#lstPersonalGroup option").each(function () {
				lst.push($(this).val());
			});
			$.ajax({
				type: 'GET',
				url: "update_index_personal_group",
				dataType: 'script',
				data: {
						lst_personal_group: lst,
				},
				success: function (data, textStatus, jqXHR) {
						
				},
				error: function (jqXHR, textStatus, errorThrown) {
						console.log("AJAX Error: #{textStatus}")
				}
			});
		});

  // update event keyup Enter submit form
  $("body").delegate('#modal_personal_group', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnSavePersonalGroup").click();
    }
  });

  $("body").delegate('#modal_confirm_destroy_personal_group', 'keyup', function (event) {
    if (event.key === "Enter") {
      if ($("#btnUpdateIndexPersonalGroup").length > 0) {
        event.preventDefault();
        $("#btnUpdateIndexPersonalGroup").click();
      }
    }
  });

});
