$(document).ready(function() {
  $("body").delegate(".select-master_plan-status", "change", function(){
      this.parentElement.querySelector('.change_status').click();
  })

  if($(".master_plan_table").length > 0){
    $("body").delegate("#select_all_master_plan_table", "change", function() {
      if ($(this).is(':checked')) {
        $('.checkbox_master_plan_table').prop('checked', true);
      } else {
        $('.checkbox_master_plan_table').prop('checked', false);
      }
    });

    $("body").delegate(".master_plan_table", "change", '.checkbox_master_plan_table', function(){
      if ($('.checkbox_master_plan_table:checked').length === $('.checkbox_master_plan_table').length) {
        $('#select_all_master_plan_table').prop('checked', true);
      } else {
        $('#select_all_master_plan_table').prop('checked', false);
      }
    });

		$("body").delegate('#select_all_master_plan_table', 'change', function (ev) {
      debugger
			display_change_move_tbn(ev.currentTarget.checked)
		})

		$("body").delegate("td.checkbox_master_plan_table", "click", function(){
      debugger
			display_change_move_tbn($(".master_plan_table tbody").find("input.checkbox_master_plan_table:checked").length > 0)
		})

		$("body").delegate("input.checkbox_master_plan_table", "change", function(){
			display_change_move_tbn($(".master_plan_table tbody").find("input.checkbox_master_plan_table:checked").length > 0)
		})
	}


  $("body").delegate("#btnMoveProjectCategory", "click", function(){
    var project_category_id = $("#lstProjectCategory option:selected").val();
		var arr_master_plan = [];
		$.each($('.master_plan_table tbody tr'), function (i, row) {
			if ($(row).find("td input:checked").is(":checked")) {
				arr_master_plan.push($(row).data('master_plan-id'));
			}
		});

		if (arr_master_plan.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/master_plans/change_project_category",
				dataType: 'script',
				data: {
					master_plan_ids: arr_master_plan,
					project_category_id: project_category_id,
				},
				success: function(data, textStatus, jqXHR){
					//
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
  })

  $("body").delegate("#select_project_category", "change", function(){
    var project_category_id = $("#select_project_category option:selected").val();

    $.ajax ({
      type: 'GET',
      url: "/master_plans",
      dataType: 'script',
      data: {
        select_project_category_id: project_category_id,
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  })

  $("body").delegate('#start_time_project', 'click', function() {
    $("#start_time_project").datepicker({
      language: get_locate(),
      todayHighlight: true,
			format: "yyyy-mm-dd",
    }).focus();
  });

  $("body").delegate('#end_time_project', 'click', function() {
    $("#end_time_project").datepicker({
      language: get_locate(),
      todayHighlight: true,
			format: "yyyy-mm-dd",
    }).focus();
  });
});

function display_change_move_tbn(condition) {
	if(condition){
		$("#btnMoveProjectCategory").removeClass("disabled", false)
	}else {
		$("#btnMoveProjectCategory").addClass("disabled", true)
	}
}
