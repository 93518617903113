$(document).on('turbo:load', function() { 
    $("body").delegate('.btn-download-directly', 'click', function (event) {
        downloadFile(this.getAttribute("data-url"),this.getAttribute("data-name"));
        // downFn(this.getAttribute("data-url"),this.getAttribute("data-filename"))
    });
});
async function downloadFile(url,filename) {
  let headers = new Headers();
  headers.append('Origin',"*");
  headers.append('Access-Control-Allow-Origin',"*");
  fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    method: 'GET',
    headers: headers
})

  .then(response => response.blob())
  .then(blob => {
    // Create a new FileReader innstance
    const reader = new FileReader;
  
    // Add a listener to handle successful reading of the blob
    reader.addEventListener('load', () => {
      // const image = new Image;
      
      // Set the src attribute of the image to be the resulting data URL
      // obtained after reading the content of the blob

      
       var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', filename);
    link.href = reader.result;
    document.body.appendChild(link);
    link.click();
    link.remove();
    
    });
  
    // Start reading the content of the blob
    // The result should be a base64 data URL
    reader.readAsDataURL(blob);
  });
  }
  function downFn(url,filename) {
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (!pattern.test(url)) {
        return;
    }
    fetch(url)
        .then((res) => {
            if (!res.ok) {
                throw new Error("Network Problem");
            }
            return res.blob();
        })
        .then((file) => {
            const ex = extFn(url);
            let tUrl = URL.createObjectURL(file);
            const tmp1 = document.createElement("a");
            tmp1.href = tUrl;
            tmp1.setAttribute('download', filename);
            document.body.appendChild(tmp1);
            tmp1.click();
            URL.revokeObjectURL(tUrl);
            tmp1.remove();
        })
        .catch(() => {
           
        });
}
function extFn(url) {
    const match = url.match(/\.[0-9a-z]+$/i);
    return match ? match[0].slice(1) : "";
}


