$(document).on('ready turbo:load', function() {
  // $("#gantt_modal").delegate("#clear_filter", "click", function () {
  //   let option_list = $("#gantt_modal").find("input[type='checkbox']");
  //   $.each(option_list, function( index, col ) {
  //     $(col).prop("checked", false);
  //   });
  //   $("#task_title").val("");
  // });

  $("#gantt_modal_lg").delegate(".lable_filter_item", "click", function () {
    let input = $(this).prev("input.filter_item")
    input.prop("checked", !input.prop("checked"));

  });

  $("#gantt_modal_lg").delegate("#gantt_assigned_to, .lable_filter_item[for='assigned_to']", "click", function () {
    $('#select_member_filter').toggle();

  });

  window.$('#gantt_modal_lg').on('shown.bs.modal', function () {
    $("#member_filter_gantt").select2({
      dropdownParent: $("#gantt_modal_lg")
    })
  })
})
