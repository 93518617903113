import { Sortable, AutoScroll } from 'sortablejs';
$(document).on('turbo:load', function() {
  $('body').delegate('.add_task', 'click', function (e) {
    $(this).parent().addClass("d-none");
    $(this).parent().parent().find(".add_issue_input").removeClass("d-none").focus();
	});

  $('body').delegate('.add_issue_input', 'blur', function () {
    if ($(this).val().trim() == '') {
      $(this).addClass("d-none");
      $(this).parent().find(".add_task_box").last().removeClass("d-none");
      $(this).val("");
    }
	});

  $('body').delegate('.add_issue_input', 'keydown', function () {
    let title =  $(this).val();
    let stage_id = $(this).data('stage-id');
    let filter_options = [];
    if ($("#filter_options_in_issue").val().length > 0) {
      filter_options = JSON.parse($("#filter_options_in_issue").val().replace(/=>/g, ':'))
    }

    if (event.keyCode == 13 && title.trim() != '') {
      $(this).val("");
      $.ajax({
        type: "POST",
        url:"/master_plans/" + $("#project_manager_board").data('id') + "/issues",
        dataType: "script",
        data: {
          issue: { title: title, assignee_id: "" },
          stage_id: stage_id,
          screen: "board",
          filter_options: filter_options
        },
        success: function (data, textStatus, jqXHR) {

        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
	});

  $('.list_stage_in_board').each(function() {
    Sortable.create($(this)[0], {
      group: 'lists',
      animation: 150,
      handle: '.border_stage',
      filter: '.dropdown-menu',
      scroll: true,
      forceFallback: true,
			scrollSensitivity: 200,
      scrollSpeed: 20,
      ghostClass: 'sortable-hide',
      onStart: function () {
        $('.number_issue').click();
        $('.dropdown-menu').addClass('d-none');
      },
      onEnd: function(evt) {
        $.ajax({
          url: "/master_plans/" + $("#project_manager_board").data('id') + "/stages/update_row_order",
          method: 'GET',
          data: {
            row_order_position: evt.newIndex,
            id: $(evt.item).data('id')
          },
          success: function(response) {
            console.log("done");
          },
          error: function(xhr, status, error) {
            console.log(error);
          }
        });
        $('.dropdown-menu').removeClass('d-none');
      },
    });
  });

  drag_drop_issue_by_sortable();

  $('body').delegate('.pointer', 'click', function (e) {
    $(this).addClass("d-none");
    $(this).parent().find(".add_stage_input").removeClass("d-none").focus();
  });

  $('body').delegate('.add_stage_input', 'blur', function () {
    $(this).addClass("d-none");
    $(this).parent().find(".pointer").removeClass("d-none");
    $(this).val("");
  });

  $('body').delegate('.add_stage_input', 'keydown', function () {
    let title =  $(this).val();
    if (event.keyCode == 13 && title.trim() != '') {
      $(this).val("");
      $.ajax({
        type: "POST",
        url:"/master_plans/" + $("#project_manager_board").data('id') + "/stages",
        dataType: "script",
        data: {
          stage: {name: title, color: "blue", master_plan_id: $("#project_manager_board").data('id')}
        },
        success: function (data, textStatus, jqXHR) {

        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
  });

  $('body').delegate('.border_stage', 'dblclick', function (e) {
    if ($(e.target).is('.icon_more_project')) {
      e.stopPropagation();
      return;
    }
    $(this).addClass("d-none");
    let current_text = $(this).parent().find(".border_stage").children().first().text();
    $(this).parent().find(".update_stage_input").removeClass("d-none").focus().val(current_text);
  });

  $('body').delegate('.change_name_stage', 'click', function (e) {
    $(this).parent().parent().addClass("d-none");
    let current_text = $(this).parent().parent().children().first().text();
    $(this).parent().parent().parent().find(".update_stage_input").removeClass("d-none").focus().val(current_text);
  });

  $('body').delegate('.update_stage_input', 'blur', function () {
    $(this).addClass("d-none");
    $(this).parent().find(".border_stage").removeClass("d-none");
    $(this).val("");
  });

  $('body').delegate('.update_stage_input', 'keydown', function () {
    let title = $(this).val();
    let id = $(this).data('id')
    if (event.keyCode == 13 && title.trim() != '') {
      $(this).val("");
      $.ajax({
        type: "PUT",
        url:"/master_plans/" + $("#project_manager_board").data('id') + "/stages/" + id,
        dataType: "script",
        data: {
          stage: {name: title, master_plan_id: $("#project_manager_board").data('id')}
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
  });

  $('body').delegate('.delete_stage', 'click', function () {
    let number_issue = $(this).parent().parent().find(".number_issue").text()
    let id = $(this).parent().parent().parent().data('id')
    if (number_issue == 0) {
      $.ajax({
        type: "DELETE",
        url:"/master_plans/" + $("#project_manager_board").data('id') + "/stages/" + id,
        dataType: "script",
        data: {
         id: id,
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
    else {
      $.ajax({
        type: "GET",
        url:"/master_plans/" + $("#project_manager_board").data('id') + "/stages/confirm_delete_stage",
        dataType: "script",
        data: {},
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
      })
    }
  });

  $("body").delegate('.color-stage-select .color-item', 'click', function(ev) {
    let stage_color = $(this).attr("style").replace("background: ", "");
    let id = $(this).closest('.project_stages').data('id')
    update_stage_color(id, stage_color);
  });

  $("body").delegate('.color-stage-select .input-color', 'input', function(ev) {
    let stage_color = this.value;
    let id = $(this).closest('.project_stages').data('id')
    update_stage_color(id, stage_color);
  });
});

window.update_stage_color = function(id, stage_color) {
  $.ajax({
    type: "PUT",
    url:"/master_plans/" + $("#project_manager_board").data('id') + "/stages/" + id,
    dataType: "script",
    data: {
      stage: {color: stage_color}
    },
    success: function (data, textStatus, jqXHR) {
    },
    error: function (jqXHR, textStatus, errorThrown) {
      console.log(jqXHR.responseText)
    },
  })
}

window.drag_drop_issue_by_sortable = function() {
  if(mobileAndTabletCheck()){return} // anhvt disable drag drop when mobile or tablet

  $('.drag_drop_issue').each(function() {
    Sortable.create($(this)[0], {
      group: 'shared',
      animation: 150,
      scroll: true,
      forceFallback: true,
			scrollSensitivity: 200,
      scrollSpeed: 20,
      ghostClass: 'sortable-hide',
      filter: ".add_task_box",
      onStart: function () {
        $('.drag_drop_issue').addClass("h-100")
      },
      onAdd: function (evt) {
        if ($(evt.to).children().hasClass('add_task')) {
          $(evt.to).parent().find('.drag_drop_issue').first().append(evt.item);
        }

        if ($(evt.to.children).is('.add_task')) {
          evt.to =  evt.to.parentElement.querySelector('.drag_drop_issue')
          evt.newIndex = evt.to.childElementCount - 1
        }

        let filter_options = [];
        if ($("#filter_options_in_issue").val().length > 0) {
          filter_options = JSON.parse($("#filter_options_in_issue").val().replace(/=>/g, ':'))
        }

        $.ajax({
          url: "/master_plans/" + $("#project_manager_board").data('id') + "/issues/" +$(evt.item).data('issue-id') + "/update_stage_order",
          method: 'GET',
          data: {
            stage_id: $(evt.to).data('id'),
            stage_order_position: evt.newIndex,
            old_stage_id: $(evt.from).data('id'),
            filter_options: filter_options
          },
          success: function(response) {
            console.log('done');
          },
          error: function(xhr, status, error) {
            console.log(error);
          }
        });
      },
      onUpdate: function (evt) {
        $.ajax({
          url: "/master_plans/" + $("#project_manager_board").data('id') + "/issues/" + $(evt.item).data('issue-id') + "/update_stage_order",
          method: 'GET',
          data: {
            stage_id: $(evt.to).data('id'),
            stage_order_position: evt.newIndex
          },
          success: function(response) {
            console.log('done');
          },
          error: function(xhr, status, error) {
            console.log(error);
          }
        });
      },
      onEnd: function () {
        $('.drag_drop_issue').removeClass("h-100")
      },
    });
  });
}
