import I18n from 'i18n-js';
import { Sortable, AutoScroll } from 'sortablejs';
$(document).on('turbo:load', function() {
  if(!mobileAndTabletCheck()){
    set_drag_drop_schedules();
    set_drag_drop_month_schedules();
  }

  // }
  // $(".navbar-custom .button-menu-bar").each(function () {
  //   var pageUrl = window.location.href.split(/[/?#]/)[3];
  //   if ( pageUrl.includes("week_schedule") || pageUrl.includes("group_day") || pageUrl.includes("personal_day") || pageUrl.includes("month_schedule") || pageUrl.includes("personal_year") || pageUrl.includes("week_schedule") ) {
  //     if ( this.href.split(/[/?#]/)[3] == 'week_schedule') {
  //       $(this).addClass("item-active");
  //     }
  //   }

  //   if ( this.href.split(/[/?#]/)[3] == 'schedule_equipment' && pageUrl == "schedule_equipment") {
  //     $(this).addClass("item-active");
  //   }
  // });

  // event Enter submit form
  $("body").delegate('#modal-holiday', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_holiday").click();
    }
  });

  $("body").delegate('#modal-type-meeting', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_type_meeting").click();
    }
  });

  $("body").delegate('#import_schedule', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_submit_import_schedule").click();
    }
  });

  $("body").delegate('#setting_schedule', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_setting_schedule").click();
    }
  });

  $("body").delegate('#modal-category', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_add_category_schedule").click();
    }
  });

  $("body").delegate('#modal-change-index-categories', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateIndexCatagories").click();
    }
  });

  $("body").delegate('#modal-type-meeting', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateIndexTypeMeetings").click();
    }
  });

  // if (document.getElementById("schedule_type_schedule")) {
  //   if (document.getElementById("schedule_type_schedule").value == "1") {
  //     dates = new Array();
  //     dates.push(document.getElementById("check_date").value);
  //   }
  // }

  $('#list_department').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = data.element.attributes[0].value;
    var start_date = document.getElementById("date_week_schedule").getAttribute('value');
    if (data_type == undefined || data_type == "" ) data_type = "1"

    if (department_id == "0" ) {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);

      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/week_schedule?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&" + lst_user + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
    else {
      window.location.href = "/week_schedule?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
  });

  $('#list_department_group_day').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = data.element.attributes[0].value;
    var start_date = document.getElementById("date_group_day").getAttribute('value');
    if (data_type == undefined ) data_type = "1"

    if (department_id == "0" ) {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);

      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/group_day?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&" + lst_user + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
    else {
      window.location.href = "/group_day?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
  });

  $('#list_user_month_schedule').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var data = e.params.data;
    var user_id_month_schedule = data.id;
    var startdate = document.getElementById('dt_start_date').value;
    var id_department = document.getElementById('department_id').value;
    var data_type = $("#data_type").val();
    if (id_department == "0") {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);
      lst_user = $.param({lst_user:lst_user.split(",")});

      window.location.href = window.location.origin  + `/month_schedule?data_type=${data_type}&tab_page=${current_page}&startdate=${startdate}&user_id_month_schedule=${user_id_month_schedule}&department_id=${id_department}&${lst_user}`;
    }
    else {
      window.location.href = window.location.origin  + `/month_schedule?data_type=${data_type}&tab_page=${current_page}&startdate=${startdate}&user_id_month_schedule=${user_id_month_schedule}&department_id=${id_department}`;
    }

  });

// choose date normal schedule
  datepicker("#date_schedule");


// choose date over day schedule
  datepicker("#start_date_over_day_schedule");

  datepicker("#end_date_over_day_schedule");

// choose date period schedule
  datepicker("#start_date_period_schedule");

  datepicker("#end_date_period_schedule");

// choose date new repeat schedule
  datepicker("#date_deadline_schedule");

// choose date edit repeat schedule
  datepicker("#date_repeat");

// select department schedule
  $("body").delegate('#select_department_schedule', 'select2:select', function(e) {
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = "1";
    data_type = data.element.attributes[0].value;

    var options_user = document.getElementById("user_selected_schedule");
    var lst_user_selected = [];
    for (var i=0; i<options_user.length; i++) {
      lst_user_selected.push(options_user[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_user",
      dataType: 'script',
      data: {
        department_id: department_id,
        list_user: lst_user_selected,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

// select department overday schedule
  $("body").delegate('#select_department_over_day_schedule', 'select2:select', function(e) {
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = "1";
    data_type = data.element.attributes[0].value;

    var options_user = document.getElementById("user_selected_over_day_schedule");
    var lst_user_selected = [];
    for (var i=0; i<options_user.length; i++) {
      lst_user_selected.push(options_user[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_user",
      dataType: 'script',
      data: {
        department_id: department_id,
        list_user: lst_user_selected,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });

// select department period schedule
  $("#select_department_period_schedule").change( function() {
    var options_user = document.getElementById("user_selected_period_schedule");
    var department_id = $("#select_department_period_schedule option:selected").val();
    var data_type = "1";
    data_type = $("#select_department_period_schedule option:selected").attr('data-type');
    var lst_user_selected = [];
    for (var i=0; i<options_user.length; i++) {
      lst_user_selected.push(options_user[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_user",
      dataType: 'script',
      data: {
        department_id: department_id,
        list_user: lst_user_selected,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

// select department repeat schedule
  $("#select_department_repeat_schedule").change( function() {
    var data_type = "1";
    data_type = $("#select_department_repeat_schedule option:selected").attr('data-type');
    var options_user = document.getElementById("user_selected_repeat_schedule");
    var department_id = $("#select_department_repeat_schedule option:selected").val();

    var lst_user_selected = [];
    for (var i=0; i<options_user.length; i++) {
      lst_user_selected.push(options_user[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_user",
      dataType: 'script',
      data: {
        department_id: department_id,
        list_user: lst_user_selected,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

// move user screen normal schedule
  // $("#add_participant").click( function() {
  $("body").delegate('#add_participant', 'click', function() {
    var lst_user_select = document.getElementById('list_user_schedule');
    var lst_user_selected = document.getElementById('user_selected_schedule');
    if (lst_user_select.length > 0) {
      moveOption(lst_user_select, lst_user_selected);
    }
  });

// $("#remove_participant").click( function() {
  $("body").delegate('#remove_participant', 'click', function() {
    var lst_user_select = document.getElementById('list_user_schedule');
    var lst_user_selected = document.getElementById('user_selected_schedule');
    moveOption(lst_user_selected, lst_user_select);
  });

// move user screen over day schedule
  $("#add_participant_over_day").click( function() {
    var lst_user_select = document.getElementById('list_user_over_day_schedule');
    var lst_user_selected = document.getElementById('user_selected_over_day_schedule');
    if (lst_user_select.length > 0) {
      moveOption(lst_user_select, lst_user_selected);
    }
  });

  $("#remove_participant_over_day").click( function() {
    var lst_user_select = document.getElementById('list_user_over_day_schedule');
    var lst_user_selected = document.getElementById('user_selected_over_day_schedule');
    moveOption(lst_user_selected, lst_user_select);
  });

// move user screen period schedule
  $("#add_participant_period").click( function() {
    var lst_user_select = document.getElementById('list_user_period_schedule');
    var lst_user_selected = document.getElementById('user_selected_period_schedule');
    if (lst_user_select.length > 0) {
      moveOption(lst_user_select, lst_user_selected);
    }
  });

  $("#remove_participant_period").click( function() {
    var lst_user_select = document.getElementById('list_user_period_schedule');
    var lst_user_selected = document.getElementById('user_selected_period_schedule');
    moveOption(lst_user_selected, lst_user_select);
  });

// move user screen repeat schedule
  $("#add_participant_repeat").click( function() {
    var lst_user_select = document.getElementById('list_user_repeat_schedule');
    var lst_user_selected = document.getElementById('user_selected_repeat_schedule');
    if (lst_user_select.length > 0) {
      moveOption(lst_user_select, lst_user_selected);
    }
  });

  $("#remove_participant_repeat").click( function() {
    var lst_user_select = document.getElementById('list_user_repeat_schedule');
    var lst_user_selected = document.getElementById('user_selected_repeat_schedule');
    moveOption(lst_user_selected, lst_user_select);
  });

// select category screen normal schedule
  $('#select_category_schedule').on('select2:select', function (e) {
    var options_equipment = document.getElementById("equipment_selected_schedule");
    var lst_equipment_selected = [];
    for (var i=0; i<options_equipment.length; i++) {
      lst_equipment_selected.push(options_equipment[i].value);
    }
    var data = e.params.data;
    var category_id = data.id
    $.ajax({
      type: 'GET',
      url: "/filter_list_equipment",
      dataType: 'script',
      data: {
        category_id: category_id,
        list_equipment: lst_equipment_selected
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });

// select category screen over day schedule
  $("#select_category_over_day_chedule").change( function() {
    var options_equipment = document.getElementById("equipment_selected_overday_schedule");
    var lst_equipment_selected = [];
    for (var i=0; i<options_equipment.length; i++) {
      lst_equipment_selected.push(options_equipment[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_equipment",
      dataType: 'script',
      data: {
        overday_category_id: $("#select_category_over_day_chedule option:selected").val(),
        list_equipment: lst_equipment_selected
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });

// select category screen repeat schedule
  $("#select_category_repeat_chedule").change( function() {
    var options_equipment = document.getElementById("equipment_selected_repeat_schedule");
    var lst_equipment_selected = [];
    for (var i=0; i<options_equipment.length; i++) {
      lst_equipment_selected.push(options_equipment[i].value);
    }
    $.ajax({
      type: 'GET',
      url: "/filter_list_equipment",
      dataType: 'script',
      data: {
        repeat_category_id: $("#select_category_repeat_chedule option:selected").val(),
        list_equipment: lst_equipment_selected
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });

// move equipment screen normal schedule
  $("#add_equipment").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_schedule');
    if (lst_equipment_select.length > 0) {
      moveOption(lst_equipment_select, lst_equipment_selected);
    }
  });

  $("#remove_equipment").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_schedule');
    moveOption(lst_equipment_selected, lst_equipment_select);
  });

// move equipment screen over day schedule
  $("#add_equipment_over_day").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_over_day_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_overday_schedule');
    if (lst_equipment_select.length > 0) {
      moveOption(lst_equipment_select, lst_equipment_selected);
    }
  });

  $("#remove_equipment_over_day").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_over_day_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_overday_schedule');
    moveOption(lst_equipment_selected, lst_equipment_select);
  });

// move equipment screen repeat schedule
  $("#add_equipment_repeat").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_repeat_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_repeat_schedule');
    if (lst_equipment_select.length > 0) {
      moveOption(lst_equipment_select, lst_equipment_selected);
    }
  });

  $("#remove_equipment_repeat").click( function() {
    var lst_equipment_select = document.getElementById('list_equipment_repeat_schedule');
    var lst_equipment_selected = document.getElementById('equipment_selected_repeat_schedule');
    moveOption(lst_equipment_selected, lst_equipment_select);
  });

// choose radio button public/private screen normal schedule
  $("#public_false").click(function() {
    document.getElementById("schedule_is_public_false").checked = true;
  });

  $("#public_true").click(function() {
    document.getElementById("schedule_is_public_true").checked = true;
  });

// choose radio button public/private screen over day schedule
  $("#public_false_over_day").click(function() {
    document.getElementById("rdo_public_false_overday_schedule").checked = true;
  });

  $("#public_true_over_day").click(function() {
    document.getElementById("rdo_public_true_overday_schedule").checked = true;
  });

// choose radio button type repeat screen repeat schedule
  $("#type_1_repeat").click(function() {
    document.getElementById("rdo_type_1_repeat").checked = true;
  });

  $("#type_2_repeat").click(function() {
    document.getElementById("rdo_type_2_repeat").checked = true;
  });

  $("#type_4_repeat").click(function() {
    document.getElementById("rdo_type_4_repeat").checked = true;
  });

// choose radio button deadline/no deadline screen repeat schedule
  $("#no_deadline_repeat").click(function() {
    document.getElementById("schedule_no_deadline_true").checked = true;
  });

// choose radio button public/private screen repeat schedule
  $("#public_true_repeat").click(function() {
    document.getElementById("rdo_public_true_repeat_schedule").checked = true;
  });

  $("#public_false_repeat").click(function() {
    document.getElementById("rdo_public_false_repeat_schedule").checked = true;
  });

// choose radio button edit type time repeat schedule
  $("#rdo_time_repeat_this").click(function() {
    document.getElementById('edit_time_repeat_1').checked = true;
  });

  $("#rdo_time_repeat_after").click(function() {
    document.getElementById('edit_time_repeat_2').checked = true;
  });

  $("#rdo_time_repeat_all").click(function() {
    document.getElementById('edit_time_repeat_3').checked = true;
  });

// Initialize Dropzone
  Dropzone.autoDiscover = false;

  $('.dropzone').each(function () {
    let dropzoneControl = $(this)[0].dropzone;
    if (dropzoneControl) {
      dropzoneControl.destroy();
    }
  });

// Pre init code
  $(function() {
    //Dropzone class screen normal schedule
    var dropzone = $('#myDropzoneSchedule');
    if(dropzone.length > 0){
      $('#myDropzoneSchedule').dropzone({
        url: "/attach_document",
        autoProcessQueue: false,
        paramName: "file",
        maxFilesize: 16,
        parallelUploads: 100,
        // addRemoveLinks: true,
        previewTemplate: document.querySelector('#uploadPreviewTemplateNormalSchedule').innerHTML,
        maxFiles: 100,
        acceptedFiles: getListAcceptFileType("default"),
        clickable: true,
        accept: function(file, done) {
          defineIcon(file);
          done();
        },
        init: function() {
          setMessageErrorFile(this.options);
          var isSent = false;
          var myDropzone = this;
          document.getElementById("btnSaveDocumentNormalSchedule").addEventListener("click", function(e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          });

          this.on("error", function(file, message, xhr) {
            messageErrorFile(file, message);
						defineIcon(file);
            $(file.previewElement).find(".id_file_normal_schedule").removeClass("id_file_normal_schedule");
						var count_file_upload = document.getElementsByClassName("id_file_normal_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_normal_schedule').value = 0;
            }
          });

          this.on("addedfile", function(file) {
            document.getElementById('borrow_id_normal_schedule').style.display="block";
            document.getElementById('is_file_upload_normal_schedule').value = 1;
            var nameElement = file.previewElement.querySelector("[data-dz-name]");
            nameElement.title = file.name;
          });

          this.on("success", function(file, progress, bytesSent) {
            isSent = true;
          });

          this.on("uploadprogress", function(file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = progress + "%";
            }
          });

          this.on("removedfile", function (file) {
            // var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
            var count_file_upload = document.getElementsByClassName("id_file_normal_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_normal_schedule').value = 0;
            }
            console.log("remove");
          });

          this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
              var tab_calendar = document.getElementById('tab_calendar_normal_schedule').value;
              var user_id = document.getElementById('schedule_user_id').value;
              var date_select = document.getElementById('date_select').value;
              var department_id = document.getElementById('department_id').value;
              var cdate = document.getElementById('check_date').value;
              var selected_equipment_id = document.getElementById('selected_equipment_id').value;
              var selected_category_id = document.getElementById('selected_category_id').value;
              var data_type = document.getElementById('data_type').value;
              var current_page = $("#tab_page").val();
              var lst_user = "";
              if (department_id == "0") {
                lst_user = "&" + $.param({lst_user:JSON.parse(document.getElementById("lst_user").value)});
              }
              var lst_equipment = "";
              if (selected_category_id == "0") {
                lst_equipment = "&" + $.param({lst_equipment:JSON.parse(document.getElementById("lst_equipment").value)});
              }
              window.location.href = '/show_schedule?id=' + document.getElementById('id_schedule').value + "&tab_calendar=" + tab_calendar + "&user_id=" + user_id +
                "&date=" + date_select + "&department_id=" + department_id + "&cdate=" + cdate + "&equipment_id=" + selected_equipment_id + "&category_id=" + selected_category_id +
                "&data_type=" + data_type + "&tab_page=" + current_page + lst_user + lst_equipment;
            }
          });
        }
      });
    }

    //Dropzone class screen over day schedule
    var dropzone_overday = $('#myDropzoneOverDaySchedule');
    if(dropzone_overday.length > 0){
      $('#myDropzoneOverDaySchedule').dropzone({
        url: "/attach_document",
        autoProcessQueue: false,
        paramName: "file",
        maxFilesize: 16,
        parallelUploads: 100,
        // addRemoveLinks: true,
        previewTemplate: document.querySelector('#uploadPreviewTemplateOverDaySchedule').innerHTML,
        maxFiles: 100,
        acceptedFiles: getListAcceptFileType("default"),
        clickable: true,
        accept: function(file, done) {
          defineIcon(file);
          done();
        },
        init: function() {
          setMessageErrorFile(this.options);
          var isSent = false;
          var myDropzone = this;
          document.getElementById("btnSaveDocumentOverdaySchedule").addEventListener("click", function(e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          });
          this.on("error", function(file, message, xhr) {
            messageErrorFile(file, message);
						defineIcon(file);
            $(file.previewElement).find(".id_file_overday_schedule").removeClass("id_file_overday_schedule");
						var count_file_upload = document.getElementsByClassName("id_file_overday_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_overday_schedule').value = 0;
            }
          });

          this.on("addedfile", function(file) {
            document.getElementById('borrow_id_overday_schedule').style.display="block";
            document.getElementById('is_file_upload_overday_schedule').value = 1;
            var nameElement = file.previewElement.querySelector("[data-dz-name]");
            nameElement.title = file.name;
          });

          this.on("uploadprogress", function(file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = progress + "%";
            }
          });

          this.on("removedfile", function (file) {
            // var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
            var count_file_upload = document.getElementsByClassName("id_file_overday_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_overday_schedule').value = 0;
            }
          });

          this.on("success", function(file, progress, bytesSent) {
            isSent = true;
          });

          this.on("complete", function(file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
              var tab_calendar = document.getElementById('tab_calendar_overday_schedule').value;
              var user_id = document.getElementById('schedule_user_id').value;
              var date_select = document.getElementById('date_select').value;
              var department_id = document.getElementById('department_id').value;
              var cdate = document.getElementById('check_date').value;
              var selected_equipment_id = document.getElementById('selected_equipment_id').value;
              var selected_category_id = document.getElementById('selected_category_id').value;
              var data_type = document.getElementById('data_type').value;
              var current_page = $("#tab_page").val();
              var lst_user = "";
              if (department_id == "0") {
                lst_user = "&" + $.param({lst_user:JSON.parse(document.getElementById("lst_user").value)});
              }
              var lst_equipment = "";
              if (selected_category_id == "0") {
                lst_equipment = "&" + $.param({lst_equipment:JSON.parse(document.getElementById("lst_equipment").value)});
              }
              window.location.href = '/show_schedule?id=' + document.getElementById('id_overday_schedule').value + "&tab_calendar=" + tab_calendar +
                "&user_id=" + user_id + "&date=" + date_select + "&department_id=" + department_id + "&cdate=" + cdate + "&equipment_id=" + selected_equipment_id +
                "&category_id=" + selected_category_id + "&data_type=" + data_type + "&tab_page=" + current_page + lst_user + lst_equipment;
            }
          });
        }
      });
    }

    // Dropzone screen period schedule
    var dropzone_overday=$('#myDropzonePeriodSchedule');
    if(dropzone_overday.length>0){
      $('#myDropzonePeriodSchedule').dropzone({
        url: "/attach_document",
        autoProcessQueue: false,
        paramName: "file",
        maxFilesize: 16,
        parallelUploads: 100,
        // addRemoveLinks: true,
        previewTemplate: document.querySelector('#uploadPreviewTemplatePeriodSchedule').innerHTML,
        maxFiles: 100,
        acceptedFiles: getListAcceptFileType("default"),
        clickable: true,
        accept: function(file, done) {
          defineIcon(file);
          done();
        },
        init: function() {
          setMessageErrorFile(this.options);
          var isSent = false;
          var myDropzone = this;
          document.getElementById("btnSaveDocumentPeriodSchedule").addEventListener("click", function(e) {
            // Make sure that the form isn't actually being sent.
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          });
          this.on("error", function(file, message, xhr) {
            messageErrorFile(file, message);
						defineIcon(file);
            $(file.previewElement).find(".id_file_period_schedule").removeClass("id_file_period_schedule");
						var count_file_upload = document.getElementsByClassName("id_file_period_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_period_schedule').value = 0;
            }
          });

          this.on("addedfile", function(file) {
            document.getElementById('borrow_id_period_schedule').style.display="block";
            document.getElementById('is_file_upload_period_schedule').value = 1;
            var nameElement = file.previewElement.querySelector("[data-dz-name]");
            nameElement.title = file.name;
          });

          this.on("uploadprogress", function(file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = progress + "%";
            }
          });

          this.on("removedfile", function (file) {
            // var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
            var count_file_upload = document.getElementsByClassName("id_file_period_schedule").length;
            if (count_file_upload == 1) {
              document.getElementById('is_file_upload_period_schedule').value = 0;
            }
            console.log("remove");
          });

          this.on("success", function(file, progress, bytesSent) {
            isSent = true;
          });

          this.on("complete", function(file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
              var tab_calendar = document.getElementById('tab_calendar_period_schedule').value;
              var user_id = document.getElementById('schedule_user_id').value;
              var date_select = document.getElementById('date_select').value;
              var department_id = document.getElementById('department_id').value;
              var cdate = document.getElementById('check_date').value;
              var data_type = document.getElementById('data_type').value;
              var current_page = $("#tab_page").val();
              var lst_user = "";
              if (department_id == "0") {
                lst_user = "&" + $.param({lst_user:JSON.parse(document.getElementById("lst_user").value)});
              }
              // var lst_equipment = "";
              // if (selected_category_id == "0") {
              //   lst_equipment = "&" + $.param({lst_equipment:JSON.parse(document.getElementById("lst_equipment").value)});
              // }
              window.location.href = '/show_schedule?id=' + document.getElementById('id_period_schedule').value + "&tab_calendar=" + tab_calendar +
                "&user_id=" + user_id + "&date=" + date_select + "&department_id=" + department_id + "&cdate=" + cdate + "&data_type=" + data_type +
                "&tab_page=" + current_page + lst_user;
            }
          });
        }
      });
    }
  });

// choose start time screen normal schedule
  $("body").delegate('#schedule_time_start_hour', 'change', function () {
    var time_start_hour = $("#schedule_time_start_hour").val();
    var time_start_minutes = $("#schedule_time_start_minutes").val();
    var time_end_hour = $("#schedule_time_end_hour").val();
    var time_end_minutes = $("#schedule_time_end_minutes").val();
    var type_schedule = $("#schedule_type_schedule").val();
    if (time_start_hour == '') {
      $("#schedule_time_start_minutes").val('');
    }
    else {
      if (time_end_minutes == '') $("#schedule_time_end_minutes").val(0);
      if (time_start_minutes == '') $("#schedule_time_start_minutes").val(0);
      if (time_end_hour == '') {
        $("#schedule_time_end_hour").val(parseInt(time_start_hour) + 1);
      }
      else if((parseInt(time_start_hour) >= parseInt(time_end_hour)) && parseInt(type_schedule) == 1) {
        $("#schedule_time_end_hour").val(parseInt(time_start_hour) + 1);
      }
      // else{}
    }
  });

  $("body").delegate('#schedule_time_end_hour', 'change', function () {
    var time_start_hour = $("#schedule_time_start_hour").val();
    var time_start_minutes = $("#schedule_time_start_minutes").val();
    var time_end_hour = $("#schedule_time_end_hour").val();
    var time_end_minutes = $("#schedule_time_end_minutes").val();
    var type_schedule = $("#schedule_type_schedule").val();
    if (time_end_hour == '') {
      $("#schedule_time_end_minutes").val('');
    }
    else {
      if (time_end_minutes == '') $("#schedule_time_end_minutes").val(0);
      if (time_start_minutes == '') $("#schedule_time_start_minutes").val(0);
      if (time_start_hour == '') {
        var time_start_hour = (parseInt(time_end_hour) - 1) < 0 ? 0 : parseInt(time_end_hour) - 1;
        $("#schedule_time_start_hour").val(parseInt(time_start_hour));
      }
      else if((parseInt(time_end_hour) < parseInt(time_start_hour)) && (parseInt(type_schedule) == 1)) {
        var time_start_hour = (parseInt(time_end_hour) - 1) < 0 ? 0 : parseInt(time_end_hour) - 1;
        $("#schedule_time_start_hour").val(parseInt(time_start_hour));
      }
    }
  });

// choose start time screen over day schedule
  $("#time_start_hour_overday").change(function() {
    var time_start_hour = $("#time_start_hour_overday").val();
    var time_start_minutes = $("#time_start_minutes_overday").val();
    var time_end_hour = $("#time_end_hour_overday").val();
    var time_end_minutes = $("#time_end_minutes_overday").val();
    if (time_start_hour == '') {
      $("#time_start_minutes_overday").val('');
    }
    else {
      if (time_start_minutes == '' && time_end_hour == '' && time_end_minutes == '') {
        $("#time_start_minutes_overday").val(0);
        $("#time_end_hour_overday").val(parseInt(time_start_hour) + 1);
        $("#time_end_minutes_overday").val(0);
      }
      else if(time_start_minutes == '') {
        $("#time_start_minutes_overday").val(0);
      }
      else{}
    }
  });

  $("#time_end_hour_overday").change(function() {
    var time_start_hour = $("#time_start_hour_overday").val();
    var time_start_minutes = $("#time_start_minutes_overday").val();
    var time_end_hour = $("#time_end_hour_overday").val();
    var time_end_minutes = $("#time_end_minutes_overday").val();
    if (time_end_hour == '') {
      $("#time_end_minutes_overday").val('');
    }
    else {
      if (time_end_minutes == '') $("#time_end_minutes_overday").val(0);
    }
  });

// choose start time screen repeat schedule
  $("#time_start_hour_repeat").change(function() {
    var time_start_hour = $("#time_start_hour_repeat").val();
    var time_start_minutes = $("#time_start_minutes_repeat").val();
    var time_end_hour = $("#time_end_hour_repeat").val();
    var time_end_minutes = $("#time_end_minutes_repeat").val();
    if (time_start_hour == '') {
      $("#time_start_minutes_repeat").val('');
    }
    else {
      if (time_end_minutes == '') $("#time_end_minutes_repeat").val(0);
      if (time_start_minutes == '') $("#time_start_minutes_repeat").val(0);

      if (time_end_hour == '') {
        $("#time_end_hour_repeat").val(parseInt(time_start_hour) + 1);
      }
      else if((parseInt(time_start_hour) >= parseInt(time_end_hour))) {
        $("#time_end_hour_repeat").val(parseInt(time_start_hour) + 1);
      }
      else{}
    }
  });

  $("#time_end_hour_repeat").change(function() {
    var time_start_hour = $("#time_start_hour_repeat").val();
    var time_start_minutes = $("#time_start_minutes_repeat").val();
    var time_end_hour = $("#time_end_hour_repeat").val();
    var time_end_minutes = $("#time_end_minutes_repeat").val();
    if (time_end_hour == '') {
      $("#time_end_minutes_repeat").val('');
    }
    else {
      if (time_end_minutes == '') $("#time_end_minutes_repeat").val(0);
      if (time_start_minutes == '') $("#time_start_minutes_repeat").val(0);
      if (time_start_hour == '') {
        var time_start_hour = (parseInt(time_end_hour) - 1) < 0 ? 0 : parseInt(time_end_hour) - 1;
        $("#time_start_hour_repeat").val(parseInt(time_start_hour));
      }
      else if ((parseInt(time_end_hour) < parseInt(time_start_hour))) {
        var time_start_hour = (parseInt(time_end_hour) - 1) < 0 ? 0 : parseInt(time_end_hour) - 1;
        $("#time_start_hour_repeat").val(parseInt(time_start_hour));
      }
    }
  });

// validate screen create normal schedule
  $("#btnSaveSchedule").click(function() {
    validate_normal_schedule("create");
  });

// validate screen update normal schedule
  $("#btnUpdateNormalSchedule").click(function() {
    validate_normal_schedule("update");
  });

// validate screen create over day schedule
  $("#btn-save-over-day-schedule").click(function() {
    validate_overday_schedule("create");
  });

// validate screen update over day schedule
  $("#btnUpdateOverdaySchedule").click(function() {
    validate_overday_schedule("update");
  });

// validate screen create period schedule
  $("#btn-save-period-schedule").click(function() {
    validate_period_schedule("create");
  });

// validate screen update period schedule
  $("#btnUpdatePeriodSchedule").click(function() {
    validate_period_schedule("update");
  });

// validate screen create repeat schedule
  $("#btn-save-repeat-schedule").click(function() {
    validate_repeat_schedule("create");
  });

// validate screen update repeat schedule
  $("#btnUpdateRepeatSchedule").click(function() {
    validate_repeat_schedule("update");
  });

// submit screen create normal schedule
  $("#new_schedule").submit(function() {
    submit_normal_schedule();
  });

//submit screen update normal schedule
  $("#edit_normal_schedule").submit(function() {
    submit_normal_schedule();
  });

// submit screen create over day schedule
  $("#new_over_day_schedule").submit(function() {
    submit_overday_schedule();
  });

// submit screen update over day schedule
  $("#edit_over_day_schedule").submit(function() {
    submit_overday_schedule();
  });

// submit screen create period schedule
  $("#new_period_schedule").submit(function() {
    submit_period_schedule();
  });

// submit screen update period schedule
  $("#edit_period_schedule").submit(function() {
    submit_period_schedule();
  });

// submit screen create repeat schedule
  $("#new_repeat_schedule").submit(function() {
    submit_repeat_schedule("create");
  });

// submit screen update repeat schedule
  $("#edit_repeat_schedule").submit(function() {
    submit_repeat_schedule("update");
  });



  // $('.table-month-schedule tr').find('th.weeks-of-month:contains("日")').css('background-color','#fce8ef');
  // $('.table-month-schedule tr').find('td.wday-0').css('background-color','#fbe9e9');
  // $('.table-month-schedule tr').find('th.weeks-of-month:contains("土")').css('background-color','#dfeefc');
  // $('.table-month-schedule tr').find('td.wday-6').css('background-color','#e5fafe');
  // $('.table-month-schedule tr').find('td.today').css('background-color','#e3ffb2');
  // $('.table-month-schedule tr').find('td.today').css('color','#838f9c');

  $('#btnPrevWeek').click(function() {
    $("#text_week_date").disabled = false;
    $("#text_month_date").disabled = true;
  });

  $('#btnNextWeek').click(function() {
    $("#text_week_date").disabled = false;
    $("#text_month_date").disabled = true;
  });

  // $(".delete_file_schedule").click(function() {
  //   var checkbox_delete = this.classList[1].replace('file','is_delete_document');
  //   if (document.getElementById(checkbox_delete).checked) {
  //     document.getElementById(checkbox_delete).checked = false;
  //   }
  //   else {
  //     document.getElementById(checkbox_delete).checked = true;
  //   }
  // });


  // $("#btnDeleteScheduleTags").click(function(){
  $("body").delegate('#btnDeleteScheduleTags', 'click', function () {
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    var check_date = $('#check_date').val();
    var page = $("#page").val();
    $.ajax ({
      type: 'GET',
      url: "confirm_destroy_schedule_tags",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        check_date: check_date,
        page: page
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("body").delegate('#normal_tag_check', 'click', function (e) {
    if (!($("#delete_tag_1").is(":checked")) && !($("#delete_tag_2").is(":checked"))) {
      $('#error_confirm_destroy_schedule_tags').html(I18n.t('schedules.messages.choose_condition'));
      $('#error_confirm_destroy_schedule_tags').removeClass("d-none");
      e.preventDefault();
    }
  });

  $("body").delegate('#repeate_tag_check_all', 'click', function (e) {
    if (!($("#delete_tag_4_1").is(":checked")) && !($("#delete_tag_4_2").is(":checked"))) {
      $('#error_confirm_destroy_repeat_tag').html(I18n.t('schedules.messages.choose_condition'));
      $('#error_confirm_destroy_repeat_tag').removeClass("d-none");
      e.preventDefault();
    } else {
        if (!($("#delete_range_3").is(":checked")) && !($("#delete_range_4").is(":checked")) && !($("#delete_range_5").is(":checked"))) {
          $('#error_confirm_destroy_repeat_tag').html(I18n.t('schedules.messages.choose_condition'));
          $('#error_confirm_destroy_repeat_tag').removeClass("d-none");
        e.preventDefault();
      }
    }
  });
  $("body").delegate('#repeate_tag_check_one', 'click', function (e) {
    if (!($("#delete_range_3").is(":checked")) && !($("#delete_range_4").is(":checked")) && !($("#delete_range_5").is(":checked"))) {
      $('#error_confirm_destroy_repeat_tag').html(I18n.t('schedules.messages.choose_condition'));
      $('#error_confirm_destroy_repeat_tag').removeClass("d-none");
      e.preventDefault();
    }
  });
  $("body").delegate('#join_repeat_tag_check_one', 'click', function (e) {
    if (!($("#join_range_3").is(":checked")) && !($("#join_range_4").is(":checked")) && !($("#join_range_5").is(":checked"))) {
      $('#error_confirm_join_repeat_tag').html(I18n.t('schedules.messages.choose_condition'));
      $('#error_confirm_join_repeat_tag').removeClass("d-none");
      e.preventDefault();
    }
  });
  $("body").delegate('#get-out-repeate_tag_check_one', 'click', function (e) {
    if (!($("#get_out_range_3").is(":checked")) && !($("#get_out_range_4").is(":checked")) && !($("#get_out_range_5").is(":checked"))) {
      $('#error_confirm_get_out_repeat_tag').html(I18n.t('schedules.messages.choose_condition'));
      $('#error_confirm_get_out_repeat_tag').removeClass("d-none");
      e.preventDefault();
    }
  });


  $("#btnDeleteRepeatTag").click(function(){
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val(); // ngày user chọn delete
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var check_date = $('#check_date').val(); // ngày bắt đầu của lịch
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    $.ajax ({
      type: 'GET',
      url: "confirm_destroy_repeat_tag",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        check_date : check_date,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("#btnGetOutScheduleTags").click(function(){
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    var check_date = $('#check_date').val();
    $.ajax ({
      type: 'GET',
      url: "confirm_get_out_schedule_tags",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        check_date: check_date
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("#btnJoinScheduleTags").click(function(){
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    var check_date = $('#check_date').val();
    $.ajax ({
      type: 'GET',
      url: "confirm_join_schedule_tags",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        check_date: check_date
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("#btnGetOutRepeatTag").click(function(){
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var check_date = $('#check_date').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    $.ajax ({
			type: 'GET',
			url: "confirm_get_out_repeat_tag",
			dataType: 'script',
			data: {
				user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        check_date : check_date,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment
			},
			success: function(data, textStatus, jqXHR){
				//
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
			}
		});
  });

  $("#btnJoinRepeatTag").click(function(){
    var user_id = $('#user_id_show_schedule').val();
    var schedule_id = $('#schedule_id').val();
    var current_date = $('#current_date').val();
    var tab_calendar = $('#tab_calendar').val();
    var department_id = $('#department_id').val();
    var check_date = $('#check_date').val();
    var equipment_id = $('#selected_equipment_id').val();
    var category_id = $('#selected_category_id').val();
    var data_type = $('#data_type').val();
    var current_page = $("#tab_page").val();
    var lst_user = JSON.parse($("#lst_user").val());
    var lst_equipment = JSON.parse($("#lst_equipment").val());
    $.ajax ({
      type: 'GET',
      url: "confirm_join_repeat_tag",
      dataType: 'script',
      data: {
        user_id: user_id,
        schedule_id: schedule_id,
        current_date: current_date,
        tab_calendar: tab_calendar,
        department_id: department_id,
        check_date : check_date,
        equipment_id: equipment_id,
        category_id: category_id,
        data_type: data_type,
        tab_page: current_page,
        lst_user: lst_user,
        lst_equipment: lst_equipment
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("#btn_select_multi_date").click(function() {
    $("#btn_select_multi_date").addClass("d-none");
    $("#single_day").addClass("d-none");
    $("#multi_day").removeClass("d-none");
    $("#btn_select_single_date").removeClass("d-none");
    $("#list_multi_day").removeClass("d-none");
    $("#document_normal_schedule").addClass("d-none");
    $("#notice_upload_document").removeClass("d-none");
    if ($("#document_reuse_schedule").length > 0) $("#document_reuse_schedule").addClass("d-none")
    first_time_load_datepicker = true;
    if (dates.length == 0) dates.push(this.value);
    loadDatepicker();
  });

  $("#btn_select_single_date").click(function() {
    $("#btn_select_single_date").addClass("d-none");
    $("#multi_day").addClass("d-none");
    $("#list_multi_day").addClass("d-none");
    $("#single_day").removeClass("d-none");
    $("#btn_select_multi_date").removeClass("d-none");
    $("#document_normal_schedule").removeClass("d-none");
    $("#notice_upload_document").addClass("d-none");
    if ($("#document_reuse_schedule").length > 0) $("#document_reuse_schedule").removeClass("d-none")
  });

// modal create simple schedule
  // $("body").delegate('#schedule_time_start_hour', 'click', function (e) {
  //   var time_start_hour = $("#schedule_time_start_hour").val();
  //   var time_start_minutes = $("#schedule_time_start_minutes").val();
  //   var time_end_hour = $("#schedule_time_end_hour").val();
  //   var time_end_minutes = $("#schedule_time_end_minutes").val();
  //   if (time_start_hour == '') {
  //     $("#schedule_time_start_minutes").val('');
  //   }
  //   else {
  //     if (time_start_minutes == '' && time_end_hour == '' && time_end_minutes == '') {
  //       $("#schedule_time_start_minutes").val(0);
  //       $("#schedule_time_end_hour").val(parseInt(time_start_hour) + 1);
  //       $("#schedule_time_end_minutes").val(0);
  //     }
  //     else if(time_start_minutes == '') {
  //       $("#schedule_time_start_minutes").val(0);
  //     }
  //     else{}
  //   }
  // });

  // $("body").delegate('#schedule_time_end_hour', 'change', function (e) {
  //   var time_start_hour = $("#schedule_time_start_hour").val();
  //   var time_start_minutes = $("#schedule_time_start_minutes").val();
  //   var time_end_hour = $("#schedule_time_end_hour").val();
  //   var time_end_minutes = $("#schedule_time_end_minutes").val();
  //   if (time_end_hour == '') {
  //     $("#schedule_time_end_minutes").val('');
  //   }
  //   else {
  //     if (time_end_minutes == '') $("#schedule_time_end_minutes").val(0);
  //   }
  // });

  $("body").delegate('#public_false', 'click', function (e) {
    document.getElementById("schedule_is_public_false").checked = true;
  });

  $("body").delegate('#public_true', 'click', function (e) {
    document.getElementById("schedule_is_public_true").checked = true;
  });

// validate modal create simple schedule
  $("body").delegate('#btnSaveSimpleSchedule', 'click', function (e) {
    var time_start_hour = $("#schedule_time_start_hour").val();
    var time_end_hour = $("#schedule_time_end_hour").val();
    var date = $('#schedule_date').val();
    var time_start_minutes = $("#schedule_time_start_minutes").val();
    var time_end_minutes = $("#schedule_time_end_minutes").val();
    var start_time = new Date(date.replaceAll("-", "/") + " " + time_start_hour + ":" + time_start_minutes);
    var end_time = new Date(date.replaceAll("-", "/") + " " + time_end_hour + ":" + time_end_minutes);

    if ((start_time >= end_time) && (time_end_hour != '')) {
      $("#error_simple_schedule").text(I18n.t('schedules.messages.invalid_start_time'));
      if ($("#error_simple_schedule").hasClass("d-none")) {
        $("#error_simple_schedule").removeClass('d-none');
      }
    }
    else if (time_start_hour == '' && time_end_hour != '') {
      $("#error_simple_schedule").text(I18n.t('schedules.messages.no_start_time'));
      if ($("#error_simple_schedule").hasClass("d-none")) {
        $("#error_simple_schedule").removeClass('d-none');
      }
    }
    else if(isNaN(end_time)) {
      $("#error_simple_schedule").text("時刻が不正です。");
      if ($("#error_simple_schedule").hasClass("d-none")) {
        $("#error_simple_schedule").removeClass('d-none');
      }
    }
    else if ($("#equipment_id").length > 0 && time_start_hour == "" && time_start_minutes == "" && time_end_hour == "" && time_end_minutes == "") {
      $("#error_simple_schedule").text("施設を予約する場合は、時刻を入力してください。");
      $("#error_simple_schedule").removeClass('d-none');
    }
    else {
      document.getElementById("btnSave_simple_schedule").click();
    }
  });

  // $(".setting-color-schedule").click(function() {
  $("body").delegate('.setting-color-schedule', 'click', function () {
    var setting_colors = document.getElementsByClassName('setting-color-schedule');
    for(var i = 0; i < setting_colors.length; i++) {
      if (setting_colors[i].classList.contains("border-img")) {
        setting_colors[i].classList.remove("border-img");
      }
    }
    this.classList.add("border-img");
    var color = this.getAttribute('value');
    var schedule_id = document.getElementById('schedule_id').value;
    var date = document.getElementById('current_date').value;
    var tab_calendar = document.getElementById('tab_calendar').value;
    var tab_page = document.getElementById('tab_page').value;
    var equipment_id = document.getElementById('selected_equipment_id').value;
    $.ajax ({
      type: 'GET',
      url: "/setting_color_schedule",
      dataType: 'script',
      data: {
        schedule_id: schedule_id,
        color: color,
        date: date,
        tab_calendar: tab_calendar,
        tab_page: tab_page,
        equipment_id: equipment_id
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $('#btn-check-free-time-create-1').click(function(){
    var current_schedule = null;
    var date = $('#date_schedule')[0].getAttribute('value');
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#schedule_time_start_hour').val();
    var end_hour = $('#schedule_time_end_hour').val();
    var start_minute = $('#schedule_time_start_minutes').val();
    var end_minute = $('#schedule_time_end_minutes').val();
    var start_date = null;
    var end_date = null;
    $("#user_selected_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-update-1').click(function(){
    var current_schedule = $("#current-schedule-in-edit").val();
    var date = $("#date_schedule").val();
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#schedule_time_start_hour').val();
    var end_hour = $('#schedule_time_end_hour').val();
    var start_minute = $('#schedule_time_start_minutes').val();
    var end_minute = $('#schedule_time_end_minutes').val();
    var start_date = null;
    var end_date = null;
    $("#user_selected_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-create-2').click(function(){
    var current_schedule = null;
    var date = $("#start_date_over_day_schedule")[0].getAttribute('value');
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#time_start_hour_overday').val();
    var end_hour = $('#time_end_hour_overday').val();
    var start_minute = $('#time_start_minutes_overday').val();
    var end_minute = $('#time_end_minutes_overday').val();
    var start_date = $('#start_date_over_day_schedule')[0].getAttribute('value');
    var end_date = $('#end_date_over_day_schedule')[0].getAttribute('value');
    $("#user_selected_over_day_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_overday_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-update-2').click(function(){
    var current_schedule = $("#current-schedule-in-edit").val();
    var date = $("#start_date_over_day_schedule").val();
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#time_start_hour_overday').val();
    var end_hour = $('#time_end_hour_overday').val();
    var start_minute = $('#time_start_minutes_overday').val();
    var end_minute = $('#time_end_minutes_overday').val();
    var start_date = $('#start_date_over_day_schedule').val();
    var end_date = $('#end_date_over_day_schedule').val();
    $("#user_selected_over_day_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_overday_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-create-3').click(function(){
    var current_schedule = null;
    var date = $("#start_date_period_schedule")[0].getAttribute('value');
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = null;
    var end_hour = null;
    var start_minute = null;
    var end_minute = null;
    var start_date = null;
    var end_date = null;
    $("#user_selected_period_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_period_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-update-3').click(function(){
    var current_schedule = $("#current-schedule-in-edit").val();
    var date = $("#start_date_period_schedule")[0].getAttribute('value');
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = null;
    var end_hour = null;
    var start_minute = null;
    var end_minute = null;
    var start_date = null;
    var end_date = null;
    $("#user_selected_period_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_period_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-create-4').click(function(){
    var current_schedule = null;
    var date = $("#date_select").val();
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#time_start_hour_repeat').val();
    var end_hour = $('#time_end_hour_repeat').val();
    var start_minute = $('#time_start_minutes_repeat').val();
    var end_minute = $('#time_end_minutes_repeat').val();
    var start_date = null;
    var end_date = null;
    $("#user_selected_repeat_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_repeat_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $('#btn-check-free-time-update-4').click(function(){
    var current_schedule = $("#current-schedule-in-edit").val();
    var date = $("#date_select").val();
    var lst_user = [];
    var lst_equipment = [];
    var start_hour = $('#time_start_hour_repeat').val();
    var end_hour = $('#time_end_hour_repeat').val();
    var start_minute = $('#time_start_minutes_repeat').val();
    var end_minute = $('#time_end_minutes_repeat').val();
    var start_date = null;
    var end_date = null;
    $("#user_selected_repeat_schedule option").each(function(){
      lst_user.push($(this).val());
    });
    $("#equipment_selected_repeat_schedule option").each(function(){
      lst_equipment.push($(this).val());
    });
    check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date);
  });

  $("#btn_submit_import_schedule").click(function() {
    if(document.getElementById("schedule_file_file").value != "") {
     window.$("#loadMe").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
      });
    }
  });

  var element_parent;
  var count = 1;
  $('.tbl_check_freetime tbody tr').each(function() {
    if (this.children[0].textContent != '') {
      count = 1;
      element_parent = this;
    }
    else {
      count = count + 1;
      this.children[0].remove();
      this.children[0].remove();
      element_parent.children[0].rowSpan = count;
      element_parent.children[1].rowSpan = count;
    }
  });

  // change list user tab personal day
  $('#list_user_personal_day').on('select2:select', function (e) {
    var current_page = $("#tab_page").val();
    var data = e.params.data;
    var user_id = data.id;
    var data_type = $("#data_type").val();
    var start_date = document.getElementById("date_personal_day").getAttribute('value');
    var department_id = $("#department_id_personal_day").val();
    if (department_id == "0") {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);
      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/personal_day?data_type=" + data_type + "&department_id=" + department_id + "&" + lst_user + "&tab_page=" + current_page + "&user_id=" + user_id + "&start_date=" + start_date;
    }
    else {
      window.location.href = "/personal_day?data_type=" + data_type + "&department_id=" + department_id + "&tab_page=" + current_page + "&user_id=" + user_id + "&start_date=" + start_date;
    }
  });

  $("[class*=div-schedule-personal-day]").hover(function() {
    this.classList.add("div-hover-personal-day");
  },
  function() {
    this.classList.remove("div-hover-personal-day");
  });

  $("[class*=schedule-personal-day]").hover(function() {
    var id_column_schedule = this.id;
    var id_column_time = id_column_schedule.replace('schedule', 'time');
    var list_div_hover = document.getElementsByClassName("div-hover-personal-day");
    if (id_column_time != "" && id_column_schedule != "")
    {
      if (list_div_hover.length == 0)
      {
        $("#" + id_column_time).addClass("td-hover-personal-day");
        $("#" + id_column_schedule).addClass("td-hover-personal-day");
      }
      else {
        $("#" + id_column_schedule).removeClass("td-hover-personal-day");
      }
    }
  },
  function() {
    var id_column_schedule = this.id;
    var id_column_time = id_column_schedule.replace('schedule', 'time');
    var list_div_hover = document.getElementsByClassName("div-hover-personal-day");
    if (id_column_time != "" && id_column_schedule != "")
    {
      if (list_div_hover.length == 0) {
        $("#" + id_column_time).removeClass("td-hover-personal-day");
        $("#" + id_column_schedule).removeClass("td-hover-personal-day");
      }
      else {
        $("#" + id_column_schedule).removeClass("td-hover-personal-day");
      }
    }
  });

  $("[class*=time-personal-day]").hover(function() {
    var id_column_time = this.id;
    var id_column_schedule = id_column_time.replace('time', 'schedule');
    var list_div_hover = document.getElementsByClassName("div-hover-personal-day");
    // if (id_column_time != "" && id_column_schedule != "" && list_div_hover.length == 0)
    // {
      $("#" + id_column_schedule).addClass("td-hover-personal-day");
      $("#" + id_column_time).addClass("td-hover-personal-day");
    // }

  },
  function() {
    var id_column_time = this.id;
    var id_column_schedule = id_column_time.replace('time', 'schedule');
    var list_div_hover = document.getElementsByClassName("div-hover-personal-day");
    // if (id_column_time != "" && id_column_schedule != "" && list_div_hover.length == 0)
    // {
      $("#" + id_column_schedule).removeClass("td-hover-personal-day");
      $("#" + id_column_time).removeClass("td-hover-personal-day");
    // }
  });

  $("[class*=tbl-personal-row]").click(function() {
    var date = this.getAttribute("date");
    var start_hour = this.getAttribute("sh");
    var start_minute = this.getAttribute("sm");
    var end_hour = this.getAttribute("eh");
    var end_minute = this.getAttribute("em");
    var user_id = $("#list_user_personal_day option:selected").val();
    var list_div_hover = document.getElementsByClassName("div-hover-personal-day");
    var department_id = $("#department_id_personal_day").val();
    var data_type = $("#data_type").val();
    if (list_div_hover.length == 0)
    {
      $.ajax ({
        type: 'GET',
        url: "/new_simple_schedule",
        dataType: 'script',
        data: {
          day: date,
          start_hour: start_hour,
          start_minute: start_minute,
          end_hour: end_hour,
          end_minute: end_minute,
          user_id: user_id,
          tab_calendar: 4,
          tab_type_schedule: 1,
          department_id: department_id,
          data_type: data_type
        },
        success: function(data, textStatus, jqXHR){
          //
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error");
        }
      });
    }

  });

  $("body").delegate('#export-error-schedule', 'click', function (e) {
    export_error_schedule();
  });

  $("body").delegate('#btn_filter_schedule_user', 'click', function (e) {
    var user_id = document.getElementById("user_id").value;
    var lst_user_selected = [];
    var options_user = document.getElementById("user_selected_schedule_modal");
    if (options_user.length > 0) {
      for (var i=0; i<options_user.length; i++) {
        options_user[i].selected = "true";
        lst_user_selected.push(options_user[i].value);
      }
      set_item_user_in_storage(lst_user_selected, user_id);
    }
  });

// button filter schedule by list user
  $("#btnFilterUser").click(function() {
    var tab_calendar = this.getAttribute("value");
    var date = document.getElementById("date_schedule").value;
    var current_page = $("#tab_page").val();
    var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);

      lst_user = lst_user.split(",");
    // var data_type = $("#data_type").val();
    $.ajax({
      type: 'GET',
      url: "select_list_user",
      dataType: 'script',
      data: {
        start_date: date,
        tab_calendar: tab_calendar,
        data_type: "1",
        lst_user: lst_user,
        tab_page: current_page
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });


  // $("body").delegate('#select_department_schedule_modal', 'change', function() {
  // $('body').on('select2:select', '#select_department_schedule_modal', function(e) {
  // $('#select_department_schedule_modal').on('select2:select', function (e) {
  //   var options_user = document.getElementById("user_selected_schedule_modal");
  //   var data = e.params.data;
  //   var department_id = data.id.split("_")[0];
  //   var data_type = data.element.attributes[0].value;
  //   var lst_user_selected = [];
  //   for (var i=0; i<options_user.length; i++) {
  //     lst_user_selected.push(options_user[i].value);
  //   }
  //   $.ajax({
  //     type: 'GET',
  //     url: "/filter_list_user_modal",
  //     dataType: 'script',
  //     data: {
  //       department_id: department_id,
  //       list_user: lst_user_selected,
  //       data_type: data_type
  //     },
  //     success: function(data, textStatus, jqXHR){

  //     },
  //     error:function(jqXHR, textStatus, errorThrown){
  //       console.log("AJAX Error")
  //     }
  //   });
  // });


// move user modal filter schedule user
  $("body").delegate('#add_participant_modal', 'click', function() {
    var lst_user_select = document.getElementById('list_user_schedule_modal');
    var lst_user_selected = document.getElementById('user_selected_schedule_modal');
    if (lst_user_select.length > 0) {
      moveOption(lst_user_select, lst_user_selected);
    }
  });

  // $("#remove_participant").click( function() {
  $("body").delegate('#remove_participant_modal', 'click', function() {
    var lst_user_select = document.getElementById('list_user_schedule_modal');
    var lst_user_selected = document.getElementById('user_selected_schedule_modal');
    moveOption(lst_user_selected, lst_user_select);
  });

// datepicker group day
  $("#date_group_day").on("change", function(e) {
    var current_page = $("#tab_page").val()
    var start_date = this.getAttribute('value');
    var value_select_department = document.getElementById("list_department_group_day").value.split("_");
    var department_id = value_select_department[0];
    var data_type = value_select_department[1];
    if (department_id == "0") {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);
      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/group_day?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&" + lst_user + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
    else {
      window.location.href = "/group_day?data_type=" + data_type + "&department_id=" + department_id + "&tab_page=" + current_page + "&start_date=" + start_date;
    }
  });

// datepicker week schedule
  $("#date_week_schedule").on("change", function(e) {
    var current_page = $("#tab_page").val();
    var start_date = this.getAttribute('value');
    var value_select_department = document.getElementById("list_department").value.split("_");
    var department_id = value_select_department[0];
    var data_type = value_select_department[1] == undefined ? "1" : value_select_department[1];
    var page = $("#page").val() !== "" ? "&page=" + $("#page").val() : "";

    if (department_id == "0") {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);
      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/week_schedule?" + "department_id=" + department_id + "&" + "data_type=" + data_type + "&" + lst_user + "&tab_page=" + current_page + "&start_date=" + start_date + page;
    }
    else {
      window.location.href = "/week_schedule?data_type=" + data_type + "&department_id=" + department_id + "&tab_page=" + current_page + "&start_date=" + start_date + page;
    }
  });

// datepicker personal day
  $("#date_personal_day").on("change", function(e) {
    var current_page = $("#tab_page").val()
    var start_date = this.getAttribute('value');
    var user_id = document.getElementById("list_user_personal_day").value;
    var data_type = $("#data_type").val();
    var department_id = $("#department_id_personal_day").val();

    if (department_id == "0") {
      var lst_user = find_list_user_in_storage(document.getElementById("user_id").value);
      lst_user = $.param({lst_user:lst_user.split(",")});
      window.location.href = "/personal_day?data_type=" + data_type + "&department_id=" + department_id + "&" + lst_user + "&tab_page=" + current_page + "&user_id=" + user_id + "&start_date=" + start_date;
    }
    else {
      window.location.href = "/personal_day?data_type=" + data_type + "&department_id=" + department_id + "&tab_page=" + current_page + "&user_id=" + user_id + "&start_date=" + start_date;
    }
  });

  $(document).on("keydown", "#new_schedule, #new_over_day_schedule, #new_period_schedule, #new_repeat_schedule, #edit_normal_schedule, #edit_overday_schedule, #edit_period_schedule, #edit_repeat_schedule", function(event) {
    return event.key != "Enter";
  });

});

// get list attach file with re-use schedule
function getListAttachFileSchedule() {
  if (document.getElementById("list_attach_file_schedule") && !$("#document_reuse_schedule").hasClass("d-none") ) {
    var arr_id_file = []
    var file_attach = document.getElementsByName("ckb_document_schedule");
    for(var i = 0; i < file_attach.length; i++) {
      arr_id_file.push(file_attach[i].value)
    }
    document.getElementById("list_attach_file_schedule").value = arr_id_file;
  }
}

// set item session storage filter user
export function set_item_user_in_storage(lst_user_selected, current_user_id) {
  var users = [];
  var newList = new Object();
  newList.user_id = current_user_id;
  newList.user_selected = lst_user_selected.toString();
  if (sessionStorage.getItem("listUserSchedule") == '' || sessionStorage.getItem("listUserSchedule") == null) {
    users.push(newList);
  }
  else {
    users = JSON.parse(sessionStorage.getItem('listUserSchedule'));
    if (users.some(user => user.user_id == current_user_id)) {
      // users = JSON.parse(sessionStorage.getItem('listUserSchedule'));
      var objIndex = users.findIndex((user => user.user_id == current_user_id));
      users[objIndex].user_selected = lst_user_selected.toString();
      console.log(users);
    }
    else {
      users.push(newList);
    }
  }
  sessionStorage.setItem("listUserSchedule", JSON.stringify(users));
}
// get item session storage filter user
export function find_list_user_in_storage(current_user_id) {
  var users = [];
  var retrievedObject;
  var newList = new Object();
  newList.user_id = current_user_id;
  newList.user_selected = current_user_id;
  if (sessionStorage.getItem("listUserSchedule") == '' || sessionStorage.getItem("listUserSchedule") == null) {
    users.push(newList);
  }
  else {
    users = JSON.parse(sessionStorage.getItem('listUserSchedule'));
    if (users.some(user => user.user_id == current_user_id)) {
      users = JSON.parse(sessionStorage.getItem('listUserSchedule'));
    }
    else {
      users.push(newList);
    }
  }
  sessionStorage.setItem("listUserSchedule", JSON.stringify(users));
  retrievedObject = JSON.parse(sessionStorage.getItem('listUserSchedule'));
  const lst = retrievedObject.find(u => u.user_id === current_user_id);
    // console.log(lst);
  return lst['user_selected']
}


var dates = new Array();
var delete_action  = false;
var first_time_load_datepicker = true;
var delete_selected_date;
function loadDatepicker() {
  datepicker("#datepicker_multi_day", new Date(), true);
  $("#datepicker_multi_day").datepicker({
    beforeShowDay: function (date) {
      var year = date.getFullYear();
      // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
      var month = padNumber(date.getMonth() + 1);
      var day = padNumber(date.getDate());
      // This depends on the datepicker's date format
      var dateString = year + "-" + month + "-" + day;

      var gotDate = jQuery.inArray(dateString, dates);
      if (gotDate >= 0) {
          // Enable date so it can be deselected. Set style to be highlighted
          return [true, "active"];
      }
      // Dates not in the array are left enabled, but with no extra style
      return [true, ""];
    },
    // multidate: true,
  }).on('changeDate', function (event) {
    var viewDate = $("#datepicker_multi_day").data('datepicker').viewDate;
    var today = new Date();
    var deleteDate = new Date(delete_selected_date);
      if ((delete_action == false && typeof viewDate !== "undefined")
      || (viewDate.toDateString() === today.toDateString() && dates.length > 0 && isNaN(deleteDate) && deleteDate.toDateString() === viewDate.toDateString())){
        var year = viewDate.getFullYear();
        var month = padNumber(viewDate.getMonth() + 1);
        var day = padNumber(viewDate.getDate());
        var dateString = year + "-" + month + "-" + day;
        if (!event.handled) {
          if (!first_time_load_datepicker){
            addOrRemoveDate(dateString);
          }
          updateScroll();
          event.handled = true;
        }
      }

  });
  var list_dates = dates;
  var dt = $("#datepicker_multi_day").datepicker().data('datepicker');
  list_dates = list_dates.map(function(dateString) {
    return new Date(dateString);
  });
  dt.setDate(list_dates);
  delete_action = false;
  first_time_load_datepicker = false;
  printArray();
}

function deleteMultiDate() {
  var elements = document.getElementsByClassName("icon_delete_date");
  for(var i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", function() {
      delete_action = true;
      var date = $(this).parent().children().first().data("date");
      delete_selected_date = date;
      var index = jQuery.inArray(date, dates);
      if (index >= 0) {
        dates.splice(index, 1);
      }

      // $("#datepicker_multi_day").datepicker("destroy");
      loadDatepicker();
    });
  }
}
function addDate(date) {
  if (jQuery.inArray(date, dates) < 0) {
    dates.push(date);
  }
}

function removeDate(index) {
  dates.splice(index, 1);
}

function printArray() {
  var printArr = new String;
  $("#list_multi_date").empty();
  dates.sort(function(a,b){
    return new Date(a) - new Date(b)
  });
  dates.forEach(function (val) {
    var date = get_format_date(val);
    printArr += `<span class="row ps-0 mt-1 border-bottom"><span class="ps-1 fw-bold w-auto p-0" data-date=${val}>` + date + ' </span>' + '<i class="dripicons-cross text-danger icon_delete_date w-auto p-0"></i></span>';
    $('#list_multi_date').append(new Option(val, val));

  });
  $('#list_multi_day').html(printArr);
  $("#count_date_selected").text(dates.length);
  // document.getElementById("list_multi_date").value = dates;
  if (dates.length == 20) {
    $("#warning_limit_date").removeClass("d-none");
  }
  else {
    if (!$("#warning_limit_date").hasClass("d-none")) {
      $("#warning_limit_date").addClass("d-none");
    }
  }
  deleteMultiDate();
}
// Adds a date if we don't have it yet, else remove it
function addOrRemoveDate(date) {
    var index = jQuery.inArray(date, dates);
    if (index >= 0) {
      removeDate(index);
    }
    else {
      if (dates.length < 20) {
        addDate(date);
      }
    }
    printArray();
}

function padNumber(number) {
    var ret = new String(number);
    if (ret.length == 1) ret = "0" + ret;
    return ret;
}


export function moveOption(moveOptionFrom, moveOptionTo) {
  $(moveOptionFrom).find('option:selected').detach().prop("selected", false).appendTo($(moveOptionTo));
}

function submit_normal_schedule() {
  var options_user = document.getElementById("user_selected_schedule");
  for (var i=0; i<options_user.length; i++)
  {
    options_user[i].selected = "true";
    document.getElementById('btn_add_participant').click();
    var idUser = 'input[id^="schedule_schedule_users_attributes_"][id$="_user_id"]';
    $(idUser).each(function(){
      var current = $(this).attr("id");
      if (document.getElementById(current).value == "") {
        document.getElementById(current).value = options_user[i].value;
      }
    });
  }

  var options_equipment = document.getElementById("equipment_selected_schedule");
  if (options_equipment.length > 0) {
    for(var i=0; i<options_equipment.length; i++) {
      options_equipment[i].selected = "true";
      document.getElementById("btn_add_equipment").click();
      var idEquipment = 'input[id^="schedule_schedule_equipments_attributes_"][id$="_equipment_id"]';
      $(idEquipment).each(function(){
        var current = $(this).attr("id");
        if (document.getElementById(current).value == "") {
          document.getElementById(current).value = options_equipment[i].value;
        }
      });
    }
  }

  if ($("#single_day").hasClass("d-none")) {
    var options_date = document.getElementById("list_multi_date");
    for (var i=0; i<options_date.length; i++) {
      options_date[i].selected = "true";
    }
  }

  getListAttachFileSchedule();
}

function submit_overday_schedule() {
  var options_user = document.getElementById("user_selected_over_day_schedule");
  for (var i=0; i<options_user.length; i++)
  {
    options_user[i].selected = "true";
    document.getElementById('btn_add_participant_over_day').click();
    var idUser = 'input[id^="schedule_schedule_users_attributes_"][id$="_user_id"]';
    $(idUser).each(function(){
      var current = $(this).attr("id");
      if (document.getElementById(current).value == "") {
        document.getElementById(current).value = options_user[i].value;
      }
    });
  }

  var options_equipment = document.getElementById("equipment_selected_overday_schedule");
  if (options_equipment.length > 0) {
    for(var i=0; i<options_equipment.length; i++) {
      options_equipment[i].selected = "true";
      document.getElementById("btn_add_equipment_over_day").click();
      var idEquipment = 'input[id^="schedule_schedule_equipments_attributes_"][id$="_equipment_id"]';
      $(idEquipment).each(function(){
        var current = $(this).attr("id");
        if (document.getElementById(current).value == "") {
          document.getElementById(current).value = options_equipment[i].value;
        }
      });
    }
  }

  getListAttachFileSchedule();
}

function submit_period_schedule() {
  var options_user = document.getElementById("user_selected_period_schedule");
  for (var i=0; i<options_user.length; i++)
  {
    options_user[i].selected = "true";
    document.getElementById('btn_add_participant_period').click();
    var idUser = 'input[id^="schedule_schedule_users_attributes_"][id$="_user_id"]';
    $(idUser).each(function(){
      var current = $(this).attr("id");
      if (document.getElementById(current).value == "") {
        document.getElementById(current).value = options_user[i].value;
      }
    });
  }

  getListAttachFileSchedule();
}

function submit_repeat_schedule(type_submit) {
  var options_user = document.getElementById("user_selected_repeat_schedule");
  for (var i=0; i<options_user.length; i++) {
    options_user[i].selected = "true";
    if (type_submit == "create") {
      document.getElementById('btn_add_participant_repeat').click();
      var idUser = 'input[id^="schedule_schedule_users_attributes_"][id$="_user_id"]';
      $(idUser).each(function(){
        var current = $(this).attr("id");
        if (document.getElementById(current).value == "") {
          document.getElementById(current).value = options_user[i].value;
        }
      });
    }
  }

  var options_equipment = document.getElementById("equipment_selected_repeat_schedule");
  if (options_equipment.length > 0) {
    for(var i=0; i<options_equipment.length; i++) {
      options_equipment[i].selected = "true";
      if (type_submit == "create") {
        document.getElementById("btn_add_equipment_repeat").click();
        var idEquipment = 'input[id^="schedule_schedule_equipments_attributes_"][id$="_equipment_id"]';
        $(idEquipment).each(function(){
          var current = $(this).attr("id");
          if (document.getElementById(current).value == "") {
            document.getElementById(current).value = options_equipment[i].value;
          }
        });
      }
    }
  }

  getListAttachFileSchedule();
}

function validate_normal_schedule(type_submit) {
  var time_start_hour = $("#schedule_time_start_hour").val();
  var time_end_hour = $("#schedule_time_end_hour").val();
  var options_user = document.getElementById("user_selected_schedule");
  var options_equipment = document.getElementById("equipment_selected_schedule");
  var date = $('#date_schedule')[0].getAttribute('value');
  var time_start_minutes = $("#schedule_time_start_minutes").val();
  var time_end_minutes = $("#schedule_time_end_minutes").val();
  var start_time = new Date(date.replaceAll("-", "/") + " " + time_start_hour + ":" + time_start_minutes);
  var end_time = new Date(date.replaceAll("-", "/") + " " + time_end_hour + ":" + time_end_minutes);

  if ((start_time >= end_time) && (time_end_hour != '')) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (options_user.length == 0 && options_equipment.length == 0) {
    $("#content_warning").text(I18n.t('schedules.messages.no_participants_or_devices'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (time_start_hour == '' && time_end_hour != '') {
    $("#content_warning").text(I18n.t('schedules.messages.no_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (options_equipment.length > 0 && (time_start_hour == '' || time_end_hour == '' )) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_time_with_equipment'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if(isNaN(end_time)) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else {
    if (type_submit == "create") {
      if ($("#single_day").hasClass("d-none") && document.getElementById("list_multi_date").length == 0) {
        $("#content_warning").text(I18n.t('schedules.messages.no_date_selected'));
        window.$("#modal_warning_alert").modal('show');
      }
      else {
        var list_nested_participants = [];
      $('#list_participant > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_participants.push(elem);
        }
      });
      if (list_nested_participants.length > 0) {
        for (var i= 0; i < list_nested_participants.length; i++) {
          list_nested_participants[i].lastElementChild.click();
          list_nested_participants[i].children[1].value = 1
        }
      }

      var list_nested_equipments = [];
      $('#list_equipment > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_equipments.push(elem);
        }
      });
      if (list_nested_equipments.length > 0) {
        for (var i= 0; i < list_nested_equipments.length; i++) {
          list_nested_equipments[i].lastElementChild.click();
          list_nested_equipments[i].children[1].value = 1
        }
      }
        document.getElementById("btnSave_schedule").click();
      }
    }
    else {
      var list_nested_participants = [];
      $('#list_participant > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_participants.push(elem);
        }
      });
      if (list_nested_participants.length > 0) {
        for (var i= 0; i < list_nested_participants.length; i++) {
          list_nested_participants[i].lastElementChild.click();
          list_nested_participants[i].children[1].value = 1
        }
      }

      var list_nested_equipments = [];
      $('#list_equipment > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_equipments.push(elem);
        }
      });
      if (list_nested_equipments.length > 0) {
        for (var i= 0; i < list_nested_equipments.length; i++) {
          list_nested_equipments[i].lastElementChild.click();
          list_nested_equipments[i].children[1].value = 1
        }
      }
      document.getElementById("btnUpdate_schedule").click();
    }
  }
}

function validate_overday_schedule(type_submit) {
  var time_start_hour = $("#time_start_hour_overday").val();
  var time_end_hour = $("#time_end_hour_overday").val();
  var options_user = document.getElementById("user_selected_over_day_schedule");
  var start_date = (new Date($("#start_date_over_day_schedule")[0].getAttribute('value'))).toLocaleString('ja',{ dateStyle: 'short' });
  var end_date = (new Date($("#end_date_over_day_schedule")[0].getAttribute('value'))).toLocaleString('ja',{ dateStyle: 'short' });
  var options_equipment = document.getElementById("equipment_selected_overday_schedule");
  var time_start_minutes = $("#time_start_minutes_overday").val();
  var time_end_minutes = $("#time_end_minutes_overday").val();
  var start_time = new Date(start_date + " " + time_start_hour + ":" + time_start_minutes);
  var end_time = new Date(end_date + " " + time_end_hour + ":" + time_end_minutes);
  if (options_user.length == 0 && options_equipment.length == 0) {
    $("#content_warning").text(I18n.t('schedules.messages.no_participants_or_devices'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (time_start_hour == '' && time_end_hour != '') {
    $("#content_warning").text(I18n.t('schedules.messages.no_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if ((start_time >= end_time) && time_end_hour != '') {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (start_date > end_date) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_time_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if ((time_start_hour != '' && time_end_hour == '') && (start_time < end_time)) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (options_equipment.length > 0 && (time_start_hour == '' || time_end_hour == '' )) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_time_with_equipment'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if(isNaN(end_time)) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else {
    if (type_submit == "create") {
      var list_nested_participants = [];
      $('#list_participant_over_day > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_participants.push(elem);
        }
      });
      if (list_nested_participants.length > 0) {
        for (var i= 0; i < list_nested_participants.length; i++) {
          list_nested_participants[i].lastElementChild.click();
          list_nested_participants[i].children[1].value = 1;
        }
      }

      var list_nested_equipments = [];
      $('#list_equipment_over_day > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_equipments.push(elem);
        }
      });
      if (list_nested_equipments.length > 0) {
        for (var i= 0; i < list_nested_equipments.length; i++) {
          list_nested_equipments[i].lastElementChild.click();
          list_nested_equipments[i].children[1].value = 1;
        }
      }
      document.getElementById("btnSaveOverDaySchedule").click();
    }
    else {
      var list_nested_participants = [];
      $('#list_participant_over_day > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_participants.push(elem);
        }
      });
      if (list_nested_participants.length > 0) {
        for (var i= 0; i < list_nested_participants.length; i++) {
          list_nested_participants[i].lastElementChild.click();
          list_nested_participants[i].children[1].value = 1;
        }
      }

      var list_nested_equipments = [];
      $('#list_equipment_over_day > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_equipments.push(elem);
        }
      });
      if (list_nested_equipments.length > 0) {
        for (var i= 0; i < list_nested_equipments.length; i++) {
          list_nested_equipments[i].lastElementChild.click();
          list_nested_equipments[i].children[1].value = 1;
        }
      }
      document.getElementById("btnUpdate_overdaySchedule").click();
    }
  }
}

function validate_period_schedule(type_submit) {
  var start_date = new Date($("#start_date_period_schedule")[0].getAttribute('value'));
  var end_date = new Date($("#end_date_period_schedule")[0].getAttribute('value'));
  var options_user = document.getElementById("user_selected_period_schedule");
  if (options_user.length == 0) {
    $("#content_warning").text(I18n.t('schedules.messages.no_participants_or_devices'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (start_date > end_date) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_time_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if ($("#start_date_period_schedule").val() == '' || $("#end_date_period_schedule").val() == '') {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_or_end_date'));
    window.$("#modal_warning_alert").modal('show');
  }
  else {
    if (type_submit == "create") {
      document.getElementById("btnSavePeriodSchedule").click();
    }
    else {
      var list_nested_participants = [];
      $('#list_participant_period > div.nested-fields').each((index, elem) => {
        if (elem.style.display != "none") {
          list_nested_participants.push(elem);
        }
      });
      if (list_nested_participants.length > 0) {
        for (var i= 0; i < list_nested_participants.length; i++) {
          list_nested_participants[i].lastElementChild.click();
          list_nested_participants[i].children[1].value = 1;
        }
      }
      document.getElementById("btnUpdate_periodSchedule").click();
    }
  }
}

function validate_repeat_schedule(type_submit) {
  var time_start_hour = $("#time_start_hour_repeat").val();
  var time_end_hour = $("#time_end_hour_repeat").val();
  var options_user = document.getElementById("user_selected_repeat_schedule");
  var options_equipment = document.getElementById("equipment_selected_repeat_schedule");
  var date_select = document.getElementById("date_select").value;
  var date_deadline = document.getElementById("date_deadline_schedule").value;
  var no_dateline = document.getElementById("schedule_no_deadline_false").checked
  var start = new Date(date_select);
  var end = new Date(date_deadline);
  var time_start_minutes = $("#time_start_minutes_repeat").val();
  var time_end_minutes = $("#time_end_minutes_repeat").val();
  var start_time = new Date(date_select.replaceAll("-", "/") + " " + time_start_hour + ":" + time_start_minutes);
  var end_time = new Date(date_select.replaceAll("-", "/") + " " + time_end_hour + ":" + time_end_minutes);

  if (options_user.length == 0 && options_equipment.length == 0) {
    $("#content_warning").text(I18n.t('schedules.messages.no_participants_or_devices'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (time_start_hour == '' && time_end_hour != '') {
    $("#content_warning").text(I18n.t('schedules.messages.no_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if (options_equipment.length > 0 && (time_start_hour == '' || time_end_hour == '' )) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_time_with_equipment'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if ((start_time >= end_time) && (time_end_hour != '')) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_start_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else if(isNaN(end_time)) {
    $("#content_warning").text(I18n.t('schedules.messages.invalid_end_time'));
    window.$("#modal_warning_alert").modal('show');
  }
  else {
    if (type_submit == "create") {
      if (document.getElementById('schedule_no_deadline_false').checked && (end < start)) {
        $("#content_warning").text(I18n.t('schedules.messages.invalid_deadline'));
        window.$("#modal_warning_alert").modal('show');
      }
      else if ((no_dateline) && (find_next_day_avaiable(start, end)))
      {
        $("#content_warning").text(I18n.t('schedules.messages.event_deleted_or_rescheduled'));
        window.$("#modal_warning_alert").modal('show');
      }
      else
      {
        var list_nested_participants = [];
        $('#list_participant_repeat > div.nested-fields').each((index, elem) => {
          if (elem.style.display != "none") {
            list_nested_participants.push(elem);
          }
        });
        if (list_nested_participants.length > 0) {
          for (var i= 0; i < list_nested_participants.length; i++) {
            list_nested_participants[i].lastElementChild.click();
            list_nested_participants[i].children[1].value = 1;
          }
        }

        var list_nested_equipments = [];
        $('#list_equipment_repeat > div.nested-fields').each((index, elem) => {
          if (elem.style.display != "none") {
            list_nested_equipments.push(elem);
          }
        });
        if (list_nested_equipments.length > 0) {
          for (var i= 0; i < list_nested_equipments.length; i++) {
            list_nested_equipments[i].lastElementChild.click();
            list_nested_equipments[i].children[1].value = 1;
          }
        }
        document.getElementById("btnSaveRepeatSchedule").click();
      }
    }
    else {
      var old_time_start = new Date(document.getElementById('old_time_start').value);
      if (document.getElementById('edit_time_repeat_3').checked)
      {
        start = new Date(old_time_start);
      }
      if ($('input[name=edit_time_repeat]:checked').length == 0) {
        $("#content_warning").text(I18n.t('schedules.messages.choose_condition'));
        window.$("#modal_warning_alert").modal('show');
      }
      else {
        if (document.getElementById('schedule_no_deadline_false').checked && (end < start) && document.getElementById('edit_time_repeat_2').checked) {
          $("#content_warning").text(I18n.t('schedules.messages.invalid_deadline'));
          window.$("#modal_warning_alert").modal('show');
        }
        else if (document.getElementById('schedule_no_deadline_false').checked && (end < old_time_start) && document.getElementById('edit_time_repeat_3').checked) {
          $("#content_warning").text(I18n.t('schedules.messages.invalid_deadline'));
          window.$("#modal_warning_alert").modal('show');
        }
        else if ((no_dateline) && (find_next_day_avaiable(start, end)))
        {
          $("#content_warning").text(I18n.t('schedules.messages.event_deleted_or_rescheduled'));
            window.$("#modal_warning_alert").modal('show');
          }
          else {
            var list_nested_participants = [];
          $('#list_participant_repeat > div.nested-fields').each((index, elem) => {
            if (elem.style.display != "none") {
              list_nested_participants.push(elem);
            }
          });
          if (list_nested_participants.length > 0) {
            for (var i= 0; i < list_nested_participants.length; i++) {
              list_nested_participants[i].lastElementChild.click();
              list_nested_participants[i].children[1].value = 1;
            }
          }

          var list_nested_equipments = [];
          $('#list_equipment_repeat > div.nested-fields').each((index, elem) => {
            if (elem.style.display != "none") {
              list_nested_equipments.push(elem);
            }
          });
          if (list_nested_equipments.length > 0) {
            for (var i= 0; i < list_nested_equipments.length; i++) {
              list_nested_equipments[i].lastElementChild.click();
              list_nested_equipments[i].children[1].value = 1;
            }
          }
          document.getElementById("btnUpdate_repeatSchedule").click();
        }
      }
    }
  }
}

function find_next_day_avaiable(start, end)
{
  var repeat_type_2 = document.getElementById("rdo_type_2_repeat").checked
  var repeat_type_3 = document.getElementById("rdo_type_3_repeat").checked
  var repeat_type_4 = document.getElementById("rdo_type_4_repeat").checked
  if (start <= end)
  {
    if (repeat_type_2)
    {
      if((start.getDay() == 6 || start.getDay() == 0) && (end.getDay() == 6 || end.getDay() == 0) && (((end - start)/86400000) <= 1))
      {
        return true
      }
      else
      {
        return false
      }
    }
    else if (repeat_type_3)
    {
      var weekly = parseInt(document.getElementById('weekly').value);
      var weekday = parseInt(document.getElementById('weekday').value);
      var day_in_month = start;
      if (weekly == 0)
      {
        if (start.getDay() == weekday)
        {
          day_in_month = start;
        }
        else if (start.getDay() < weekday)
        {
          day_in_month.setDate(start.getDate() + (weekday-start.getDay()));
        }
        else
        {
          if (weekday == 0)
          {
            day_in_month.setDate(start.getDate() + (weekday-start.getDay()) + 1);
          }
          else
          {
            day_in_month.setDate(start.getDate() + 7 - (start.getDay()-weekday));
          }
        }
        while (day_in_month < start)
        {
          day_in_month.setDate(start.getDate() + 7);
        }
      }
      else
      {
        day_in_month = find_avaiable_wday(start);
        if (day_in_month < start)
        {
          day_in_month = find_avaiable_wday(new Date(start.getFullYear(), start.getMonth() + 1, start.getDate()));
        }
      }
      if (day_in_month <= end)
      {
        return false
      }
      else
      {
        return true
      }
    }
    else if (repeat_type_4)
    {
      var monthly = parseInt(document.getElementById('monthly').value);
      if (monthly == start.getDate())
      {
        var day_in_month = start;
      }
      else if (monthly > start.getDate())
      {
        var current_month = start.getMonth();
        var day_in_month = new Date(start.getFullYear(), current_month, monthly);
        while (day_in_month.getDate() != monthly)
        {
          current_month++;
          day_in_month = new Date(start.getFullYear(), current_month, monthly);
        }
      }
      else if (monthly == 0)
      {
        var day_in_month = new Date(start.getFullYear(), start.getMonth()+1, 0);
      }
      else
      {
        var current_month = start.getMonth();
        var day_in_month = new Date(start.getFullYear(), current_month, monthly);
        if (day_in_month < start)
        {
          current_month++;
          day_in_month = new Date(start.getFullYear(), current_month, monthly);
        }
        while (day_in_month.getDate() != monthly)
        {
          current_month++;
          day_in_month = new Date(start.getFullYear(), current_month, monthly);
        }
      }
      if (day_in_month <= end)
      {
        return false
      }
      else
      {
        return true
      }
    }
    else
    {
      return false
    }
  }
  else
  {
    return false
  }
}

function find_avaiable_wday(start)
{
  var weekly = parseInt(document.getElementById('weekly').value);
  var weekday = parseInt(document.getElementById('weekday').value);
  var day_in_month = start;
  if (weekly == 5)
  {
    var end_of_month = new Date(start.getFullYear(), start.getMonth()+1, 0);
    var day_in_month = end_of_month;
    if (end_of_month.getDay() == weekday)
    {
      day_in_month = end_of_month;
    }
    else if (end_of_month.getDay() < weekday)
    {
      day_in_month.setDate(end_of_month.getDate() - 7 + (weekday-end_of_month.getDay()));
    }
    else
    {
      day_in_month.setDate(end_of_month.getDate() - (end_of_month.getDay()-weekday));
    }
  }
  else
  {
    var start_of_month = new Date(start.getFullYear(), start.getMonth(), 1);
    var first_wday_in_month = start_of_month;
    if (start_of_month.getDay() == weekday)
    {
      first_wday_in_month = start_of_month;
    }
    else if (start_of_month.getDay() < weekday)
    {
      first_wday_in_month.setDate(start_of_month.getDate() + (weekday-start_of_month.getDay()));
    }
    else
    {
      if (weekday == 0)
      {
        first_wday_in_month.setDate(start_of_month.getDate() + (weekday-start_of_month.getDay()) + 1);
      }
      else
      {
        first_wday_in_month.setDate(start_of_month.getDate() + 7 - (start_of_month.getDay()-weekday));
      }
    }
    var day_in_month = first_wday_in_month;
    day_in_month.setDate(first_wday_in_month.getDate() + 7 * (weekly-1))
    if (day_in_month < start)
    {
      start_of_month = new Date(start.getFullYear(), start.getMonth()+1, 1);
      if (start_of_month.getDay() == weekday)
      {
        first_wday_in_month = start_of_month;
      }
      else if (start_of_month.getDay() < weekday)
      {
        first_wday_in_month = start_of_month;
        first_wday_in_month.setDate(start_of_month.getDate() + (weekday-start_of_month.getDay()));
      }
      else
      {
        if (weekday == 0)
        {
        first_wday_in_month = start_of_month;
          first_wday_in_month.setDate(start_of_month.getDate() + (weekday-start_of_month.getDay()) + 1);
        }
        else
        {
        first_wday_in_month = start_of_month;
          first_wday_in_month.setDate(start_of_month.getDate() + 7 - (start_of_month.getDay()-weekday));
        }
      }
      day_in_month = first_wday_in_month;
      day_in_month.setDate(first_wday_in_month.getDate() + 7 * (weekly-1))
    }
  }
  return day_in_month
}


function updateScroll(){
  var list_multi_day_scroll = document.getElementById("list_multi_day");
  list_multi_day_scroll.scrollTop = list_multi_day_scroll.scrollHeight;
}



function check_free_time(date, lst_user, lst_equipment, current_schedule, start_hour, end_hour, start_minute, end_minute, start_date, end_date)
{
  var type_schedule = $('#schedule_type_schedule').val();
  if (lst_user.length == 0 && lst_equipment.length == 0) {
    $("#content_warning").text(I18n.t('schedules.messages.no_member_or_equipment_selected'));
    window.$("#modal_warning_alert").modal('show');
  }
  else {
    $.ajax({
      type: 'GET',
      url: "/check_free_time",
      dataType: 'script',
      data: {
        date: date,
        lst_user: lst_user,
        lst_equipment: lst_equipment,
        current_schedule: current_schedule,
        start_hour: start_hour,
        end_hour: end_hour,
        start_minute: start_minute,
        end_minute: end_minute,
        type_schedule: type_schedule,
        start_date: start_date,
        end_date: end_date
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  }
}

function export_error_schedule()
{
  var oTable = document.getElementById("tbl-export-error-schedule");
  var rowLength = oTable.rows.length;
  var rows = [["イベントID", "開始日付", "開始時刻", "終了日付", "終了時刻", "予定", "予定詳細", "メモ", "参加者", "施設"]]; // I18n.t('schedules.modal_export_error_schedule.id')
    //loops through rows
    for (var i = 0; i < rowLength; i++){
      var row = []
      //gets cells of current row
       var oCells = oTable.rows.item(i).cells;

       //gets amount of cells of current row
       var cellLength = oCells.length;

       //loops through each cell in current row
       for(var j = 0; j < cellLength; j++){

              // get your cell info here

              var cellVal = oCells.item(j).innerHTML;
              row.push(cellVal);
           }
      rows.push(row);
    }

  let csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF"
    + rows.map(e => e.join(",")).join("\n");

  var encodedUri = encodeURI(csvContent);
  // var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö';
  // var universalBOM = "\uFEFF";
  var link = document.createElement("a");
  link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodedUri);
  link.setAttribute("download", "error_schedule.csv");
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "my_data.csv".
}
// function load user use select2 in modal
window.filter_user_select2_in_modal = function(element_select2_id, users_selected, url, modal_id) {

  $(element_select2_id).select2({dropdownParent: $(modal_id) });

  $(element_select2_id).on('select2:select', function (e) {
    var options_user = document.getElementById(users_selected);
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = data.element.attributes[0].value;
    var lst_user_selected = [];
    for (var i=0; i<options_user.length; i++) {
      lst_user_selected.push(options_user[i].value);
    }
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        department_id: department_id,
        list_user: lst_user_selected,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });
}
