$(document).on('turbo:load', function() {
  $("#js-export-bulletins-submit").on('click', function(e) {
    e.preventDefault();
    let errors = 0;
    let format = 'YYYY-MM-DD';
    let start_time = $("#bulletin_start_time")[0].getAttribute('value');
    let end_time = $("#bulletin_end_time")[0].getAttribute('value');
    let time_errors = 0

    if ($("#listBulletinCategory").val() == "") {
      errors += 1
      $(".bulletin_category_id_errors").html(I18n.t("export_bulletins.validate.category_blank"))
    } else {
      $(".bulletin_category_id_errors").html("")
    }

    if (!moment(start_time, format, true).isValid() || $("#bulletin_start_time").val() == '') {
      errors += 1
      time_errors +=1
      $(".start_time_errors").html(I18n.t("export_bulletins.validate.start_time_blank"))
    } else {
      $(".start_time_errors").html("")
    }

    if (!moment(end_time, format, true).isValid() || $("#bulletin_end_time").val() == '') {
      errors += 1
      time_errors +=1
      $(".end_time_errors").html(I18n.t("export_bulletins.validate.end_time_blank"))
    } else {
      $(".end_time_errors").html("")
    }

    if (time_errors == 0) {
      if (new Date(start_time) > new Date(end_time)) {
        errors += 1
        $(".time_errors").html(I18n.t("export_bulletins.validate.error_time"))
      } else {
        $(".time_errors").html("")
      }
    }

    if (errors == 0) {
      $("#js-export-bulletins-form").submit();
    }
  })
})

