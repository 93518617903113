// import { Callbacks } from 'jquery';
// import Quill from 'quill';
//import QuillMention from 'quill-mention';
// anhvt fix bugs warning : quill Overwriting modules/mention with ƒ Mention(quill, options) 
// import 'quill-mention';

import { CustomEditorText } from "../libs/quill/custome_editor_text";


// Quill.register({ 'modules/mention': QuillMention });
$(document).ready(function() {
	if ($("#editor_content_bulletin").length > 0) {
		if ($(".form-bulletin").length > 0) {
			var form = document.querySelector('.form-bulletin');
		}		
		var input = { 
			id: 'editor_content_bulletin',
			status: false
		}
		var text_Editor = new CustomEditorText(input)
		form.onsubmit = function() {
			var postContentInput = document.querySelector('#bulletin_content');
			postContentInput.value = text_Editor.value();
		};
	}
	if ($("#bulletin_comment_content").length > 0) {
		load_quill_comment();
	}
	initButtonTitle();
});

$(document).on('turbo:load', function() {
  // event keydown Enter submit form
  $("body").delegate('#import_bulletin', 'keydown', function (event) {
		if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_import_bulletin").click();
		}
	});

  $("body").delegate('#export_bulletins', 'keydown', function (event) {
		if (event.key === "Enter") {
      event.preventDefault();
      $("#js-export-bulletins-submit").click();
		}
	});

	$("#left_bar_bulletin ul a").click(function() {
			// remove classname 'active' from all li who already has classname 'active'
			$("#list_categories_default a.bulletin_active").removeClass("bulletin_active");
			$("#list_bulletin_categories_default a.bulletin_active").removeClass("bulletin_active");
			$("#list_bulletin_categories a.bulletin_active").removeClass("bulletin_active");
			// adding classname 'active' to current click li 
			this.classList.add("bulletin_active");
	});

	// datepicker("#bulletin_start_time");

	$("#text_starttime_bulletin").click(function() {
			document.getElementById("bulletin_is_publish").click();
	});

	$("#bulletin_is_publish").click(function() {
			var checked_value = this.checked;
			toggle_disabled_bulletin(checked_value, "bulletin_start_time", "start_time_hour", "start_time_minutes");
			// $("#content_start_time").toggleClass("bg-disabled");
	});

	// choose end date bulletin
	datepicker("#bulletin_end_time");

	$("#text_endtime_bulletin").click(function() {
			document.getElementById("bulletin_is_finish").click();
	});

	$("#bulletin_is_finish").click(function() {
			var checked_value = this.checked;
			toggle_disabled_bulletin(checked_value, "bulletin_end_time", "end_time_hour", "end_time_minutes");
			// $("#content_end_time").toggleClass("bg-disabled");
	});

	$("#text_allow_comment").click(function() {
		document.getElementById("bulletin_allow_comment").click();
	});

	$("#bulletin_allow_comment").click(function() {
			var checked_value = this.checked;
			if (checked_value) {
					document.getElementById("bulletin_allow_emotion").checked = true;
					document.getElementById("bulletin_allow_emotion").disabled = false;
					// document.getElementById("direct_type_emotion").disabled = false;
					document.getElementById("listEmotion").disabled = false;
			} else {
					document.getElementById("bulletin_allow_emotion").checked = false;
					document.getElementById("bulletin_allow_emotion").disabled = true;
					// document.getElementById("direct_type_emotion").disabled = true;
					document.getElementById("listEmotion").disabled = true;
			}
	});

	$("#text_allow_emotion").click(function() {
		document.getElementById("bulletin_allow_emotion").click();
	});

		// $("#bulletin_allow_emotion").click(function() {
		// 		var checked_value = this.checked;
		// 		if (checked_value) {
		// 				// document.getElementById("direct_type_emotion").disabled = false;
		// 				// document.getElementById("listEmotion").disabled = false;
		// 		} else {
		// 				// document.getElementById("direct_type_emotion").disabled = true;
		// 				// document.getElementById("listEmotion").disabled = true;
		// 		}
		// });

		// $("#listEmotion").change(function() {
		// 		var emotion_type = $("#listEmotion option:selected").val();
		// 		if (emotion_type == "0") {
		// 				$("#direct_type_emotion").removeClass("d-none");
		// 		} else {
		// 				$("#direct_type_emotion").addClass("d-none");
		// 		}
		// });

	// Initialize Dropzone
	Dropzone.autoDiscover = false;
	$('.dropzone').each(function() {
		let dropzoneControl = $(this)[0].dropzone;
		if (dropzoneControl) {
				dropzoneControl.destroy();
		}
	});

	$(function() {
		//Dropzone bulletin document
		var dropzoneBulletin = $('#myDropzoneBulletin');
		if (dropzoneBulletin.length > 0) {
			$('#myDropzoneBulletin').dropzone({
				url: "/attach_bulletin_document",
				autoProcessQueue: false,
				paramName: "file",
				maxFilesize: 16,
				parallelUploads: 100,
				// addRemoveLinks: true,
				previewTemplate: document.querySelector('#uploadPreviewTemplateBulletin').innerHTML,
				maxFiles: 100,
				acceptedFiles: getListAcceptFileType("default"),
				clickable: true,
				accept: function(file, done) {
					defineIcon(file);
					done();
				},
				
					init: function() {
						setMessageErrorFile(this.options);
						var isSent = false;
						var myDropzone = this;
						document.getElementById("btnSaveDocumentBulletin").addEventListener("click", function(e) {
							// Make sure that the form isn't actually being sent.
							e.preventDefault();
							e.stopPropagation();
							myDropzone.processQueue();

						});

						this.on("error", function(file, message, xhr) {
							messageErrorFile(file, message);
							defineIcon(file);
							$(file.previewElement).find(".id_file_bulletin").removeClass("id_file_bulletin");
							var count_file_upload = document.getElementsByClassName("id_file_bulletin").length;
							if (count_file_upload == 1) {
									document.getElementById('is_file_upload_document_bulletin').value = 0;
							}
						});

						this.on("addedfile", function(file) {
								document.getElementById('borrow_id_bulletin').style.display = "block";
								document.getElementById('is_file_upload_document_bulletin').value = 1;
								var nameElement = file.previewElement.querySelector("[data-dz-name]");
								nameElement.title = file.name;
						});
						this.on("uploadprogress", function(file, progress, bytesSent) {
							if (file.previewElement) {
								var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
								progressElement.style.width = progress + "%";
								progressElement.querySelector(".progress-text").textContent = progress + "%";
							}
						});

						this.on("success", function(file, progress, bytesSent) {
							isSent = true;
						});

					this.on("removedfile", function(file) {
						// var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
						var count_file_upload = document.getElementsByClassName("id_file_bulletin").length;
						if (count_file_upload == 1) {
								document.getElementById('is_file_upload_document_bulletin').value = 0;
						}
					});

					this.on("complete", function(file) {
						if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
							var bulletin_category = document.getElementById('bulletin_category_selected').value;
							var bulletin_slug = document.getElementById('bulletin_slug').value;
							var page_back = document.getElementById("page_back").value;
							if (page_back == "bulletin_subject") {
								window.location.href = '/show_bulletin_subject?bulletin_category_id=' + bulletin_category + "&bulletin_id=" + bulletin_slug;
							}
							else if (page_back == "show_bulletin") {
								window.location.href = '/bulletins/' + bulletin_slug + '?bulletin_category_id=' + bulletin_category ;
							}
							else {
								window.location.href = '/bulletins?bulletin_category_id=' + bulletin_category;
							}
						}
					});
				}
			});
		}
	});

	// validate before click submit form bulletin
		$("#btnSaveBulletin").click(function() {
			document.getElementById("bulletin_is_draft").value = "false";
			validate_before_submit();
			// document.getElementById("btn_save_bulletin").click();
		});

	// handle confirm when no title
		$("body").delegate('#btnConfirmSave', 'click', function() {
			window.$("#modal_warning_bulletin").modal("hide");
			document.getElementById("btn_save_bulletin").click();
		});

	$(".delete_file_bulletin").click(function() {
			var id_text_delete = this.id;
			var checkbox_delete = id_text_delete.replace('file_bulletin', 'is_delete_document');
			var content_file = id_text_delete.replace('file_bulletin', 'content_bulletin_document');
			if (document.getElementById(checkbox_delete).checked) {
				document.getElementById(checkbox_delete).checked = false;
				document.getElementById(content_file).classList.remove('checked_delete_bulletin')
				document.getElementById(content_file).classList.add('border');
			} else {
				document.getElementById(checkbox_delete).checked = true;
				document.getElementById(content_file).classList.add('checked_delete_bulletin')
				document.getElementById(content_file).classList.remove('border');
			}
	});

	$("body").delegate('#btnCancelReply', 'click', function() {
		document.getElementById("bulletin_comment_reply_comment_id").value = "";
		$('.bulletin_reply_comment').parent().remove();
		initElement();
		$("#bulletin_comment_content").val('<p><br></p>');
	});

	

	// handle click row in table bulletin show offcanvas
		$("body").delegate('.tbl_bulletin tbody tr td.show_detail_bulletin', 'click', function(e) {
			window.$("#bulletin_detail_offcanvas").find(".offcanvas-content").html("");
			window.$("#bulletin_detail_offcanvas").offcanvas('show');
			var url = $(this).parent().data('url');
			if ($(this).parent().hasClass("fw-bold")) {
				$(this).parent().removeClass("fw-bold")
			}
			$.ajax({
				type: 'GET',
				url: url,
				dataType: 'script',
				data: {},
				success: function (data, textStatus, jqXHR) {
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("Error!")
				}
			});
		});

	// handle js when click checkbox create topic
		$("body").delegate('#bulletin_is_topic', 'change', function() {
			if (this.checked) {
				$("#content_topic").removeClass("d-none");
				document.getElementById("bulletin_poll_attributes_topic").required = true;
				$('#list_nested_poll_option > div.nested-fields').each((index, elem) => {
		      if (elem.style.display != "none") {
		        // list_poll_option.push(elem);
		        elem.querySelector(".field-title").required = true
		      }
		    });
			} 
			else {
				$("#content_topic").addClass("d-none");
				document.getElementById("bulletin_poll_attributes_topic").required = false;
				$('#list_nested_poll_option > div.nested-fields').each((index, elem) => {
		      if (elem.style.display != "none") {
		        // list_poll_option.push(elem);
		        elem.querySelector(".field-title").required = false;
		      }
		    });
				// $("#content_topic").html("")
			}
		});

	// handle when remove option in topic
		$("body").delegate("#list_nested_poll_option", "cocoon:after-remove", function () {
	    var list_poll_option = [];
	    $('#list_nested_poll_option > div.nested-fields').each((index, elem) => {
	      if (elem.style.display != "none") {
	        list_poll_option.push(elem);
	      }
	    });
	    if (list_poll_option.length <= 2 && document.getElementsByClassName("btn-remove-poll-option").length > 0) {
	    	$.each(list_poll_option, function(index, elem) {
  				if (elem.getElementsByClassName("btn-remove-poll-option").length > 0) elem.getElementsByClassName("btn-remove-poll-option")[0].remove();
    		});
	    }
	    
	  });
		
	// handle when click text choose radio button option in poll
		$("body").delegate('.only-option', 'click', function() {
			if (this.firstElementChild.checked == false) {
				this.firstElementChild.checked = true; // checked option radio button
				update_option_in_poll(this.firstElementChild.value);
			}
		});

	// handle when choose radio option in poll
		$("body").delegate('.radio-option-vote', 'change', function() {
			update_option_in_poll(this.value);
		});
		
		function update_option_in_poll(option_id_selected) {
			$.ajax({
				type: 'POST',
				url: "/vote_poll",
				dataType: 'script',
				// headers: {
			  //   Accept: "text/vnd.turbo-stream.html"
			  // },
				data: {
					option_id_selected: option_id_selected
				},
				success: function (data, textStatus, jqXHR) {
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("Error!")
				}
			});
		}

	// handle when click text choose checkbox option in poll
		$("body").delegate('.checkbox-option-vote', 'change', function() {
			// console.log("a");
			update_option_in_poll(this.value)
			// $.ajax({
			// 	type: 'POST',
			// 	url: "/vote_poll",
			// 	dataType: 'script',
			// 	data: {
			// 		option_id_selected: this.firstElementChild.value
			// 	},
			// 	success: function (data, textStatus, jqXHR) {
			// 	},
			// 	error: function (jqXHR, textStatus, errorThrown) {
			// 		console.log("Error!")
			// 	}
			// });
			
		});

	// handle click icon locked show detail permission bulletin category
		$("body").delegate('.btn-show-permission-bulletin-category', 'click', function (e) {
	    var bulletin_category_id = this.value;
	    if(bulletin_category_id != ""){
	      $.ajax({
	        type: 'GET',
	        url: "/show_permission_bulletin_category",
	        dataType: 'script',
	        data: {
	          bulletin_category_id: bulletin_category_id
	        },
	        success: function (data, textStatus, jqXHR) {
	        },
	        error: function (jqXHR, textStatus, errorThrown) {
	          console.log("AJAX Error: #{textStatus}")
	        }
	      });  
	    }  
	  });

	// handle option with datepicker
		// $("body").delegate('.icon-calendar-poll-option', 'click', function() {
		// 	var element_field = $(this).parent().find(".field-title");
		// 	var text_element = element_field[0].value
		// 	element_field.datepicker({
		// 		language: get_locate(),      
		// 		sideBySide: true,
		// 		// todayHighlight: true,
		// 		"autoclose": true,
		// 	}).focus()
		// 	.on("change", function(e) {
		// 		console.log(a);
		// 		// console.log(e.format(0,"dd/mm/yyyy"));
		// 	})
		// 	// .on('changeDate', function(e) {
		// 	// 	console.log(e.format(0,"dd/mm/yyyy"));
		// 	// 	var element = $(this).parent().find(".field-title");
		// 	// 	var text_element = element[0].value
		// 	// 	element[0].value = text_element + " " + e.format(0,"dd/mm/yyyy");
		// 	// });
		// });

	// handle button save draft bulletin
		$("body").delegate('#btnSaveDraftBulletin', 'click', function() {
			document.getElementById("bulletin_is_draft").value = "true";
			document.getElementById("btn_save_bulletin").click();
		});

		$("body").delegate('#btnSaveCommentBulletin', 'click', function() {
			document.getElementById("btn_save_comment_bulletin").click();
		});

});
// use datepicker for poll option
window.datepicker_poll_option = function (element) {
	element.datepicker({
		language: get_locate(),      
		sideBySide: true,
		// todayHighlight: true,
		"autoclose": true		
	})
	.on('changeDate', function(e) {
		// console.log(e.format(0,"dd/mm/yyyy"));
		var element = $(this).parent().find(".field-title");
		var text_element = element[0].value
		element[0].value = text_element + " " + e.format(0,"dd/mm/yyyy");
	});
}

function isQuillEmpty(value) {
	if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
		return true;
	}
	return false;
}

window.load_quill_comment = function() {
	if ($("#editor_comment_bulletin").length > 0) {
		var form = document.querySelector('#form_comment');

		var input = { 
      id: "editor_comment_bulletin",
      callbackMentionUrl: '/filter_mention_user',
      status: false
    }
    var text_Editor = new CustomEditorText(input);

    form.onsubmit = function() {
			var postContentInput = document.querySelector("#bulletin_comment_content");
      postContentInput.value = text_Editor.value();

      var content_comment = window.$("#bulletin_comment_content").val();
			var doc = new DOMParser().parseFromString(content_comment, "text/html");
			var list_mention_user = doc.getElementsByClassName("mention");
			if (list_mention_user.length > 0) {
				var arr_mention_user = [];
				$.each(list_mention_user, function(index, elem) {
						arr_mention_user.push($(elem).data("id"));
				});
				document.getElementById("bulletin_comment_mention_user").value = arr_mention_user.toString();
			}
		};
	}
}

function validate_before_submit() {
	var bulletin_subject = $("#bulletin_subject").val();
	if (bulletin_subject == "") {
		window.$("#modal_warning_bulletin").modal("show");
	} else {
		document.getElementById("btn_save_bulletin").click();
	}
}

function toggle_disabled_bulletin(value_checkbox, id_date_field, id_hour, id_minutes) {
	if (value_checkbox) {
		document.getElementById(id_date_field).disabled = false;
		document.getElementById(id_hour).disabled = false;
		document.getElementById(id_minutes).disabled = false;
	} else {
		document.getElementById(id_date_field).disabled = true;
		document.getElementById(id_hour).disabled = true;
		document.getElementById(id_minutes).disabled = true;
	}
}

window.initElement = function () {
	let element = document.getElementsByClassName("ql-editor");
	element[0].innerHTML = "";
}
