$(document).on('turbo:load', function() {
  // event Enter submit form
  $("body").delegate('#shift_modal', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_shift").click();
    }
  });

  $("body").delegate('#setting_work_log_time', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_setting_general_shift").click();
    }
  });
});
