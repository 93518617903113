var totalPage = 0;
$(document).on('turbo:load', function() {
    window.$("#modal-split-file").on("shown.bs.modal", function () {    
        total_page();
    })
    $("body").delegate('#ckSplitAll', 'change', function () {
        setSplitAll(this.checked);
    }); 
    // $("body").delegate('#btnSplitDownload', 'click', function () {    
    //     DownloadFilesSplited();
    // });
    
})

async function total_page() {
    var formUrl = document.getElementById('url_file').value ; 
    var pdfBytes = await fetch_data(formUrl); 
    const pdfDoc = pdfjsLib.getDocument({
        data: pdfBytes,
        cMapUrl: '/cmaps/',
        cMapPacked: true
    });
    
    pdfDoc.promise.then(function (doc) {  
        totalPage = doc.numPages;
    }).catch(console.error);
}



setColorArrayPage = function(listSpan){
    var colors= [ 'gray', 'blue', 'fuchsia', 'yellow', 'green', 
                'lime', 'maroon', 'navy', 'olive', 'orange', 'purple', 'red', 
                'silver', 'teal', 'aqua'];
    var idxColor = 0;   
    var indexgroup = 0;      

    var listThumb = document.getElementById('contentSplit').querySelectorAll('.split-thumbnail');

    for (let index = 0; index < listThumb.length; index++) {
        const element = listThumb[index];
        var num = element.getAttribute('num');
        if(num > listSpan[indexgroup]){
            indexgroup++;
            idxColor ++;
        }        
        if(idxColor == colors.length-1){
            idxColor = 0;
        }
        setColorBadge(element,colors[idxColor],indexgroup);
    }    
}

getListCutted = function(){
    var listIndexCutted = []
    var listChild = document.getElementById('contentSplit').querySelectorAll('.bar-split [selected=true]');
    for (let index = 0; index < listChild.length; index++) {
        listIndexCutted.push(parseInt(listChild[index].parentElement.getAttribute('num')));        
    }
   return listIndexCutted;
}

setColorBadge = function(elem, color){
    elem.querySelector('.badge').style.background = color;
}

setSplitAll = function(checked){
    var lstButton = document.querySelectorAll('.split-button');
    for (let index = 0; index < lstButton.length; index++) {
        const element = lstButton[index];
        if (checked) {              
            element.setAttribute("selected",true);
            element.classList.add("cutting");    
        }
        else{
            element.setAttribute("selected",false);
            element.classList.remove("cutting");    
        }
    }
    var listSpan = getListCutted();
    // set color
    setColorArrayPage(listSpan);
}

// DownloadFilesSplited = function(){
//     var listSpan = getListCutted();    
//     // $.ajax({
//     //     type: 'get',
//     //     url: window.origin +  "/pdf/download_split_file",
//     //     dataType: 'script',
//     //     data: {
//     //         totalpage : totalPage,
//     //         split: listSpan,
//     //         path: decodeURI(document.querySelector('#url_file').value)
//     //     },
//     //     success: function(data, textStatus, jqXHR){
//     //     },
//     //     error:function(jqXHR, textStatus, errorThrown){
//     //         console.log("AJAX Error: #{textStatus}")
//     //     }
//     // });

//     window.location.href = window.origin + '/pdf/download_split_file?totalpage='+totalPage + '&split=' + listSpan + '&path=' + decodeURI(document.querySelector('#url_file').value);
//     hideFormLoading();
// }
