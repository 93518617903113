$(document).on('turbo:load', function() {
  $('body').delegate('.show_log_work', 'click', function (e) {
    $.ajax ({
      type: 'GET',
      url: $(this).data('url') + "/show_log_work",
      dataType: 'script',
      data: {
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

  $("body").delegate('#day_of_year_issue', 'click', function() {
      $("#day_of_year_issue").datepicker({
        showMonthAfterYear: true,
        autoclose: true,
        todayHighlight: true,
        language: get_locate(),
      }).focus()
  });

  // window.$('#modal_log_work_issue').on('shown.bs.modal', function (e) {
  //   $(".select_user_log_work").select2({
  //     dropdownParent: $("#modal_log_work_issue"),
  //   })
  // })


  $("body").delegate('#add_log_work_in_issue', 'click', function(event) {
    let timeValue =  parseFloat($("#time_issue_input").val());
    if (isNaN(timeValue) || timeValue < 0 || timeValue > 24) {
      $(".validate-hour-issue").first().removeClass("d-none")
    }
    else {
      $("#log_work_issue_present").removeClass("d-none")
      $(".validate-hour-issue").addClass("d-none")
      day_of_year = $("#day_of_year_issue")[0].getAttribute('value');
      actual_time_old = parseFloat( $('.actual_time_issue').text());
      plan_time = parseFloat($(".duration_by_hour_issue").text());
      selector = '[data-item-id="' + day_of_year + '"]';
      if ($(".user_log_work").length > 0) {
        user_log_work = $(".user_log_work").data('user-id');
      } else {
        user_log_work = $(".select_user_log_work").val();
      }
      if ($('.log-work-issue-tbl').find(selector).filter('[data-user-id="' + user_log_work + '"]').length) {
        row_log_work = $('.log-work-issue-tbl').find(selector).filter('[data-user-id="' + user_log_work + '"]').parent();
        if (row_log_work.hasClass('destroy_log_work')){
          row_log_work.removeClass("d-none destroy_log_work")
          row_log_work.find("input").addClass("time_Log_work_issue")
          new_log_work_time = parseFloat(timeValue)
        }
        else {
          log_work_time = parseFloat(row_log_work.find("input").val())
          new_log_work_time = parseFloat(log_work_time + timeValue)
        }
        if (isNaN(new_log_work_time) || new_log_work_time < 0 || new_log_work_time > 24) {
          row_log_work.find(".validate-hour-issue").removeClass("d-none")
        }
        else {
          $('.actual_time_issue').text((timeValue + actual_time_old).toFixed(2))
          $('.remaning_time_issue').text((plan_time - timeValue - actual_time_old).toFixed(2))
          $(".validate-hour-issue").addClass("d-none")
          row_log_work.find("input").val(new_log_work_time.toFixed(2))
          $("#time_issue_input").val("").focus()
        }
      }
      else{
        $('.actual_time_issue').text((timeValue + actual_time_old).toFixed(2))
        $('.remaning_time_issue').text((plan_time - timeValue - actual_time_old).toFixed(2))
        id = $("#issue_id_in_log_work").val()
        $.ajax({
          type: "GET",
          url: $(this).data('url') + '/add_log_work',
          dataType: "script",
          data: {
            employee_log_time: {
              day_of_year: day_of_year,
              user_id: user_log_work,
              log_works_attributes: {"0": {
                master_plan_id: $(this).data('master-plan-id'),
                time: timeValue,
                issue_id: id,
              }}
            }
          },
          success: function (data, textStatus, jqXHR) {
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
      }
    }
  });

  $('body').delegate('#time_issue_input', 'keydown', function (event) {
    if (event.keyCode == 13) {
      document.getElementById("add_log_work_in_issue").click();
    }
	});

  $('body').delegate('.delete_log_work_issue', 'click', function (event) {
    $(this).parent().parent().parent().addClass("d-none destroy_log_work");
    $(this).parent().parent().parent().find("input").removeClass("time_Log_work_issue");

    plan_time = parseFloat($(".duration_by_hour_issue").text())
    var total = 0;
    $('.time_Log_work_issue').each(function() {
      total += parseFloat($(this).val() ||$(this).text() ) || 0;
    });
    $('.actual_time_issue').text(total)
    $('.remaning_time_issue').text(plan_time - total)
    if (total == 0){
      $("#log_work_issue_present").addClass("d-none")
    }
	});

  $("body").delegate('.time_Log_work_issue', 'blur keydown', function(event) {
    if (event.type === 'focusout' || (event.type === 'keydown' && event.keyCode === 13)){
      var total = 0;
      var new_time = parseFloat($(this).val())
      if (isNaN(new_time) || new_time <= 0 || new_time > 24) {
        $(this).parent().find(".validate-hour-issue").removeClass("d-none")
      }
      else {
        $(this).parent().find(".validate-hour-issue").addClass("d-none")
        plan_time = parseFloat($(".duration_by_hour_issue").text())
        $('.time_Log_work_issue').each(function() {
          total += parseFloat($(this).val()) || 0;
        });
        $('.actual_time_issue').text((total).toFixed(2))
        $('.remaning_time_issue').text((plan_time - total).toFixed(2))
      }
    }
  });


  $("body").delegate('#save_log_work_issue', 'click', function() {
    let total_log_work = $('.log-work-issue-tbl').find("[data-item-id]")
    log_work = $('.log-work-issue-tbl').find("[data-log-work-id]")
    id = $("#issue_id_in_log_work").val()
    let employee_log_times = addLogWorksToEmployeeLogTimes(total_log_work, log_work, id);
    if (id == $(".issue_title").data('id')){
      sub_issue = false
    }
    else  {
      sub_issue = true
    }
    $.ajax({
      type: "GET",
      url:"/master_plans/" + $("#save_log_work_issue").data('master-plan-id') + "/issues/"+ id + '/save_log_work',
      dataType: "script",
      data: {
        employee_log_time: employee_log_times,
        actual_time: true,
        sub_issue: sub_issue
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });


  function addLogWorksToEmployeeLogTimes(total_log_work, log_work, issue_id) {
    let employee_log_times = [];

    for(let i = 0; i < total_log_work.length; i++) {
      let day_of_year =  $(total_log_work[i]).data('item-id');
      let selector = '[data-item-id="' + day_of_year + '"]';
      let time = $(total_log_work[i]).parent().find("input").val();
      let user_id = $(total_log_work[i]).data('user-id');
      let log_work_id = $(log_work[i]).data('log-work-id');
      let master_plan_id = $("#save_log_work_issue").data('master-plan-id');
      let destroy = false;

      if ($('.log-work-issue-tbl').find(selector).filter('[data-user-id="' + user_id + '"]').parent().hasClass('destroy_log_work')) {
        destroy = "1";
      }

      let log_works_attributes = [{id: log_work_id, master_plan_id: master_plan_id, time: time, _destroy: destroy, issue_id: issue_id}];
      let employee_log_time = { day_of_year: day_of_year, time_start: "09:00", time_end: "18:00", user_id: user_id, log_works_attributes: log_works_attributes };
      employee_log_times.push(employee_log_time);
    }

    return employee_log_times;
  }
});
