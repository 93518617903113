import {PDFViewerApplication } from '../libs/pdf-editor/define/PDFViewerApplication';
import {toolBarHelper} from '../libs/pdf-editor/edit/toolbar_helper';
import { stamp_helper } from '../libs/pdf-editor/common/stamp_helper';
$(document).on('turbo:load', function() { 
    var currentUrl = window.location.pathname;
   if (currentUrl.includes(`/pdf/`) && currentUrl.includes(`/edit`)) {  

    var url_file = $('#url_file').val();     
    open(url_file);   
    stamp_helper.init();
    toolBarHelper.setHiddenButton();
    // events();
    $( function() {  
        $( "#sortable" ).sortable({
            start: function( event, ui ) {
                ui.item[0].setAttribute('dragging',true)
            },
            stop: function( event, ui ) {
                ui.item[0].removeAttribute('dragging');
            },
            update: function( event, ui ) {
                save_order_button();
            }    
        });
    });          
   }        
});
function save_order_button(){
    var containnerleftButton =  document.querySelector('.top-col-left');
    var buttons = containnerleftButton.querySelectorAll(`.edit-button`);
    var arrayType = [],type;
    for (let index = 0; index < buttons.length; index++) {
        type = parseInt(buttons[index].getAttribute('type'));
        if(type){
            arrayType.push(type) ;  
        }             
    }    
    var objectDataSubmit = {types: arrayType};
    AjaxSubmit("/pdf/save_order_button",'GET',objectDataSubmit);  
  }



async function open(url){

    var viewComponent = getViewComponents();
    PDFViewerApplication.set_mode(1);
    PDFViewerApplication.set_app_type(1);
    await PDFViewerApplication.initialize(null,viewComponent);
    await PDFViewerApplication.open(url);
    
    window.addEventListener("resize", (event) => {
        toolBarHelper.setHiddenButton();
    });    

}

function getViewComponents(){
    var container = el('form-edit-pdf')
    var viewContainer = el('viewer');
    var thumnailContainer = el('page-navi');
    const simpleBar = new SimpleBar(container.querySelector('#pdf-viewer'));
    var scroll = simpleBar.getScrollElement(); 
    return {
        toolbar:{
        // firstpage:el('btnFirstPage'),
        //     endpage:el('btnEndPage'),
        //     previous: el('btnPrevPage'),				
        //     next: el('btnNextPage'),
        //     rotateleft: el('btnRotateLeft'),
        //     rotateright: el('btnRotateRight'),
        //     zoomIn: el('btnZoomIn'),
        //     zoomOut: el('btnZoomOut'),
        //     current_page: el('current_page_value'),
        //     total_page: el('total_page_value'),
        //     select_scale: el('scaleSelect')


            firstpage: container.querySelector('#btnfirstpage'),
            endpage: container.querySelector('#btnendpage'),
            previous: container.querySelector('#btnPrevPdfInDocument'),				
            next: container.querySelector('#btnNextPdfInDocument'),
            rotateleft: container.querySelector('#btnRotateLeftInDocument'),
            rotateright: container.querySelector('#btnRotateRightInDocument'),
            zoomIn: container.querySelector('#btnZoomInDocument'),
            zoomOut: container.querySelector('#btnZoomOutDocument'),
            current_page: container.querySelector('#current_page_value'),
            total_page: container.querySelector('#total_page_value'),
            select_scale: container.querySelector('#scaleSelect'),          
        },
        edittoolbar:{
            select_text: el('btnSelectPoint'),
            select_annotation : el('btnSelectannotation'),
            hand : el('btnHand'),
            add_text: el('btnAddText'),
            highlight: el('btnHighLightText'),
            strikeout: el('btnStrikeOutText'),
            underline: el('btnUnderLineText'),
            draw_line: el('draw_line'),
            draw_arrow: el('btnDrawArrow'),
            draw_ellipse: el('draw_ellipse'),
            draw_rectangle: el('draw_rectangle'),
            mark_x: el('btnMarkX'),
            mark_v: el('btnMarkV'),
            mark_o: el('btnMarkO'),
            form_textbox: el('btnFormTextBox'),
            form_textarea: el('btnFormTextArea'),
            form_radio: el('btnFormRadio'),
            form_checkbox: el('btnFormCheckBox'),
            form_dropdown: el('btnFormDropdown'),
            add_image: el('btnAddImages'),          
            pen: el('btnPen'),
            free_draw: el('btnfree_draw'),
            erase: el('btnErase'),
            link: el('btnAddLink'),
            customize_tool: el('btn-customize-button'),
            close_tool: el('close-tool-setting'),
            undo: el('btnUndoEdit'),
            redo: el('btnRedoEdit'),
            reload: el('btnReload'),
            download: el('btnDownload'),
            // save : el('btnSave'),
            screenshot : el('btnScreenShot')
            
        },
        toolbarSecond:{            
            add_text: el('btnAddText2'),
            highlight: el('btnHighLightText2'),
            strikeout: el('btnStrikeOutText2'),
            underline: el('btnUnderLineText2'),
            draw_line: el('draw_line2'),
            draw_arrow: el('btnDrawArrow2'),
            draw_ellipse: el('draw_ellipse2'),
            draw_rectangle: el('draw_rectangle2'),
            mark_x: el('btnMarkX2'),
            mark_v: el('btnMarkV2'),
            mark_o: el('btnMarkO2'),
            form_textbox: el('btnFormTextBox2'),
            form_textarea: el('btnFormTextArea2'),
            form_radio: el('btnFormRadio2'),
            form_checkbox: el('btnFormCheckBox2'),
            form_dropdown: el('btnFormDropdown2'),
            add_image: el('btnAddImages2'),          
            pen: el('btnPen2'),
            free_draw: el('btnfree_draw2'),
            erase: el('btnErase2'),
            link: el('btnAddLink2'),                   
            screenshot : el('btnScreenShot2')
            
        },
        propertiesToolbar:{
            // increaseSize:  el('size-increase'),
            // decreaseSize:  el('size-decrease'),
            // boldtext:  el('text-bold'),
            // italictext:  el('text-italic'),
            // increaseSize:  el('size-increase'),
            // increaseSize:  el('size-increase'),
            // fontTextChange : el('fontTextChange'),
            // textColor : el('text-color'),
            commonDelete : '.objectdelete',
            commonClone : '.objectClone',
            c_stamp_personal :   '.c_stamp_personal',
            c_stamp_manager :   '.c_stamp_manager',
            c_stamp_company :   '.c_stamp_company',
            c_stamp__autocad: '.img_autocad',
            canvas_signal :   '.canvas_signal',        
            // image_item: '.image_item',
            toolButton: '.tool-item button',
            thick_item: '.thick_item',
            dash_item: '.dash_item',
            color_item: '.color_item',
            color_pick: '.input_color',
            opacity_item: '.opacity_item',
            width_item: '.width_item',
            height_item: '.height_item',
            font_family: '.font-family',
            font_size: '.font-size',
            bold_text: '.bold-text',
            italic_text: '.italic-text',
            arrow_item: '.arrow_item',
            slider_item: '.slider_item',
            text_slider: '.text-slider',
            check_box_item: '.check_box_item',
            date_format: '.date_format',
            date_out_put: '.date_out_put'

        },
        viewContainer:viewContainer,
        scrollContent: scroll,
        pageScroll: container.querySelector('#pdf-viewer') ,
        thumnailContainer:thumnailContainer
    }
}

