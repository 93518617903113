import { url_helper } from "./supports/url_helper";

$(document).on('turbo:load', function() {

  // Initialize Dropzone
  Dropzone.autoDiscover = false;
  $('.dropzone').each(function () {
    let dropzoneControl = $(this)[0].dropzone;
    if (dropzoneControl) {
        dropzoneControl.destroy();
    }
  });

  // Pre init code
  $(function() {
    //Dropzone class
    var dropzone = $('#fileDropzone');
    var file_title = [];
    var file_version = [];
    var file_note = [];
    if(dropzone.length > 0) {
      $('#fileDropzone').dropzone({
        url: "upload_file",
        paramName: "file",
        maxFilesize: 16,
        // addRemoveLinks: true,
        previewTemplate: document.querySelector('#uploadFilePreviewTemplate').innerHTML,
        previewsContainer: "#dropzone-previews",
        maxFiles: 100,
        acceptedFiles: getListAcceptFileType("default"),
        autoDiscover: false,
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 100,
        timeout: 6000000,
        clickable: true,
        accept: function(file, done) {
          defineIcon(file);
          done();
        },
        init: function() {
          setMessageErrorFile(this.options);
          var btn_upload = document.querySelector("#btnUploadFile");
          // First change the button to actually tell Dropzone to process the queue.
          var myDropzone = this;

          btn_upload.addEventListener("click", function (e) {
          // Make sure that the form isn’t actually being sent.
          // $(".btn-remove-file").addClass("d-none");
          // $(".progress").removeClass("d-none")
          var file_block = document.getElementsByClassName("file-row");
          file_block.forEach(function(file) {
            file_title.push(file.getElementsByClassName("file-title")[0].value);
            file_version.push(file.getElementsByClassName("file-version")[0].value);
            file_note.push(file.getElementsByClassName("file-note")[0].value);
          });
          e.preventDefault();
          e.stopPropagation();
          myDropzone.processQueue();
          });
          this.on("sending", function(file, xhr, formData){
            formData.append("file_title",file_title);
            formData.append("file_version",file_version);
            formData.append("file_note",file_note);
          });
          this.on("error", function(file, message, xhr) { 
            messageErrorFile(file, message);
            defineIcon(file);
         });
          this.on("addedfile", function(file) {
            document.getElementById('borrow_file_id').style.display="block";            
          });
            
          this.on("success", function(file, responseText) {   
            var id = parseInt(url_helper.get_value_params_from_URL('folder_id'));
            window.location.href = window.origin + `/folders/index/${id}`
            var removeButton = file.previewElement.querySelector('[data-dz-remove]');
            removeButton.addEventListener("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            })
          });

          this.on("removedfile", function(file) {
          });

          this.on("uploadprogress", function(file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = parseInt(progress) + "%";
            }
          });
        }
      });
    }
  });

  
  $("body").delegate('#file_information_path', 'change', function() {
    var convert_filesize, maxfilesize = (1024 * 1024)*30,  // 1 Mb
    warningel   = document.getElementById('lb_error');
    var filesize = (this.files.length == 0) ? 0 : this.files[0].size;
    if ((filesize/1024/1024) == 0) {
      convert_filesize = parseFloat(filesize/1024).toFixed(2) + "kB";
    }
    else {
      convert_filesize = parseFloat(filesize/1024/1024).toFixed(2) + "MB";
    }
    // else {
    //   convert_filesize = parseFloat(filesize/1024/1024/1024).toFixed(2) + "GB";
    // }
    if ( filesize > maxfilesize ) {
      warningel.innerHTML = "File too large: " + convert_filesize + ". Maximum size: 30MB";
      $("#btnUpdateFileInformation").addClass("disabled");
      return false;
    }
    else {
      warningel.innerHTML = '';
      $("#btnUpdateFileInformation").removeClass("disabled");
      return true;
    }   
  });

  $("body").delegate('.tblFileHistories tbody tr', 'mouseover', function() {
    $(this).find(".more-action-table").removeClass('hide');
		
	})
  $("body").delegate('.tblFileHistories tbody tr', 'mouseout', function() {
    $(this).find(".more-action-table").addClass('hide');
  })

});