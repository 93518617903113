// class create Container contain content of file
import { PDF } from '../../../libs/pdf-editor/define/PDF';
import { url_helper } from '../../../packs/supports/url_helper';
import { definePropertiesPage } from '../common/definePropertiesPage';
import { load_element_annotation_thumbnail } from '../common/load_annotation_thumbnail';

export class PDFSplit {
  file
  pdf

  constructor(url) {
    this.url = url;
    this.file = new PDF();
  }

  async open() {    
    this.file.url = this.url;

    if (el('slug')) {
      this.file.id = el('slug').value;
    }
    var result = await setDataByURL(this);
    if (!result) return;

    load(this);
    this.file.loading = false;
  }
}

function load(splitPDF) {
  var loadingTask = pdfjsLib.getDocument({
    data: splitPDF.file.bytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });

  loadingTask.promise.then(function (pdf) {
    splitPDF.pdf = pdf;
    createComponents(splitPDF);
  })
}

async function setDataByURL(splitPDF) {
  try {
    var bytes = await getPdfBytes(splitPDF.file.url);
    splitPDF.file.bytes = bytes;
    return true;
  }
  catch (error) {
    console.log(error);
    alert(error.message);
    return false;
  }
}

async function getPdfBytes(url) {
  
    var bytes =  await fetch_data(url);
   return bytes;
  
}

async function getPages(pdf) {
  const pageNumbers = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
  const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));
  return await Promise.all(promises);
}

async function createComponents(splitPDF) {
  var init_page = get_page_number_init();

  if (splitPDF.app_type == 0) {
    init_page = 1;
  }
  splitPDF.file.pages = await getPages(splitPDF.pdf);
  definePropertiesPage(splitPDF.file.pages);

  splitPDF.file.numPages = splitPDF.pdf.numPages;
  loadSplit(splitPDF);
}

function get_page_number_init() {
  var init_page = 1;
  var page = url_helper.get_value_params_from_URL('page');
  if (page) {
    init_page = parseInt(page);
  }
  return init_page;
}

async function loadSplit(splitPDF) {
  var page, div_page, canvas;
  var array_fabric_canvas = []
  var i;
  var container = document.getElementById("contentSplit");
  container.replaceChildren();

  for (i = 0; i < splitPDF.file.pages.length; i++) {
    page = splitPDF.file.pages[i];
    div_page = create_split_pdf(page);
    container.appendChild(div_page);
    canvas = renderSplit(page, splitPDF, div_page);
    if (i < splitPDF.file.pages.length - 1) {
      container.appendChild(createBarSplit(i));
    }
    array_fabric_canvas.push(canvas);
  }

  return array_fabric_canvas;
}

function create_split_pdf(page) {
  var dv = document.createElement("div");
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;

  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }

  dv.setAttribute("id", "split-thumbnail-" + (page._pageIndex));
  dv.setAttribute('num', page._pageIndex);
  dv.className = "split-thumbnail d-flex btn-fit-content m-1 p-0";
  dv.style.width = viewport.width * scale_thum + "px";
  dv.style.height = viewport.height * scale_thum + "px";

  return dv;
}
function renderSplit(page, splitPDF, div_page) {
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;

  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }
  viewport = page.getViewport({ scale: scale_thum, rotation: rotate });
  div_page.replaceChildren();
  div_page.setAttribute("scale", scale_thum);

  var canvas = create_canvas_page(viewport, splitPDF, div_page, page, scale_thum);
  var span = document.createElement("span");
  span.className = "thumbnailNumber badge px-0";
  span.textContent = (page._pageIndex + 1);
  span.style.background = 'gray';
  div_page.appendChild(span);

  return canvas;
}

function create_canvas_page(viewport, splitPDF, div_page, page, scale_thum) {
  var width = parseFloat(viewport.width);
  var height = parseFloat(viewport.height);
  var canvas = document.createElement("canvas");
  canvas.style.width = width + "px";
  canvas.style.height = height + "px";

  var divcanvasWrapper = document.createElement("div");
  divcanvasWrapper.id = "split-canvas" + page._pageIndex;
  divcanvasWrapper.className = "split-canvas canvasWrapper";
  divcanvasWrapper.setAttribute("selected", false);
  divcanvasWrapper.setAttribute("num", page._pageIndex);
  divcanvasWrapper.addEventListener('contextmenu', event => event.preventDefault());
  divcanvasWrapper.style.width = width + "px";
  divcanvasWrapper.style.height = height + "px";

  divcanvasWrapper.appendChild(canvas);
  div_page.appendChild(divcanvasWrapper);
  canvas.height = height;
  canvas.width = width;

  var canvasannotation = createElementCanvasannotation(canvas);
  div_page.appendChild(canvasannotation);

  var annotationLayer = document.createElement("div");
  annotationLayer.setAttribute("class", "annotationLayer");
  div_page.appendChild(annotationLayer);
  const renderContext = {
    annotationMode: 2,
    background: 'rgba(0,0,0,0)',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  var renderTask = page.render(renderContext);
  var fabricCanvas = init_split_pdf(canvasannotation, splitPDF, page._pageIndex, div_page, scale_thum);
  renderTask.promise.then(function () {
    page.getTextContent().then(function (textContent) {
      const eventBus = new pdfjsViewer.EventBus();
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      page.getAnnotations().then(function (annotationsData) {
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService,
          textLayerMode: 2
        });
      })
    });
  });
  return fabricCanvas;
}

function init_split_pdf(input_canvas, splitPDF, page_index, div_page, scale) {
  var canvas = new fabric.Canvas(input_canvas, {
    preserveObjectStacking: true
  });

  load_element_annotation_thumbnail(canvas, splitPDF, page_index, div_page, scale);
  canvas.forEachObject(object => {
    object.selectable = false;
    object.evented = false;
  });
  return canvas;
}

function createElementCanvasannotation(canvas) {
  var canvasannotation = canvas.cloneNode();
  canvasannotation.width = canvas.width;
  canvasannotation.height = canvas.height;
  return canvasannotation;
}

function createBarSplit(numPage) {
  var dv = document.createElement("div");
  dv.id = "bar-split" + (numPage);
  dv.className = "bar-split d-flex flex-column btn-fit-content m-0";
  dv.setAttribute('num', numPage)
  // button split
  var splitButton = document.createElement("button");
  splitButton.className = "split-button"
  // span top button
  var span = document.createElement("span");
  span.className = "split-span-button-top"
  splitButton.appendChild(span);

  // span vertical center button
  span = document.createElement("span");
  span.className = "split-span-button-center"

  // image svg split
  var i = document.createElement("i");
  i.className = "mdi mdi-scissors-cutting";
  span.appendChild(i);

  splitButton.appendChild(span);
  // span bottom button
  span = document.createElement("span");
  span.className = "split-span-button-bottom"
  splitButton.appendChild(span);

  splitButton.addEventListener('click', splitButton_Click);
  dv.appendChild(splitButton);
  return dv;
}

function splitButton_Click() {
  var selected = this.getAttribute("selected");
  if (selected != "true") {
    this.setAttribute("selected", true);
    this.classList.add("cutting");
  }
  else {
    this.setAttribute("selected", false);
    this.classList.remove("cutting");
  }
  // get list cutted index
  var listSpan = getListCutted();
  // set color
  setColorArrayPage(listSpan);
}


function getListCutted () {
  var listIndexCutted = []
  var listChild = document.getElementById('contentSplit').querySelectorAll('.bar-split [selected=true]');
  for (let index = 0; index < listChild.length; index++) {
    listIndexCutted.push(parseInt(listChild[index].parentElement.getAttribute('num')));
  }
  return listIndexCutted;
}

function setColorArrayPage (listSpan) {
  var colors = ['gray', 'blue', 'fuchsia', 'yellow', 'green',
    'lime', 'maroon', 'navy', 'olive', 'orange', 'purple', 'red',
    'silver', 'teal', 'aqua'];
  var idxColor = 0;
  var indexgroup = 0;

  var listThumb = document.getElementById('contentSplit').querySelectorAll('.split-thumbnail');

  for (let index = 0; index < listThumb.length; index++) {
    const element = listThumb[index];
    var num = element.getAttribute('num');
    if (num > listSpan[indexgroup]) {
      indexgroup++;
      idxColor++;
    }
    if (idxColor == colors.length - 1) {
      idxColor = 0;
    }
    setColorBadge(element, colors[idxColor], indexgroup);
  }
}

function setColorBadge (elem, color) {
  elem.querySelector('.badge').style.background = color;
}
