export const ultil_mdethods = {
    // p: PDFDocumentPage    
    calculate_point_after_rotate(page,obj,rotate){        
        var out_x = obj.left,out_y = obj.top;
        switch(rotate){
          case 0:
          case 360:
            out_x =  obj.left;
            out_y =  obj.top;
            break;
          case -90:
          case 270:
            out_x =  obj.top;
            out_y =  page.height - obj.left;
            break;
          case 180:
          case -180:
            out_x =  page.width - obj.left;
            out_y =  page.height - obj.top;
            break;
          case -270:
          case 90:
            out_x =  page.width -  obj.top;
            out_y =  obj.left;
            break;
        }
      
        return {x: out_x,y: out_y};
    },
    rotate_object(page,obj,rotate,scale){        
      var out_x = obj.left,out_y = obj.top;
      switch(rotate){
        case 0:
        case 360:
          out_x =  obj.left*scale;
          out_y =  obj.top*scale;
          break;
        case -90:
        case 270:
          out_x =  obj.top* scale;
          out_y =  page.height - obj.left* scale - obj.width * scale -  obj.strokeWidth*scale;
          break;
        case 180:
        case -180:
          out_x =  page.width - obj.left* scale - obj.width * scale -  obj.strokeWidth*scale;
          out_y =  page.height - obj.top* scale -  obj.height*scale -  obj.strokeWidth*scale;
          break;
        case -270:
        case 90:
          out_x =  page.width -  obj.top*scale  -  obj.height*scale - obj.strokeWidth*scale;
          out_y =  obj.left*scale ;
          break;
      }
    
      return {x: out_x,y: out_y};
  },
    async getImageFromSvgNode(svg){
        var svg64 = btoa(svg);
        var b64Start = 'data:image/svg+xml;base64,';
        var image64 = b64Start + svg64;
        var img = new Image();
        var canvas = document.createElement("canvas");
        // var bbox = svg.getBBox();
        canvas.width = 100;
        canvas.height = 100;
        // set it as the source of the img element
        img.onload = function() {
          // draw the image onto the canvas
          canvas.getContext('2d').drawImage(img, 0, 0);
        }
        img.src = image64;
        await img.decode();

        return img;
    },
    get_scaleXY_image(image){      
      var width_fix = 100;
      if(image.className.includes('image_item')){
        width_fix = 100;
      }
      if(image.className.includes('action-repeatable')){
        width_fix = 20;
      }
      return parseFloat(width_fix/image.width).toFixed(2);
    },
    get_stroke_by_scale(config_dash,config_strokeWidth,scale){     
      var strokeWidth = parseFloat( config_strokeWidth)*parseFloat( scale);
      var strokeDashArray = ultil_mdethods.convertArray(config_dash,strokeWidth);
      return {strokeWidth: strokeWidth, strokeDashArray: strokeDashArray}
    },
    convertArray(arr,divide){
      var output = []
      if(arr){ 
        for (let index = 0; index < arr.length; index++) {
          output.push(parseFloat(arr[index])*parseFloat( divide));      
        }
      }
      return output;
    }
}
 
  
  
  