import { CustomEditorText } from "../../libs/quill/custome_editor_text";

window.initialQuill = function (elemnt, input, shortsubmit = false, submitElment) {
  if ($("#" + elemnt).length > 0) {
    let master_plan_id = $("#" + input).data("master-plan-id");
    var quill_init = {
      id: elemnt,
      callbackMentionUrl: "/project_manager/filter_member/" + master_plan_id,
      status: false,
      submit: function(){
        buttonSend.click();
      }
    };

    if (shortsubmit) {
      quill_init.submit = function(){
        submitElment.click();
      };
    }

    var text_Editor = new CustomEditorText(quill_init);
    var form_id = $("#" + elemnt).parents("form").attr("id"),
    form = document.querySelector('#' + form_id);
    form.onsubmit = function() {
			var postContentInput = document.querySelector('#' + input);
			postContentInput.value = text_Editor.value();
		};
  }
};
