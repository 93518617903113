$(document).on('turbo:load', function() {
	$('body').delegate('#input_comment_issue_box .ql-toolbar.ql-snow .ql-formats button.ql-file', 'click', function (e) {
    $("#js_add_more_attachment").click()
	});

	$('body').delegate('#input_note_issue_box .ql-toolbar.ql-snow .ql-formats button.ql-file', 'click', function (e) {
    $("#js_add_more_attachment_note").click()
	});

  $('body').delegate('.js_add_attachment', 'click', function (e) {
    $(this).parent().find(".js_remove_attachment").click()
	});

	$('body').delegate('#select_file_for_comment_box', 'cocoon:after-insert', function (e) {
    $(this).find("div.nested-fields:last input:first").click()
		$(this).find("div.nested-fields:last").addClass("d-none");
	});

	$('body').delegate('#select_file_for_comment_box div.nested-fields .js_input_attachment', 'cancel', function() {
		if (this.files.length < 1) {
			$(this).parent().find(".js_remove_attachment").click()
		}
	})

  $('body').delegate('#select_file_for_comment_box div.nested-fields .js_input_attachment', 'change', function() {
		var $input = $(this);
		if (this.files && this.files.length) {
			$(this).parent().removeClass("d-none")
			let reader = new FileReader();
			this.enabled = false
			reader.onload = (function (e) {
				const ImgType = new RegExp('^image\/*');
				let type_file = get_attchment_type(e)
				let template = []
				if(ImgType.test(type_file)) {
					template.push('<img src="'+e.target.result+'" />')
					$input.prev(".js_add_attachment").html(['<img src="'+e.target.result+'" />'].join(''))
				}	else {
					switch (type_file) {
						case 'application/pdf':
							template.push('<img src="/images/file/pdf.png" />')
							break;
						case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
						case 'application/msword':
							template.push('<img src="/images/file/word.png" />')
							break;
						case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
						case 'application/vnd.ms-excel':
							template.push('<img src="/images/file/excel.png" />')
							break;
						case 'applications/ppt':
						case 'applications/pptx':
							template.push('<img src="/images/file/power_point.png" />')
							break;
						case 'application/zip':
						case 'application/rar':
						case 'application/x-7z-compressed':
								template.push('<img src="/images/file/zip.png" />')
								break;
						default:
							template.push('<img src="/images/file/file.png" />')
							break;
					}
				}

				template.push('<span class="custom-btn-destroy-attachment destroy_attachment"><i class="mdi mdi-delete action-icon"></i></span>')
				$input.prev(".js_add_attachment").html(template.join(''))
			});
			$(this).parent().find(".js_input_attachment_type").val(this.files[0].type)
			$(this).parent().find(".js_label_attachment").html(`<label>${this.files[0].name}</label>`)
			reader.readAsDataURL(this.files[0]);
		}
	});

	$('body').delegate("#issue_note", "dblclick", function() {
		let master_plan_id = $(this).data("master-plan-id");
		let issue_id = $(this).data("issue-id");

		$.ajax({
			type: "GET",
			url: "/project_manager/master_plans/"+ master_plan_id + "/issues/"+ issue_id +"/note/edit",
			dataType: "script",
		})
	})

})

function get_attchment_type(ob) {
	let text_type = ob.target.result.split(";base64")[0];
	return text_type.substr(5);
}
