import { url_helper } from "../../../packs/supports/url_helper";
export function set_events(canvas,eventBus){     

    canvas.on('after:render',function(event){    
      eventBus.dispatch("canvas_after_render", {
          canvas: this  , 
          ev :    event
      });       
     });
    var lastTouch = {
        time: 0,
        x: 0,
        y: 0
        };
    var doubleClickThreshold = 300;
    canvas.on('mouse:down',function(event){    
        const pointer = canvas.getPointer(event.e);
        const now = Date.now();
        if (now - lastTouch.time <= doubleClickThreshold &&
            Math.abs(pointer.x - lastTouch.x) < 10 &&
            Math.abs(pointer.y - lastTouch.y) < 10) {
            // Đây là double click
            eventBus.dispatch("mousedblclick", {
                canvas: this,
                ev: event
            });
        }
    
        // Cập nhật thông tin lần chạm cuối
        lastTouch.time = now;
        lastTouch.x = pointer.x;
        lastTouch.y = pointer.y;
    
        // debugger
        eventBus.dispatch("fabric_mouse_down", {
            canvas: this  , 
            ev :    event
        });    
   });
   canvas.on('object:modified',function(event){    
    eventBus.dispatch("object_modified", {
        canvas: this  , 
        ev :    event
    });        
   });
   canvas.on('object:added',function(event){   
    eventBus.dispatch("object_added", {
      canvas: this  , 
      ev :    event
  }); 
   });
   canvas.on('mouse:dblclick',function(event){   
    eventBus.dispatch("mousedblclick", {
      canvas: this  , 
      ev :    event
  }); 
   });
   canvas.on('object:removed',function(event){   
    eventBus.dispatch("object_removed", {
      canvas: this  , 
      ev :    event
  }); 
   });
   canvas.on('path:created',function(ob){    
    eventBus.dispatch("path_created", {
        canvas: this  , 
        ob :    ob
    });    
    });
    canvas.on('object:moving',function(event){    
    eventBus.dispatch("object_moving", {
        canvas: this  , 
        ev :    event
    });    
    });
    canvas.on('before:render',function(event){    
        eventBus.dispatch("before_render", {
            canvas: this  , 
            ev :    event
        });    
    });
    canvas.on('mouse:move',function(event){    
      eventBus.dispatch("mouse_move", {
          canvas: this  , 
          ev :    event
      });    
    });

    canvas.on('mouse:down:before',function(event){    
        eventBus.dispatch("mouse_down_before", {
            canvas: this  , 
            ev :    event
        });    
    });
    canvas.on('mouse:up', function(event){
        eventBus.dispatch("mouse_up", {
            canvas: this  , 
            ev :    event
        });  
    });
    canvas.on('object:selected',function(event){    
    eventBus.dispatch("object_selected", {
        canvas: this  , 
        ev :    event
    });    
    });
    canvas.on('selection:cleared',function(event){    
        eventBus.dispatch("selection_cleared", {
            canvas: this  , 
            ev :    event
        });    
        url_helper.add_params_to_url('selected',null);
    });
    canvas.on({
      'selection:updated': HandleElement,
      'selection:created': HandleElement
    });


   return canvas;
}

function HandleElement(obj){
    var id = null;
    if(obj && obj.selected.length>0){
        id = obj.selected[0].id;
        
    }
    url_helper.add_params_to_url('selected',id);    
}