import {setLeft} from './pdf/decompose_pdf.js';
$(document).on('turbo:load', function () {
	formatDataTable();
})

window.formatDataTable = function inItDatatable(id=null){	
	$('.dataTables_length').parent().parent().remove();
	$('.dataTables_paginate').parent().remove();
	$('.dataTables_info').parent().remove();
	formatTableMergePdf(id);
	formatTableDepartment(id);
	formatTablePosition(id);
	formatTableFoldeSearch();
}

function setSelectedRows(table,id){
	if(id && table){
		table.rows().every (function (rowIdx, tableLoop, rowLoop) {
			// console.log(parseInt(this.data()[0]));
			if (parseInt(this.data()[0]) == parseInt(id)) {
				this.select ();
				this.show().draw(false); 
				return;
			}
		});
	}
}

function formatTableMergePdf(id){
	var tbl_select_complete = document.getElementsByClassName("tbl_select_complete");
	if (tbl_select_complete.length > 0) {
			tbl_select_complete = window.$('.tbl_select_complete').DataTable({
					'stateSave': false,
					'destroy': true,
					'fixedColumns': true,

					'columnDefs': [
					{
							'targets': 0,
							'width': "4%",
							'checkboxes': {
							'selectRow': true
							}
					}],
					'select': {
					'style': 'multi'
					},
					'order': [1, 'asc'],
					drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
					},
					language:{paginate:{
					previous:"<i class='mdi mdi-chevron-left'>",
					next:"<i class='mdi mdi-chevron-right'>"}},
			});
			$("body").delegate('#btnPushPDF', 'click', function () {
					var id_pdf,filename;
					var lstPdf = [];
					var option;
					var select = document.getElementById("lstPdfMerge");
					$.each(tbl_select_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
							var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
							if (checkBox) 
							{
									id_pdf =row.querySelector('#col_checkbox').getAttribute('data-item');
									filename =row.querySelector('#col_file_name').textContent;
									option = document.createElement("option");
									option.text = filename;
									option.value = id_pdf;				
									select.appendChild(option);                
							}   
					});		
					$(".btn_close_modal").click();
			});
			$("body").delegate('#btnSetPdf', 'click', function () {

					var lstPath = [],path_pdf;
					$.each(tbl_select_complete.rows({ 'search': 'applied' }).nodes(), function (i, row) {
						var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
						if (checkBox) 
						{
							path_pdf =row.querySelector('#col_checkbox').getAttribute('data-path');			 
							lstPath.push(path_pdf);      
						}   
					});		
			
					if(lstPath.length < 1) return;
			
					$(".btn_close_modal").click();
					setLeft(lstPath);
					
				});
	};	
}

function formatTableDepartment(id){
	$("#btn_edit_department").click(function() {
			var mydata = [],id;

			$.each($('.tbl_depart tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.getAttribute('data-item');
							mydata.push(id);
							return;                
					}   
			});

			if(mydata.length!=1) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "/departments/" + mydata[0] + "/edit",
					dataType: 'script',
					data: {
									id: mydata[0]
					},
					success: function(data, textStatus, jqXHR){
					},
					error:function(jqXHR, textStatus, errorThrown){
									console.log("AJAX Error: #{textStatus}")
					}
			})
	});

	$("#btn_delete_department").click(function() {
			var mydata = [],id;

			$.each($('.tbl_depart tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.getAttribute('data-item');
							mydata.push(id);
										 
					}   
			});

			if(mydata.length==0) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "delete_department",
					dataType: 'script',
					data: {
									data: {ids:mydata},
					},
					success: function(data, textStatus, jqXHR){
							console.log("AJAX OK!")
					},
					error:function(jqXHR, textStatus, errorThrown){
							console.log("AJAX Error: #{textStatus}")
					}
			})
	});

	$('a[href="#profile"]').on('click', function(event){
		formatTablePosition();
	});

}    

function formatTablePosition(id){
	$("#btnDeletePosition").click(function() {
			var mydata = [],id;

			$.each($('.tbl_position tbody tr'), function (i, row) {
					var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
					if (checkBox) 
					{
							id =row.getAttribute('data-item');
							mydata.push(id);
										 
					}   
			});

			if(mydata.length==0) 
			{return;}
			$.ajax
			({
					type: 'GET',
					url: "delete_positions",
					dataType: 'script',
					data: {
									data: {ids:mydata},
					},
					success: function(data, textStatus, jqXHR){
							console.log("AJAX OK!")
					},
					error:function(jqXHR, textStatus, errorThrown){
							console.log("AJAX Error: #{textStatus}")
					}
			})
	});
}

function formatTableFoldeSearch(){
	// var tbl_file_folder = document.getElementsByClassName("tbl-folder-search");
  //   if (tbl_file_folder.length > 0) {
  //     tbl_file_folder = window.$('.tbl-folder-search').DataTable({
  //       'destroy': true,
  //       'columnDefs': [
  //         {
  //           'targets': 0,
  //           'width': "4%",           
  //         },
  //         { "width": "6%", "targets": 1 }
  //       ],
  //       'select': {
  //         'style': 'multi'
  //       },
  //       'order': [0, 'asc'],
  //       drawCallback: function() {
  //         $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
  //       },
  //       language:{paginate:{
  //       previous:"<i class='mdi mdi-chevron-left'>",
  //       next:"<i class='mdi mdi-chevron-right'>"}},
  //     });
	// }
}
