$(document).on("ready turbo:load", function () {
  if ($("#gantt_here").length > 0) {

    navigation.addEventListener("navigate", (event) => {
      let url_new = new URL(event.destination.url).pathname,
          gantt_url =  /\/master_plans\/[0-9][0-9]\/gantt/,
          current_url = window.location.pathname;

      if(current_url.match(gantt_url).length && !url_new.match(gantt_url)){
        saveConfig();

      }
    });

    var gantt_data = gon.gantt_data;
    var links_gantt_data = gon.links_gantt_data;
    var stage_array = gon.stage_array;
    var priority_array = gon.priority_array;
    var member_array = gon.member_array;
    var header = JSON.parse(gon.header);
    var kind_array = gon.kind_array;
    var readonly_gantt = $("#readonly_gantt").val().toLowerCase() === 'true';
    var default_scale = [
      { unit: "month", step: 1, date: "%M, %Y" },
      {
        unit: "day",
        step: 1,
        date: "%d",
        css: function (date) {
          if (
            date.getDay() === 0 ||
            date.getDay() === 6 ||
            gon.holiday_for_gantt.includes(format_date(date))
          ) {
            return "weekend_header";
          }
          return "";
        },
      },
    ];

    gantt.plugins({
      multiselect: true,
      auto_scheduling: true,
      keyboard_navigation: true,
      tooltip: true,
      marker: true,
      undo: true,
    });

    function format_date(date) {
      let yr = date.getFullYear(),
        month_date = date.getMonth() + 1;
      (month = month_date < 10 ? "0" + month_date : month_date),
        (day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()),
        (newDate = yr + "-" + month + "-" + day);

      return newDate;
    }

    gantt.config.scale_height = 30 * 2;
    gantt.config.min_column_width = 50;
    gantt.config.scales = default_scale;

    gantt.templates.timeline_cell_class = function (task, date) {
      if (
        date.getDay() === 0 ||
        date.getDay() === 6 ||
        gon.holiday_for_gantt.includes(format_date(date))
      ) {
        return "weekend";
      }
    };

    gantt.templates.grid_folder = function (item) {
      return;
    };

    gantt.templates.grid_file = function (item) {
      return "";
    };

    gantt.templates.task_class = function (start, end, task) {
      if (task.type == gantt.config.types.project) {
        return "project-top-line";
      }
    };

    gantt.templates.rightside_text = function(start, end, task){
      if(task.type == gantt.config.types.milestone){
          return task.text;
      }
      return "";
    };

    var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    var today = new Date($("#gantt_today").val());
    gantt.addMarker({
      start_date: today,
      css: "today",
      text: "Today",
      title: "Today",
    });

    gantt.config.layout = {
      css: "gantt_container",
      cols: [
        {
          width: 500,
          min_width: 300,
          rows: [
            {
              view: "grid",
              scrollX: "gridScroll",
              scrollable: true,
              scrollY: "scrollVer",
            },
            { view: "scrollbar", id: "gridScroll", group: "horizontal" },
          ],
        },
        { resizer: true, width: 1 },
        {
          rows: [
            { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
            { view: "scrollbar", id: "scrollHor", group: "horizontal" },
          ],
        },
        { view: "scrollbar", id: "scrollVer" },
      ],
    };

    gantt.config.readonly = readonly_gantt;
    gantt.config.start_date = new Date($("#watch_start_time").val());
    gantt.config.end_date = new Date($("#watch_end_time").val());
    gantt.config.show_tasks_outside_timescale = true;
    gantt.config.reorder_grid_columns = true;
    gantt.config.keyboard_navigation_cells = true;
    gantt.config.auto_scheduling = true;
    gantt.config.auto_scheduling_strict = true;
    gantt.config.row_height = 30;
    gantt.config.fit_tasks = true;
    gantt.config.show_unscheduled = true;
    gantt.config.placeholder_task = !readonly_gantt;
    // gantt.config.auto_types = true;
    gantt.config.touch = "force";
    gantt.config.scroll_on_click = true;
    gantt.config.scroll_size = 20;
    gantt.config.open_split_tasks = true;
    gantt.config.date_format = "%Y-%m-%d"

    // gantt.config.work_time = true;
    // gantt.config.correct_work_time = true;
    // reordering tasks within the same nesting level
    gantt.config.order_branch = "marker";
    // reordering tasks within the whole gantt
    gantt.config.order_branch_free = true;
    gantt.config.sort = true;

    var formatter = gantt.ext.formatters.durationFormatter({
      enter: "day",
      store: "day",
      format: "auto",
    });

    var getInput = function (node) {
      return node.querySelector("input");
    };

    gantt.config.editor_types.progressNumber = {
      show: function (id, column, config, placeholder) {
        var min = config.min || 0,
          max = config.max || 100;
        var html =
          "<div><input type='number' min='" +
          min +
          "' max='" +
          max +
          "' name='" +
          column.name +
          "'></div>";
        placeholder.innerHTML = html;
      },
      hide: function () {
        // can be empty since we don't have anything to clean up after the editor
        // is detached
      },
      set_value: function (value, id, column, node) {
        getInput(node).value = value * 100;
      },
      get_value: function (id, column, node) {
        return getInput(node).value / 100 || 0;
      },
      is_changed: function (value, id, column, node) {
        var currentValue = this.get_value(id, column, node);
        return Number(value) !== Number(currentValue);
      },
      is_valid: function (value, id, column, node) {
        return !isNaN(parseInt(value, 10));
      },
      focus: function (node) {
        var input = getInput(node);
        if (!input) {
          return;
        }
        if (input.focus) {
          input.focus();
        }
        if (input.select) {
          input.select();
        }
      },
    };

    gantt.config.editor_types.custom_datepicker_editor = {
      show: function (id, column, config, placeholder) {
        placeholder.innerHTML = "<input type='text' id='datepickerGantt' name='" +
                                  column.name + "'>";
        $("#datepickerGantt").datepicker({
          dateFormat: "yy-mm-dd",
          language: get_locate(),
          autoclose: true,
          onSelect: function(dateStr){
            gantt.ext.inlineEditors.save()
          }
        });
      },
      hide: function (node) {
        $("#datepickerGantt").datepicker( "destroy" );
      },

      set_value: function (value, id, column, node) {
        $("#datepickerGantt").datepicker("setDate", value);
      },

      get_value: function (id, column, node) {
        return $("#datepickerGantt").datepicker( "getDate" );
      },

      is_changed: function (value, id, column, node) {
        return (+$("#datepickerGantt").datepicker( "getDate" ) !== +value);
      },
      is_valid: function (value, id, column, node) {
        return !(isNaN(+$("#datepickerGantt").datepicker( "getDate" )))
      },
      save: function (id, column, node) {
      },
      focus: function (node) {
      }
    };

    var linksFormatter = gantt.ext.formatters.linkFormatter({durationFormatter: formatter});

    var editors = {
      text: { type: "text", map_to: "text" },
      start_date: {
        type: "custom_datepicker_editor",
        map_to: "start_date",
      },
      end_date: {
        type: "custom_datepicker_editor",
        map_to: "end_date",
      },
      duration: {
        type: "number",
        map_to: "duration",
        min: 0,
        formatter: formatter,
      },
      priority: {
        type: "select",
        map_to: "priority_id",
        options: gantt.serverList("priority", priority_array),
      },
      stage: {
        type: "select",
        map_to: "stage_id",
        options: gantt.serverList("stage", stage_array),
      },
      assignee: {
        type: "select",
        map_to: "assignee_id",
        options: gantt.serverList("assignee", member_array),
      },
      actual_time_start: {
        type: "custom_datepicker_editor",
        map_to: "actual_time_start",
      },
      actual_time_end: {
        type: "custom_datepicker_editor",
        map_to: "actual_time_end",
      },
      progress: {
        type: "progressNumber",
        map_to: "progress",
        min: 0,
        max: 100,
      },
      kind: {
        type: "select",
        map_to: "kind",
        options: gantt.serverList("kind", kind_array),
      },
      complete: { type: "checkbox", map_to: "complete" },
      predecessors: {type: "predecessor", map_to: "auto", formatter: linksFormatter },
      est_time: {
        type: "number",
        map_to: "est_time",
        min: 0,
      },
    };

    function since_year(date, numberYear) {
      year = date.getFullYear() + numberYear;

      return new Date(year, date.getMonth(), date.getDate());
    }

    function ago_year(date, numberYear) {
      year = date.getFullYear() - numberYear;

      return new Date(year, date.getMonth(), date.getDate());
    }

    function renderLabel(value, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].key == value) {
          return list[i].label;
        }
      }
      return "";
    }

    function reder_info(params) {
      let template = "";
      $.each(params, function (index, key) {
        switch (key) {
          case "comment":
            template =
              template + '<i class="mdi mdi-comment-processing-outline tool_item tool-item font-18" data-action-name="comment"></i>' // COMMENT
            break;
          case "note":
            template =
              template + '<i class="mdi mdi-notebook-edit tool_item tool-item font-18 ms-1" data-action-name="note"></i>' // NOTE
            break;
          case "attachment":
            template =
              template + '<i class="mdi mdi-attachment tool_item tool-item font-18 ms-1" data-action-name="attachment"></i>' //ATTACHMENT
            break;
          default:
            template;
            break;
        }
      });
      return template;
    }

    window.allColumns = [
      {
        name: "wbs",
        label: "#",
        width: 60,
        align: "center",
        template: gantt.getWBSCode,
      },
      {
        name: "info",
        label: I18n.t("activehash.property.issue.info"),
        width: 70,
        align: "center",
        template: function (task) {
          return reder_info(task.info);
        },
      },
      {
        name: "text",
        label: I18n.t("activehash.property.issue.title"),
        tree: true,
        width: 200,
        editor: editors.text,
        template: function (task) {
          color = $.trim(task.color) == "" ? "transparent" : task.color
          let id_text = task.is_new == true ? '<i class="mdi mdi-plus me-1"></i>' : `#${task.id}: `
          return `<div class="rectangle-30" style="border-color:${color}"></div>`+ id_text + task.text;
        },
        resize: true,
      },
      {
        name: "duration",
        label: I18n.t("activehash.property.issue.duration"),
        width: 60,
        align: "center",
        editor: editors.duration,
        resize: true,
        template: function (task) {
          return formatter.format(task.duration);
        },
      },
      {
        name: "start_date",
        label: I18n.t("activehash.property.issue.plan_time_start"),
        width: 100,
        align: "center",
        editor: editors.start_date,
        resize: true,
      },
      {
        name: "end_date",
        label: I18n.t("activehash.property.issue.plan_time_end"),
        width: 100,
        align: "center",
        editor: editors.end_date,
        resize: true,
      },
      {
        name: "est_time",
        label: I18n.t("activehash.property.issue.est_time"),
        width: 80,
        align: "center",
        editor: editors.est_time,
        resize: true,
      },
      {
        name: "progress",
        label: I18n.t("activehash.property.issue.progress"),
        width: 80,
        align: "center",
        editor: editors.progress,
        template: function (task) {
          return Math.round(task.progress * 100) + "%";
        },
        resize: true,
      },
      {
        name: "priority_id",
        label: I18n.t("activehash.property.issue.priority_id"),
        width: 80,
        align: "center",
        editor: editors.priority,
        template: function (task) {
          return renderLabel(task.priority, gantt.serverList("priority"));
        },
        resize: true,
      },
      {
        name: "stage_id",
        label: I18n.t("activehash.property.issue.stage_id"),
        align: "center",
        width: 80,
        editor: editors.stage,
        template: function (task) {
          return renderLabel(task.stage, gantt.serverList("stage"));
        },
        resize: true,
      },
      {
        name: "assignee_id",
        label: I18n.t("activehash.property.issue.assignee_id"),
        align: "center",
        width: 80,
        editor: editors.assignee,
        template: function (task) {
          return renderLabel(task.assignee, gantt.serverList("assignee"));
        },
        resize: true,
      },
      {
        name: "actual_time_start",
        label: I18n.t("activehash.property.issue.actual_time_start"),
        width: 80,
        align: "center",
        editor: editors.actual_time_start,
        resize: true,
      },
      {
        name: "actual_time_end",
        label: I18n.t("activehash.property.issue.actual_time_end"),
        width: 80,
        align: "center",
        editor: editors.actual_time_end,
        resize: true,
      },
      {
        name: "kind",
        label: I18n.t("activehash.property.issue.kind"),
        align: "center",
        width: 80,
        editor: editors.kind,
        template: function (task) {
          return renderLabel(task.kind, gantt.serverList("kind"));
        },
        resize: true,
      },
      {
        name: "complete",
        label: I18n.t("activehash.property.issue.complete"),
        width: 80,
        align: "center",
        template: function (task) {
          let checked_state = "";
          if (task.complete) checked_state = "checked";
          let disabled = readonly_gantt ? "disabled" : " "
          return (
            "<input type='checkbox' class='check_complete form-check-input' data-id=" + task.id + " " + checked_state + " " + disabled + " "+ "/>"
          );
        },
        resize: true,
      },
      {
        name: "predecessors",
        label: I18n.t("activehash.property.issue.predecessors"),
        width: 80,
        align: "left",
        editor: editors.predecessors,
        resize: true,
        template: function (task) {
          var links = task.$target;
          var labels = [];
          for (var i = 0; i < links.length; i++) {
            var link = gantt.getLink(links[i]);
            labels.push(linksFormatter.format(link));
          }
          return labels.join(", ");
        },
      },
    ];

    window.createColumnsConfig = function (selectedColumns) {
      var newColumns = [];
      allColumns.forEach(function (column) {
        if (selectedColumns[column.name]) {
          newColumns.push(column);
        }
      });

      return newColumns;
    };

    gantt.config.columns = createColumnsConfig(header);

    var newTask = false; // cờ check hành động có phải là tạo task mới hay không

    gantt.attachEvent("onTaskCreated", function (task) {
      newTask = true;
      task.priority = gantt.serverList("priority")[0].key;
      task.stage = gantt.serverList("stage")[0].key;
      task.start_date = new Date();
      task.kind = gantt.serverList("kind")[1].key;

      if (task.type == gantt.config.types.placeholder) {
        task.text = I18n.t("issues.gantt.new_task_label");
        task.is_new = true
      }
      return true;
    });

    // gantt.templates.task_end_date = function (date) {
    //   return gantt.templates.task_date(new Date(date.valueOf() - 1));
    // };

    function column_width_zoom(value){
      gantt.config.min_column_width += value;

      if (gantt.config.min_column_width < zoomConfig.minColumnWidth){
        gantt.ext.zoom.zoomOut();
        if (gantt.ext.zoom.getCurrentLevel() == (gantt.ext.zoom.getLevels().length - 1)) {
          gantt.config.min_column_width -= value;
        }
      }
      else if (gantt.config.min_column_width > zoomConfig.maxColumnWidth){
        gantt.ext.zoom.zoomIn();
        if (gantt.ext.zoom.getCurrentLevel() == 0) {
          gantt.config.min_column_width -= value;
        }
      }
      else {
        if(gantt.config.min_column_width < 20 || gantt.config.min_column_width > 700) gantt.config.min_column_width -= value;
        gantt.render();

      }
    }

    var zoomConfig = {
      levels: [
        {
          name:"day",
          min_column_width:80,
          scales:[
            {unit: "month", step: 1, format: "%M %Y"},
            {unit: "day", step: 1, format: "%d"}
          ]
        },
        {
          name:"week",
          min_column_width:50,
          scales:[
            {unit: "month", format: "%M %Y"},
            {unit: "week", format: "W%W"}
          ]
        },
        {
          name:"month",
          scale_height: 50,
          min_column_width:120,
          scales:[
            {unit: "year", format: "%Y"},
            {unit: "month", format: "%M"}
          ]
        },
        {
          name:"quarter",
          min_column_width:50,
          scales:[
            {unit: "year", step: 1, format: "%Y"},
            {
              unit: "quarter", step: 1, format: function (date) {
                var dateToStr = gantt.date.date_to_str("%M");
                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            },
          ]
        },
        {
          name:"year",
          min_column_width: 90,
          scales:[
            {unit: "year", step: 1, format: "%Y"}
          ]
        }
      ],
      element: function(){
        return gantt.$root.querySelector(".gantt_task");
      }
    };

    function toggleMode(toggle) {
      gantt.$zoomToFit = !gantt.$zoomToFit;
      if (gantt.$zoomToFit) {
        toggle.innerHTML = '<i class="uil uil-redo"></i>'; //REDO
        $(toggle).attr("title", I18n.t("master_plans.gantt.set_default_scale"));
        //Saving previous scale state for future restore
        saveTempConfig();
        zoomToFit();
      } else {
        toggle.innerHTML =  '<i class="mdi mdi-magnify-expand"></i>'; // ZOOMFIT
        $(toggle).attr("title", I18n.t("master_plans.gantt.zoom_fit"));
        //Restore previous scale state
        restoreConfig();
        gantt.render();
      }
    }

    var tempCachedSettings = {};

    function saveTempConfig() {
      let config = gantt.config;
      tempCachedSettings = {};
      tempCachedSettings.scales = [
        { unit: "month", step: 1, date: "%M, %Y" },
        { unit: "day", step: 1, date: "%d"},
      ];
      tempCachedSettings.start_date = config.start_date;
      tempCachedSettings.end_date = config.end_date;
      tempCachedSettings.scroll_position = gantt.getScrollState();
    }

    function saveConfig() {
      let config = gantt.config,
          cachedSettings = {};

      cachedSettings.scales = config.scales;
      cachedSettings.start_date = config.start_date;
      cachedSettings.end_date = config.end_date;
      cachedSettings.scroll_position = gantt.getScrollState();
      localStorage.setItem("config_gantt_view", JSON.stringify(cachedSettings));
    }

    function restoreConfig() {
      applyConfig(tempCachedSettings);
    }

    function applyConfig(config, dates) {

      gantt.config.scales = config.scales;

      // restore the previous scroll position
      if (config.scroll_position) {
        setTimeout(function(){
          gantt.scrollTo(config.scroll_position.x, config.scroll_position.y)
        },4)
      }
    }

    function zoomToFit() {
      var project = gantt.getSubtaskDates(),
        areaWidth = gantt.$task.offsetWidth,
        scaleConfigs = zoomConfig.levels;

      for (var i = 0; i < scaleConfigs.length; i++) {
        var columnCount = getUnitsBetween(project.start_date, project.end_date, scaleConfigs[i].scales[scaleConfigs[i].scales.length-1].unit, scaleConfigs[i].scales[0].step);
        if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
          break;
        }
      }


      if (i == scaleConfigs.length) {
        i--;
      }

      gantt.ext.zoom.setLevel(scaleConfigs[i].name);
      applyConfig(scaleConfigs[i], project);
    }

    // get number of columns in timeline
    function getUnitsBetween(from, to, unit, step) {
      var start = new Date(from),
        end = new Date(to);
      var units = 0;
      while (start.valueOf() < end.valueOf()) {
        units++;
        start = gantt.date.add(start, step, unit);
      }
      return units;
    }

    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("day");

    if(localStorage.getItem("config_gantt_view") != null) {
      let config = JSON.parse(localStorage.getItem("config_gantt_view"));
      gantt.config.scales = config.scales;
      gantt.config.start_date = config.start_date;
      gantt.config.end_date = config.end_date;
      $("#view_mode").val(config.scales[config.scales.length - 1].unit);
    }

    gantt.init("gantt_here");
    gantt.parse({ data: gantt_data, links: links_gantt_data });

    const tooltips = gantt.ext.tooltips;

    gantt.ext.tooltips.detach("["+gantt.config.task_attribute+"]:not(.gantt_task_row)");

    function issueTooltipTemplate(task) {
      return I18n.t("gantts.title", {code: task.id, title: task.text, progress: Math.round(task.progress * 100), assignee: renderLabel(task.assignee, gantt.serverList("assignee"))} )
    }

    tooltips.tooltipFor({
      selector: ".gantt_grid_head_cell",
      html: function (event, domElement) {
        return domElement.textContent;
      },
    });

    tooltips.tooltipFor({
      selector: ".gantt_task_line",
      html: function (event, domElement) {
        id = $(domElement).data("task-id");
        task = gantt.getTask(id);
        return issueTooltipTemplate(task)
      },
      global: false
    });

    tooltips.tooltipFor({
      selector: ".gantt_cell[data-column-name='text']",
      html: function (event, domElement) {
        return $(domElement).find(".gantt_tree_content").text();
      },
      global: false
    });

    var dp = gantt.createDataProcessor({
      task: {
        create: function (item) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");
          $.ajax({
            type: "POST",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url: "/master_plans/" + master_plan_id + "/issues",
            dataType: "json",
            data: {
              master_plan_id: master_plan_id,
              old_id: item.id,
              issue: {
                title: item.text,
                plan_time_start: item.start_date,
                plan_time_end: item.end_date,
                est_time: item.est_time,
                stage_id: item.stage_id,
                priority_id: item.priority_id,
                assignee_id: item.assignee_id,
                progress: item.progress * 100,
                issue_parent_id: item.parent == 0 ? null : item.parent,
                complete: item.complete,
                kind: item.kind,
                actual_time_start: item.actual_time_start,
                actual_time_end: item.actual_time_end,
              },
            },
            success: function (data) {
              gantt.getTask(parseInt(data.old_id)).is_new = false
              gantt.changeTaskId(parseInt(data.old_id), parseInt(data.tid));
              parent_progress(data.issue.issue_parent_id);

              if (data.issue.issue_parent_id != null){
                parent = gantt.getTask(data.issue.issue_parent_id)
                parent.type = gantt.config.types.project;
              }
            },
          });
        },
        update: function (item, id) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");
          $.ajax({
            type: "PATCH",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url:
              "/master_plans/" +
              master_plan_id +
              "/issues/" +
              id +
              "/update_by_gantt",
            dataType: "json",
            data: {
              id: id,
              master_plan_id: master_plan_id,
              next_task_id: gantt.getNextSibling(id),
              issue: {
                title: item.text,
                plan_time_start: item.start_date,
                plan_time_end: item.end_date,
                est_time: item.est_time,
                stage_id: item.stage_id,
                priority_id: item.priority_id,
                assignee_id: item.assignee_id,
                progress: item.progress * 100,
                issue_parent_id: item.parent == 0 ? null : item.parent,
                complete: item.complete,
                kind: item.kind,
                actual_time_start: item.actual_time_start,
                actual_time_end: item.actual_time_end,
                color: item.color
              },
            },
            success: function (data) {
              let task = gantt.getTask(data.issue.id);
              task.progress = data.issue.progress;
              task.complete = data.issue.complete;
              task.kind = data.issue.kind;
              task.assignee_id = data.issue.assignee;
              task.assignee = parseInt(data.issue.assignee);
              task.priority = parseInt(data.issue.priority)
              task.stage = parseInt(data.issue.stage)
              gantt.render();
              gantt.eachTask(function (child) {
                child_complete(child);
              }, data.issue.id);

              parent_progress(data.issue.parent);
            },
          });
        },
        delete: function (id) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");

          $.ajax({
            type: "DELETE",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url: "/master_plans/" + master_plan_id + "/issues/" + id,
            dataType: "json",
          });
        },
      },
      link: {
        create: function (data) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");

          $.ajax({
            type: "POST",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url: "/project_manager/links",
            dataType: "json",
            data: {
              master_plan_id: master_plan_id,
              old_id: data.id,
              link: {
                source_id: data.source,
                target_id: data.target,
                link_type: data.type,
                master_plan_id: master_plan_id,
              },
            },
            success: function (data) {
              gantt.changeLinkId(parseInt(data.old_id), parseInt(data.tid));
              gantt.refreshData();
            },
          });
        },
        update: function (data, id) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");

          $.ajax({
            type: "PATCH",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url: "/project_manager/links/" + id,
            dataType: "json",
            data: {
              master_plan_id: master_plan_id,
              link: {
                id: id,
                source_id: data.source,
                target_id: data.target,
                link_type: data.type,
              },
            },
            success: function (data) {},
          });
        },

        delete: function (id) {
          let master_plan_id = $("#gantt_here").data("master-plan-id");

          $.ajax({
            type: "DELETE",
            headers: {
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
            },
            url: " /project_manager/links/" + id,
            dataType: "json",
            data: {
              master_plan_id: master_plan_id,
            },
          });
        },
      },
    });

    dp.init(gantt);

    gantt.ext.inlineEditors.attachEvent("onSave", function (state) {
      var col = state.columnName;
      if (
        gantt.autoSchedule &&
        (col == "start_date" || col == "end_date" || col == "duration")
      ) {
        gantt.autoSchedule();
      }
    });

    gantt.ext.inlineEditors.attachEvent("onBeforeSave", function (stage) {
      let task = gantt.getTask(stage.id);
      task[stage.columnName] = stage.newValue;
      let status = check_valid_issue(task.id, task);
      if (status) {
        return true;
      }
      task[stage.columnName] = stage.oldValue;
      return false;
    });

    gantt.attachEvent("onBeforeTaskUpdate", function (id, item) {
      if (check_valid_issue(id, item)) {
        return true;
      }
      return false;
    });

    // block edit process in parent task
    var colunmCanNoteditArray = ["progress", "est_time", "start_date", "end_date", "duration", "actual_time_start", "actual_time_end"]
    gantt.attachEvent("onBeforeTaskChanged", function (id, property, value) {
      task = gantt.getTask(id)

      if (task.type == "project" && ($.inArray(property, colunmCanNoteditArray) !== -1)) {
        return false;
      }
      return true;
    });

    gantt.ext.inlineEditors.attachEvent("onBeforeEditStart", function (state) {
      var col = state.columnName,
      task = gantt.getTask(state.id)

      if (task.type == "project" && ($.inArray(col, colunmCanNoteditArray) !== -1)) {
        return false;
      }

      return true;
    });

    gantt.attachEvent("onBeforeLightbox", function (id) {
      if (newTask) return true;
      return false;
    });

    gantt.attachEvent("onLightbox", function (id) {
      $(".gantt_save_btn_set").click();
    });

    gantt.attachEvent("onLightboxSave", function (id, item, is_new) {
      if (check_valid_issue(id, item)) {
        return true;
      }
      return false;
    });

    gantt.attachEvent("onAfterLightbox", function () {
      newTask = false;
    });

    gantt.attachEvent("onAfterTaskDelete", function (id, item) {
      parent_progress(item.parent);
      return true;
    });

    gantt.attachEvent("onBeforeRowDragEnd", function(id, parent, tindex){
      let parent_task = null
      if(parent > 1) parent_task = gantt.getTask(parent)

      if (parent_task!= null && parent_task.type == "milestone") return false;

      return true;
    });

    gantt.attachEvent("onRowDragEnd", function (id, target) {
      update_position(id)

      if(typeof(target) == 'number' && target > 0 ){
        new_parent = gantt.getTask(target)
        new_parent.type = gantt.config.types.project;
        gantt.render()
      }
    });

    gantt.attachEvent("onTaskClick", function(id, e) {
      if ($(e.srcElement).hasClass("gantt_close") || $(e.srcElement).hasClass("gantt_open")) return true;

      let master_plan_id = $("#gantt_here").data("master-plan-id");

      $.ajax({
        type: "GET",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        url: "/support/issues/load_issue",
        dataType: "script",
        data: {
          master_plan_id: master_plan_id,
          id: id,
        },
        success: function (data) {
        },
      })

      return true;
    });

    $("#gantt_here").delegate("input.check_complete", "click", function () {
      checked_change($(this).data("id"));
    });

    $(".header_gantt_chart").delegate(".setting_tool", "click", function () {
      $.ajax({
        type: "GET",
        url: "/project_manager/user_screen_properties",
        dataType: "script",
        data: {
          screen: "gantt",
        },
      });
    });

    var actions = {
      add: function add(task_id) {
        gantt.createTask(null, task_id);
        return task_id;
      },
      indent: function indent(task_id) {
        var prev_id = gantt.getPrevSibling(task_id);
        while (gantt.isSelectedTask(prev_id)) {
          var prev = gantt.getPrevSibling(prev_id);
          if (!prev) break;
          prev_id = prev;
        }

        if (prev_id) {
          var new_parent = gantt.getTask(prev_id);
          if (new_parent.type == gantt.config.types.milestone) {
            gantt.message({
              type: "error",
              text: "<ul><li>Milstone dont have subtasks</li></ul>",
              expire: 30 * 1000,
            });

            return;
          }

          gantt.moveTask(
            task_id,
            gantt.getChildren(new_parent.id).length,
            new_parent.id
          );

          new_parent.type = gantt.config.types.project;
          new_parent.$open = true;
          gantt.updateTask(task_id);
          gantt.updateTask(new_parent.id);

          return task_id;
        }
        return null;
      },
      outdent: function outdent(task_id, initialIndexes, initialSiblings) {
        var cur_task = gantt.getTask(task_id);
        var old_parent = cur_task.parent;
        if (
          gantt.isTaskExists(old_parent) &&
          old_parent != gantt.config.root_id
        ) {
          var index = gantt.getTaskIndex(old_parent) + 1;
          var prevSibling = initialSiblings[task_id].first;

          if (gantt.isSelectedTask(prevSibling)) {
            index += initialIndexes[task_id] - initialIndexes[prevSibling];
          }
          gantt.moveTask(task_id, index, gantt.getParent(cur_task.parent));
          if (!gantt.hasChild(old_parent))
            gantt.getTask(old_parent).type = gantt.config.types.task;
          gantt.updateTask(task_id);
          gantt.updateTask(old_parent);

          return task_id;
        }
        return null;
      },
      del: function (task_id) {
        children_id = gantt.getChildren(task_id);
        gantt.confirm({
          text:
            children_id.length > 0
              ? I18n.t("issues.gantt.delete_warning_has_child")
              : I18n.t("issues.gantt.delete_warning_not_child"),
          ok: I18n.t("issues.gantt.button.agree"),
          cancel: I18n.t("issues.gantt.button.cancel"),
          callback: function (result) {
            if (!result) return;
            if (gantt.isTaskExists(task_id)) gantt.deleteTask(task_id);
            return task_id;
          },
        });
      },
      calendar: function calendar(node) {
        let position = node.getBoundingClientRect();
        let dropDown = document.querySelector("#setting-watch-time-modal");
        $("#gantt_start_date").val(format_date(new Date(gantt.config.start_date))),
        $("#gantt_end_date").val(format_date(new Date(gantt.config.end_date)));
        datepicker("#gantt_start_date");
        datepicker("#gantt_end_date");
        dropDown.style.top = position.bottom + "px";
        dropDown.style.left = position.left - 600 + "px";
        dropDown.style.display = "block";
        dropDown.keep = true;
        setTimeout(function () {
          dropDown.keep = false;
          $(document).on("click", function (e) {
            if (
              $(e.target).closest("#setting-watch-time-modal").length === 0 &&
              $(e.target).closest('.tool_item[data-action-name="calendar"]')
                .length === 0
            ) {
              dropDown.style.display = "none";
            }
          });
        });
      },
      filter_search: function filter_search(node) {
        let master_plan_id = $("#gantt_here").data("master-plan-id");
        let list_option = $("#field_checkbox_value").val();

        $.ajax({
          type: "GET",
          url: "/project_manager/set_filter",
          dataType: "script",
          data: {
            master_plan_id: master_plan_id,
            task_title: $("#field_title_value").val(),
            filter_checkbox: list_option.split(","),
            member_filter_gantt: $("#field_select_member_value").val(),
          },
        });
      },
      comment: function comment(task_id) {
        let master_plan_id = $("#gantt_here").data("master-plan-id");
        $.ajax({
          type: "GET",
          url:
            "/project_manager/master_plans/" +
            master_plan_id +
            "/issues/" +
            task_id +
            "/moots",
          dataType: "script",
        });
      },
      attachment: function attachment(task_id) {
        let master_plan_id = $("#gantt_here").data("master-plan-id");
        $.ajax({
          type: "GET",
          url:
            "/project_manager/master_plans/" +
            master_plan_id +
            "/project_attachments/list_by_issue/" +
            task_id,
          dataType: "script",
        });
      },
      note: function note(task_id) {
        let master_plan_id = $("#gantt_here").data("master-plan-id");
        $.ajax({
          type: "GET",
          url:
            "/project_manager/master_plans/" +
            master_plan_id +
            "/issues/" +
            task_id +
            "/note",
          dataType: "script",
        });
      },
      zoom_in: function zoom_in() {
        column_width_zoom(10)
      },
      zoom_out: function zoom_in() {
        column_width_zoom(-10)
      },
      zoom_fit: function zoom_in(node) {
        toggleMode(node)
      },
      view_mode: function view_mode(node) {
        gantt.ext.zoom.setLevel(node.value);
        saveConfig()
      },
      set_color: function set_color(node){
        let color = node.value
        gantt.eachSelectedTask(function (task_id) {
          task = gantt.getTask(task_id);
          task.color = color;
          gantt.updateTask(task_id);
        })
      }
    };

    var cascadeAction = {
      indent: true,
      outdent: true,
      del: true,
      add: true,
    };

    var ganttAction = {
      calendar: true,
      filter_search: true,
      zoom_in: true,
      zoom_out: true,
      zoom_fit: true,
      view_mode: true,
      set_color: true
    };

    var issueAction = {
      note: true,
      comment: true,
      attachment: true,
    };

    gantt.performAction = function (node) {
      let actionName = $(node).data("action-name");
      var action = actions[actionName];
      if (!action) return;

      if (ganttAction[actionName]) {
        action(node);
        return;
      }

      gantt.batchUpdate(function () {
        // need to preserve order of items on indent/outdent,
        // remember order before changing anything:
        var indexes = {};
        var siblings = {};
        gantt.eachSelectedTask(function (task_id) {
          gantt.ext.undo.saveState(task_id, "task");
          indexes[task_id] = gantt.getTaskIndex(task_id);
          siblings[task_id] = {
            first: null,
          };

          var currentId = task_id;
          while (
            gantt.isTaskExists(gantt.getPrevSibling(currentId)) &&
            gantt.isSelectedTask(gantt.getPrevSibling(currentId))
          ) {
            currentId = gantt.getPrevSibling(currentId);
          }
          siblings[task_id].first = currentId;
        });

        var updated = {};
        gantt.eachSelectedTask(function (task_id) {
          if (cascadeAction[actionName]) {
            if (!updated[gantt.getParent(task_id)]) {
              var updated_id = action(task_id, indexes, siblings);
              updated[updated_id] = true;
            } else {
              updated[task_id] = true;
            }
          } else {
            action(task_id, indexes);
          }
        });
      });
    };

    $("body").delegate(".tool_item", "click", function () {
      gantt.performAction(this);
    });

    $(".header_gantt_chart").delegate('.color-select-in-gantt .input-color', 'input', function(ev) {
      gantt.performAction(this);
    });

    $(".header_gantt_chart").delegate('.color-select-in-gantt .color-item', 'click', function(ev) {
      gantt.performAction(this);
    });

    // $("#setting-column-modal").delegate(
    //   "#btn_apply_column",
    //   "click",
    //   function () {
    //     let column_list = $("#list_checkbox_columns").find(
    //       "input[type='checkbox']"
    //     );
    //     user_screen_properties_params = [];

    //     $.each(column_list, function (index, col) {
    //       user_screen_properties_params.push({
    //         id: $(col).parent().data("id"),
    //         display: $(col).is(":checked"),
    //         screen_property_id: $(col).parent().data("screen-property-id"),
    //       });
    //     });

    //     $.ajax({
    //       type: "POST",
    //       url: "/project_manager/user_screen_properties",
    //       dataType: "script",
    //       data: {
    //         screen: "gantt",
    //         user: {
    //           user_screen_properties_attributes: user_screen_properties_params,
    //         },
    //       },
    //     });
    //   }
    // );

    $("#setting-watch-time-modal").delegate(
      "#icon_start_date_gantt, #gantt_start_date",
      "click",
      function () {
        $("#gantt_start_date").datepicker("setEndDate", gantt.config.end_date);
      }
    );

    $("#setting-watch-time-modal").delegate(
      "#gantt_end_date, #icon_end_date_gantt",
      "click",
      function () {
        $("#gantt_end_date").datepicker("setStartDate", gantt.config.start_date);
      }
    );

    $("#setting-watch-time-modal").delegate( "#js-change-watch-time", "click", function () {
        let start_date = $("#gantt_start_date").attr("value"),
            end_date = $("#gantt_end_date").attr("value");
        let config = gantt.config;
        let cachedCurrentSettings = {};

        config.start_date = new Date(start_date);
        config.end_date = new Date(end_date);

        //
        cachedCurrentSettings.scales = config.scales;
        cachedCurrentSettings.start_date = config.start_date;
        cachedCurrentSettings.end_date = config.end_date;
        cachedCurrentSettings.scroll_position = gantt.getScrollState();
        saveConfig();

        $.ajax({
          type: "GET",
          headers: {
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
          url: "/support/holidays",
          dataType: "json",
          data: {
            start_date: start_date,
            end_date: end_date,
          },
          success: function (data) {

            gantt.config.scales = [
              { unit: "month", step: 1, date: "%M, %Y" },
              {
                unit: "day",
                step: 1,
                date: "%d",
                css: function (date) {
                  if (
                    date.getDay() === 0 ||
                    date.getDay() === 6 ||
                    data.holidays.includes(format_date(date))
                  ) {
                    return "weekend_header";
                  }
                  return "";
                },
              },
            ];

            gantt.templates.timeline_cell_class = function (task, date) {
              if (
                date.getDay() === 0 ||
                date.getDay() === 6 ||
                data.holidays.includes(format_date(date))
              ) {
                return "weekend";
              }
            };

            applyConfig(cachedCurrentSettings);
            gantt.render();
          },
        });

        gantt.render();

        document.querySelector("#setting-watch-time-modal").style.display =
          "none";
      }
    );
  }
});

function check_valid_issue(id, item) {
  let master_plan_id = $("#gantt_here").data("master-plan-id");
  let status;

  $.ajax({
    type: "GET",
    url:
      "/master_plans/" +
      master_plan_id +
      "/issues/" +
      id +
      "/check_valid_issue",
    dataType: "json",
    data: {
      id: id,
      master_plan_id: master_plan_id,
      issue: {
        title: item.text,
        plan_time_start: item.start_date,
        plan_time_end: item.end_date,
        est_time: item.est_time,
        stage_id: item.stage_id,
        priority_id: item.priority_id,
        assignee_id: item.assignee_id,
        progress: item.progress * 100,
        issue_parent_id: item.parent == 0 ? null : item.parent,
        complete: item.complete,
        kind: item.kind,
        actual_time_start: item.actual_time_start,
        actual_time_end: item.actual_time_end,
        color: item.color
      },
    },
    async: false,
    success: function (data) {
      if (data.status != "true") {
        $.each(data.message, function (index, value) {
          gantt.message({
            type: "error",
            text:
              "<ul>" +
              "<li>" +
              value[0] +
              ": " +
              value[1].join(", ") +
              "</li>" +
              "</ul>",
            expire: 30 * 1000,
          });
        });
        status = false;
      } else {
        status = true;
      }
    },
    error: function (jqXHR, textStatus, errorThrown) {
      status = false;
    },
  });
  return status;
}

function checked_change(id) {
  gantt.confirm({
    text: "Update task?",
    ok: I18n.t("issues.gantt.button.agree"),
    cancel: I18n.t("issues.gantt.button.cancel"),
    callback: function (result) {
      if (result) {
        var task = gantt.getTask(id);
        task.complete = !task.complete;
        gantt.updateTask(id);
      } else {
        gantt.refreshTask(id);
      }
    },
  });
}

function parent_progress(id) {
  if (id == null || id == 0) {
    return;
  }

  let task = gantt.getTask(id);
  let master_plan_id = $("#gantt_here").data("master-plan-id");
  $.ajax({
    type: "GET",
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    url: "/support/issues/load_issue",
    dataType: "json",
    data: {
      master_plan_id: master_plan_id,
      id: task.id,
    },
    success: function (data) {
      task.progress = data.issue.progress;
      task.complete = data.issue.complete;
      task.est_time = data.issue.est_time;
      gantt.render();
    },
  });
  parent_progress(task.parent);
}

function child_complete(task) {
  let master_plan_id = $("#gantt_here").data("master-plan-id");

  $.ajax({
    type: "GET",
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
    url: "/support/issues/load_issue",
    dataType: "json",
    data: {
      master_plan_id: master_plan_id,
      id: task.id,
    },
    success: function (data) {
      if (data.status) {
        task.progress = data.issue.progress;
        task.complete = data.issue.complete;
        gantt.render();
      }
    },
  });
}

function update_position(task_id) {
  let master_plan_id = $("#gantt_here").data("master-plan-id");
  $.ajax({
    type: "GET",
    url: "/master_plans/" + master_plan_id + "/issues/" + task_id + "/update_row_order",
    dataType: "json",
    data: {
      next_task_id: gantt.getNextSibling(task_id),
    },
  });
}

function get_log_work_issue(id) {
  let master_plan_id = $("#gantt_here").data("master-plan-id");
  let count = 0;
  $.ajax({
    type: "GET",
    url: "/support/issues/get_log_work_issue",
    dataType: "json",
    data: {
      master_plan_id: master_plan_id,
      id: id,
    },
    async: false,
    success: function (data) {
      count = data.count
    },
  });

  return count;
}
