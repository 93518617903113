import { CustomEditorText } from "../libs/quill/custome_editor_text";


// Quill.register({ 'modules/mention': QuillMention });
$(document).ready(function() {
	if ($("#top-page-information-content-editor").length > 0) {
		if ($(".form-top-page-information").length > 0) {
			var form = document.querySelector('.form-top-page-information');
		}		
		var input = { 
			id: 'top-page-information-content-editor',
			status: false
		}
		var text_Editor = new CustomEditorText(input)
		form.onsubmit = function() {
			var postContentInput = document.querySelector('#top_page_information_content');
			postContentInput.value = text_Editor.value();
		};
	}
	
	initButtonTitle();
});

