$(document).on('turbo:load', function() {
	$("body").delegate('.user_in_stamp_setting', 'click', function () {	
    $('.user_in_stamp_setting').removeClass('user_active');
    $(this).addClass('user_active');
    let user_id = $(this).data('user-id');
		$.ajax ({
      type: 'GET',
      url: "setting_stamps?user_id=" + user_id,
      dataType: 'script',
      data: {
        user_id: user_id
      },
      success: function(data, textStatus, jqXHR){
        // 
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
	});

  $("body").delegate("#filter_user_name", "keyup", function (e){
    let find_user_name = $("#filter_user_name").val().trim();
    $.ajax({
      type: 'GET',
      url: "/filter_user_name",
      dataType: 'script',
      data: {
        find_user_name: find_user_name,
      },
      success: function (data, textStatus, jqXHR) {
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error!")
      }
    });
  });

  // new personal stamp
  $("body").delegate('#btnNewPersonalStamp', 'click', function () { 
    var user_id = this.value;
    $.ajax({
      type: 'GET',
      url: "/setting_stamps/new_personal_stamp",
      dataType: 'script',
      data: {
        user_id: user_id,
      },
      success: function (data, textStatus, jqXHR) {
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error!")
      }
    });
  });

  // edit personal stamp
  $('body').delegate('.setting_seal', 'click', function() {
    divParent = this; //this.parentElement;
    if(divParent.children.length<5) return;
    var nameStamp = divParent.firstElementChild.value;
    var type_seal = divParent.children[2].value;
    
    // modal edit personal stamp in modal edit user
    if (type_seal == 1) {
      var idUser = divParent.children[4].value;
      var size_stamp = divParent.children[3].value;
      var id_stamp = divParent.children[5].value;

      $.ajax({
        type: 'GET',
        url: "/setting_stamps/edit_personal_stamp",
        dataType: 'script',
        data: {
          type_stamp: type_seal,
          user_id: idUser,
          name_user: nameStamp,
          size_stamp: size_stamp,
          id: id_stamp
        },
        success: function(data, textStatus, jqXHR){
          
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log("Error")
        }
      });
    }
    
    // edit job stamp 
    if (type_seal == 2) {
      var idUser = divParent.children[8].value;
      var position = divParent.children[1].value;
      var square = divParent.children[3].value;
      var size_of_name_for_manager_stamp = divParent.children[4].value;
      var size_of_posision_for_manager_stamp = divParent.children[5].value;
      var has_date = divParent.children[7].checked;
      var id_stamp = divParent.children[9].value;
      // edit job stamp
      // if (idUser != "") {
        // click edit job stamp no date
        if (has_date == true) {
          $.ajax({
            type: 'GET',
            url: "setting_stamps/edit_position_stamp",
            dataType: 'script',
            data: {
              name: nameStamp,
              position: position,
              typestamp: type_seal,
              square: square,
              size_of_name_for_manager_stamp: size_of_name_for_manager_stamp,
              size_of_posision_for_manager_stamp: size_of_posision_for_manager_stamp,
              has_date: has_date,
              id: id_stamp
            },
            success: function(data, textStatus, jqXHR){
              
            },
            error:function(jqXHR, textStatus, errorThrown){
              console.log("Error")
            }
          });
        }
        // click edit job stamp has date
        else {
          $.ajax({
            type: 'GET',
            url: "setting_stamps/edit_position_stamp_date",
            dataType: 'script',
            data: {
              name: nameStamp,
              position: position,
              typestamp: type_seal,
              square: square,
              size_of_name_for_manager_stamp: size_of_name_for_manager_stamp,
              size_of_posision_for_manager_stamp: size_of_posision_for_manager_stamp,
              has_date: has_date,
              id: id_stamp
            },
            success: function(data, textStatus, jqXHR){
              
            },
            error:function(jqXHR, textStatus, errorThrown){
              console.log("Error")
            }
          });
        }
      // }
    }
  });

  // Disabled/enable button add preview personal stamp in setting stamp
  $('body').delegate('#name_stamp', 'input', function() {
    var valInput = document.getElementById('name_stamp').value;
    var divImg = document.getElementById("draft_personal_seal");
    
    if (valInput == "") {
      $('#btnSave_personalSeal').addClass("disabled", true);
      divImg.innerHTML = '';
    } 
    else {
      if(valInput.length == 1 || (valInput.includes("_") && valInput.length == 3)){
        document.getElementById("size_personal_stamp").value=40;
      }
      else if(valInput.length == 2){
        document.getElementById("size_personal_stamp").value=30;
      }
      else if(valInput.length == 3){
        document.getElementById("size_personal_stamp").value=20;
      }
      else{
        document.getElementById("size_personal_stamp").value=20;
      }
      previewPersonalStamp();
    }
  });

  $('body').delegate('#size_personal_stamp', 'input', function() {
    var valInput = document.getElementById('name_stamp').value;
    var size_stamp = document.getElementById("size_personal_stamp").value;
    var divImg = document.getElementById("draft_personal_seal");

    if (size_stamp == "") {
      $('#btnSave_personalSeal').addClass("disabled", true);
      divImg.innerHTML = '';
    } 
    else {
      previewPersonalStamp();
    }
  });

  // Disabled/enable button add position stamp without date in setting stamp
  $('body').delegate('#position_staff_edit', 'input', function() {
    validateOfficeStamp()
  });

  $('body').delegate('#name_staff_jobStamp_edit', 'input', function() {
    validateOfficeStamp()
  });

  $('body').delegate('#position_size_jobSeal_nodate', 'input', function() {
    validateOfficeStamp()
  });

  $('body').delegate('#name_size_jobSeal_nodate', 'input', function() {
    validateOfficeStamp()
  });

  $('body').delegate('input[name = seal]', 'input', function() {
    validateOfficeStamp();
  });

  // Disabled/enable button edit position stamp without date in setting stamp
  // $('body').delegate('#position_jobSeal_nodate', 'input', function() {
  //   var valInputPosition = document.getElementById('position_jobSeal_nodate').value;
  //   var valInputStaff = document.getElementById('name_jobSeal_nodate').value;
  //   var divImg = document.getElementById("draftJobSealNodate");
    
  //   if (valInputPosition != "" & valInputStaff != "") {
  //     document.getElementById('btnPreviewJobSealNodate').disabled = false;
  //   } 
  //   else {
  //     document.getElementById('btnPreviewJobSealNodate').disabled = true;
  //     document.getElementById('btnSaveJobSealEdit').disabled = true;
  //     divImg.innerHTML = '';
  //   }
  // });

  // $('body').delegate('#name_jobSeal_nodate', 'input', function() {
  //   var valInputPosition = document.getElementById('position_jobSeal_nodate').value;
  //   var valInputStaff = document.getElementById('name_jobSeal_nodate').value;
  //   var divImg = document.getElementById("draftJobSealNodate");
    
  //   if (valInputPosition != "" & valInputStaff != "") {
  //     document.getElementById('btnPreviewJobSealNodate').disabled = false;
  //   } 
  //   else {
  //     document.getElementById('btnPreviewJobSealNodate').disabled = true;
  //     document.getElementById('btnSaveJobSealEdit').disabled = true;
  //     divImg.innerHTML = '';
  //   }
  // });

  // company stamp
  
  $('body').delegate('#btnSettingCompanyStamp', 'click', function() {
    $.ajax({
      type: 'GET',
      url: "/setting_stamps/setting_company_stamp",
      dataType: 'script',
      data: {},
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  // $("#file_company_stamp").change(previewStamp);
  $('body').delegate('#del_img', 'click', function() {
    $("#preview_stamp").empty();
    $("#file_company_stamp").val("");
    document.getElementById('filename_companyStamp').innerHTML = "No file chosen";
    document.getElementById('filename_selected').value = "";
  });

  $('body').delegate('#btn_delete_companyStamp', 'click', function() {
    $(".image_stamp").empty();
    document.getElementById('filename_companyStamp').innerHTML = "No file chosen";
    document.getElementById('filename_selected').value = "";
  });
  
  $('body').delegate('#file_company_stamp', 'change', function() {

    var input = this;
    if (input.files && input.files.length) {
      var reader = new FileReader();
      this.enabled = false
      reader.onload = (function (e) {
        $("#preview_stamp").html(['<img class="size_img" src="'+e.target.result+'" /><span><button type="button" class="btn btn-transparent dripicons-cross pt-0 custom-btn-remove" id="del_img" ></button></span>'].join(''))
      });
      reader.readAsDataURL(input.files[0]);
      var path = document.getElementById('file_company_stamp').files[0].name;
      var decodePath = decodeURIComponent(path);
      document.getElementById('filename_companyStamp').innerHTML = decodePath;
      document.getElementById('filename_selected').value = decodePath;
    }
  });

  $('body').delegate('#has_company_stamp', 'change', function() {
    var value_checkbox = this.checked;
    var user_id = this.value;
    $.ajax({
      type: 'GET',
      url: "/setting_stamps/update_status_user_company_stamp",
      dataType: 'script',
      data: {
        value_checkbox: value_checkbox,
        user_id: user_id
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  $("body").delegate('#modal_add_signals_custom', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission behavior
      $("#btnSaveSignalsViewStamp").click();
    }
  });

});

function previewStamp() {
  if ($("#imageCompanyStamp")) {
    $("#imageCompanyStamp").remove();
  }
  var input = this;
  if (input.files && input.files.length) {
    var reader = new FileReader();
    this.enabled = false
    reader.onload = (function (e) {
      $("#preview_stamp").html(['<img class="size_img" src="'+ e.target.result+ '" /><span><button type="button" class="btn btn-transparent dripicons-cross pt-0 custom-btn-remove" id="del_img" ></button></span>'].join(''))
    });
    reader.readAsDataURL(input.files[0]);
    var path = document.getElementById('file_company_stamp').files[0].name;
    document.getElementById('filename_companyStamp').innerHTML = path;
    document.getElementById('filename_selected').value = path;
  }
}

function previewOfficeStampDate() {
  var idDiv_jobStamp = document.getElementById("editDraft_jobStamp");
  idDiv_jobStamp.innerHTML = '';
  var txtPosition = $('#position_staff_edit').val();
  var txtName = $('#name_staff_jobStamp_edit').val();
  var valSquare = document.querySelector('input[name = seal]:checked').value;
  var manager_name_size = $('#name_size_jobSeal_nodate').val();
  var manager_position_size = $('#position_size_jobSeal_nodate').val();
  var shape = "false";
  if(valSquare == 'square') shape = "true";
  var has_date = document.getElementById("edit_has_date").checked;
  $('#btnSaveJobSealEdit').removeClass("disabled", false);
  DrawStamp(txtName,txtPosition,shape, idDiv_jobStamp, "edit_draftImg_jobStamp" +  Math.random(), 2,NaN,manager_name_size,manager_position_size,has_date);
}

function previewPersonalStamp() {
  $('#btnSave_personalSeal').removeClass("disabled", false);
  var idDiv = document.getElementById("draft_personal_seal");
  idDiv.innerHTML = '';

  var textValue = $('#name_stamp').val();
  var fontsize=document.getElementById("size_personal_stamp").value;
  DrawStamp(textValue,'','',idDiv,"draft_img_personal_stamp" +  Math.random(),1,fontsize);
}

function validateOfficeStamp() {
  var valInputPosition = document.getElementById('position_staff_edit').value;
    var valInputStaff = document.getElementById('name_staff_jobStamp_edit').value;
    var manager_name_size = $('#name_size_jobSeal_nodate').val();
    var manager_position_size = $('#position_size_jobSeal_nodate').val();
    var divImg = document.getElementById("editDraft_jobStamp");
    
    if (valInputPosition != "" && valInputStaff != "" && manager_name_size!= "" && manager_position_size != "") {
      // document.getElementById('btnPreviewJobSealEdit').disabled = false;
      previewOfficeStampDate();
    } 
    else {
      // document.getElementById('btnPreviewJobSealEdit').disabled = true;
      $('#btnSaveJobSealEdit').addClass("disabled", true);
      divImg.innerHTML = '';
    }
}