$(document).on('turbo:load', function() {
	// handle click row table label
	// 	var lastCheckedLabel = null;
	// 	$("body").delegate('.item-label', 'click', function () {
	// 		if(!lastCheckedLabel) {
  //       lastCheckedLabel = this;
  //       // return;
  //     }

	//     if (window.event.ctrlKey) {
	//       this.classList.toggle("selected");
	//     }
	//     else if (window.event.shiftKey) {
	//       var start = $('.item-label').index(this);
	//       var end = $('.item-label').index(lastCheckedLabel);
	//       $('.item-label').slice(Math.min(start,end), Math.max(start,end)+ 1).addClass('selected');
	//       // console.log("a");
	//     }
	//     else {
	//       $(this).toggleClass("selected").siblings().removeClass('selected');
	//     }
	//     lastCheckedLabel = this;
	//   });

	// handle click button delete label
		$("body").delegate('#btnDeleteLabel', 'click', function() {
			var arr_row_selected = [],id;

      $.each($('.tbl_label tbody tr'), function (i, row) {
          var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
          if (checkBox) 
          {
              id =row.getAttribute('data-item-id');
              arr_row_selected.push(id);
          }   
      });

      if (arr_row_selected.length > 0) {
      	$.ajax ({
					type: 'GET',
					url: "/confirm_destroy_label",
					dataType: 'script',
					data: {
						arr_label_id: arr_row_selected
					},
					success: function(data, textStatus, jqXHR){
						// 
					},
					error:function(jqXHR, textStatus, errorThrown){
						console.log("AJAX Error")
					}
				});
      }
		});

	// handle click button sort label
		$("body").delegate('#btnChangeSortLabels', 'click', function() {
			$.ajax ({
				type: 'GET',
				url: "/change_index_labels",
				dataType: 'script',
				data: {},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		});

	// handle click button move top modal sort label
		$("body").delegate('#btnMoveTopLabels', 'click', function () {
			var count_category = 0;
	        $.each($('#tblLabelList select :selected'), function (i, row) {
	            count_category = count_category + 1
	        });
	        if (count_category > 0){
				var first_elem = $("#tblLabelList option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#tblLabelList select :selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#tblLabelList select').focus();
				}	
			}
		});

	// handle click button move up modal sort label
		$("body").delegate('#btnMoveUpLabels', 'click', function () {
			$('#tblLabelList select :selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#tblLabelList select').focus();
		});

	// handle click button move down modal sort label
		$("body").delegate('#btnMoveDownLabels', 'click', function () {
			$($('#tblLabelList select :selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#tblLabelList select').focus();
		});

	// handle click button move bottom modal sort label
		$("body").delegate('#btnMoveBottomLabels', 'click', function () {	
			var count_category = 0;
	        $.each($('#tblLabelList select :selected'), function (i, row) {
	            count_category = count_category + 1
	        });
	        if (count_category > 0){
				var last_elem = $("#tblLabelList option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#tblLabelList select :selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#tblLabelList select').focus();
				}
			}
		});

	// handle click button save sort label
		$("body").delegate('#btnUpdateIndexLabels', 'click', function () {
			var lst_label_id = [];
			$("#lstLabels option").each(function () {
				lst_label_id.push($(this).val());
			});
			$.ajax({
				type: 'POST',
				url: "update_index_labels",
				dataType: 'script',
				data: {
						lst_label_id: lst_label_id,
				},
				success: function (data, textStatus, jqXHR) {
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error: #{textStatus}")
				}
			});
		});

	// select2 with multiple
		$("#label_id").select2({
			// tags: true
			templateSelection: function (data, container) {
	      var background_color = data.element.getAttribute("background-color");
	      var text_color = data.element.getAttribute("text-color");
	      
	      $(container).css("color", text_color);
	      $(container).css("background-color", background_color);
	      // $(container)[0].firstChild.firstElementChild.style.color = text_color;
	      $(container)[0].firstElementChild.style.color = text_color;
	      return data.text;
	    },
		});

	// handle select color for background with modal new/edit label
		$("body").delegate('.background-color-palette .color-item', 'click', function() {
			var color = $(this).attr("style").replace("background: ", "");
			$(".background-color-palette .color-item").removeClass("selected"); // remove border for item color
			$(this).parent().find(".input-color-container").removeClass("selected"); // remove border selected input color
			$(this).addClass("selected"); // add border for item color selected
			$(this).parent().parent().parent().find(".btn-toolbar-color")[0].style.backgroundColor = color; // change background color button background
			$("#label_background_color").val(color); // set value for input background color
			$("#preview_label").css("background-color",color); // set background color for badge label
		});

	// handle select color input color for background with modal new/edit label
		$("body").delegate('.background-color-palette .input-color', 'input', function() {
			var color = this.value;
			$(".background-color-palette .color-item").removeClass("selected"); // remove border for item color
			$(this).parent().addClass("selected"); // add border for item color selected
			$(this).parent().parent().parent().find(".btn-toolbar-color")[0].style.backgroundColor = color; // change background color button background
			$("#label_background_color").val(color); // set value for input background color
			$("#preview_label").css("background-color",color); // set background color for badge label
		});

	// handle select color for text color with modal new/edit label
		$("body").delegate('.text-color-palette .color-item', 'click', function() {
			var color = $(this).attr("style").replace("background: ", "");
			$(".text-color-palette .color-item").removeClass("selected"); // remove border for item color
			$(this).parent().find(".input-color-container").removeClass("selected"); // remove border selected input color
			$(this).addClass("selected"); // add border for item color selected
			$(this).parent().parent().parent().find(".btn-toolbar-color")[0].style.backgroundColor = color; // change background color button background
			$("#label_text_color").val(color); // set value for input text color
			$("#preview_label").css("color",color); // set text color for badge label
		});

	// handle select color input color for text color with modal new/edit label
		$("body").delegate('.text-color-palette .input-color', 'input', function() {
			var color = this.value;
			$(".text-color-palette .color-item").removeClass("selected"); // remove border for item color
			$(this).parent().addClass("selected"); // add border for item color selected
			$(this).parent().parent().parent().find(".btn-toolbar-color")[0].style.backgroundColor = color; // change background color button background
			$("#label_text_color").val(color); // set value for input text color
			$("#preview_label").css("color",color); // set text color for badge label
		});

	// handle input label name change
		$("body").delegate('#label_name', 'input', function() {
			var label_name = this.value;
			$("#preview_label").text(label_name);
		});


})