$(document).on('turbo:load', function() {
	$("#btnDeleteStatus").click(function() {
		var arr_status = [];
		$.each($('.tbl_status tbody tr'), function (i, row) {
			if ($(row).find("input[type='checkbox']:checked").is(":checked")) {
				arr_status.push($(row).data('item-id'));
			} 
		});
		if (arr_status.length > 0) {
			let page_value = $(".status-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_status",
				dataType: 'script',
				data: {
					id: arr_status,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate('.color_status_tag .color-select .input-color', 'input', function(ev) {
    el('btn_color_status').style.backgroundColor =  this.value;
    $("#status_color").val(this.value);
  });

  $("body").delegate('.color_status_tag .color-select .color-item', 'click', function(ev) {
    el('btn_color_status').style.backgroundColor = $(this).attr("style").replace("background: ", "");
    $("#status_color").val($(this).attr("style").replace("background: ", ""));
  });
});
